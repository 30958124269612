import React from "react";
import { useDispatch, useSelector } from "react-redux";

import BiometricMovementAndCautionQuestionContainer
    from "../shared/MovementModal/BiometricMovementAndCautionQuestionContainer";
import {
    setOpenBiometricMovementAndCautionQuestionContainer,
} from "../../features/editAssessment/editAssessmentSlice";
import {Modal} from "reactstrap";
import CONSTANT from "../../constants/Constant";
import useEditAssessmentHeaderAndFooter from "./hooks/useEditAssessmentHeaderAndFooter";
import crossBtn from "../../assets/img/utils/cross.svg";
import {getMarkup} from "../../utils/Utils";
import IntakeFormWrapper
    from "../shared/intake-form/IntakeFormWrapper/IntakeFormWrapper";

const EditAssessmentDialogContainer = () => {
    const { handleSetShowInstruction, onClickJoinTelemedicineNow, onClickJoinTelemedicineLater } = useEditAssessmentHeaderAndFooter();
    const dispatch = useDispatch();
    const editAssessmentState = useSelector(state => state.editAssessment);
    const baseData = editAssessmentState?.baseData;
    const groups = editAssessmentState?.groups || [];

    const openBiometricMovementAndCautionQuestionContainer = baseData?.openBiometricMovementAndCautionQuestionContainer;
    const testId = baseData?.testId || '';
    const bodyRegionList = baseData?.bodyRegionList || [];
    const showInstruction = baseData?.showInstruction;

    const currentGroupId = baseData?.currentGroupId || CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key;
    const currentGroup = groups.find(group => group.groupId === currentGroupId);


    const handleOpenBiometricModal = (value) => {
        dispatch(setOpenBiometricMovementAndCautionQuestionContainer(value));
    }

    const handleJoinTelemedicineNow = async (values) => {
        await onClickJoinTelemedicineNow(values);
    }

    const handleJoinTelemedicineLater = async (values) => {
        await onClickJoinTelemedicineLater(values)
    }

    return (
        <>
            {openBiometricMovementAndCautionQuestionContainer &&
                <BiometricMovementAndCautionQuestionContainer
                    data={{
                        testId,
                        selectedBodyRegions: { bodyRegionList : bodyRegionList },
                        openBiometricMovementAndCautionQuestionContainer,
                    }}
                    setOpenBiometricMovementAndCautionQuestionContainer={handleOpenBiometricModal}
                />
            }


            <Modal className="modal-lg" isOpen={showInstruction} toggle={()=> handleSetShowInstruction(false)}>
                <div className="edit-assessment-instruction-container">
                    <div className="edit-assessment-instruction-header-content kt-padding-b-30">
                        <p className="edit-assessment-instruction-header-content-title">{currentGroup?.groupName}</p>
                        <img onClick={()=> handleSetShowInstruction(false)} className="cursor-pointer" src={crossBtn} alt="Close Button" />
                    </div>
                    <label className="edit-assessment-instruction-info"  dangerouslySetInnerHTML={getMarkup(currentGroup?.groupTitle)}/>
                </div>
            </Modal>


            {/*<Modal*/}
            {/*    isOpen={showIntakeForm}*/}
            {/*    backdrop={"static"}*/}
            {/*    className="modal-xl">*/}
            {/*    <ModalHeader>*/}
            {/*        <span className='intake_form_header'>Intake Form</span>*/}
            {/*    </ModalHeader>*/}
            {/*    <div>*/}
            {/*        <IntakeFormWrapper*/}
            {/*            handler={handleToggleShowPolicy}*/}
            {/*            onClickJoinTelemedicineNow={handleJoinTelemedicineNow}*/}
            {/*            onClickJoinTelemedicineLater={handleJoinTelemedicineLater}*/}
            {/*            termsAndConditionModalHandler={handleToggleShowTermsAndConditionModal}>*/}
            {/*            <>*/}
            {/*                <button*/}
            {/*                    id='JOIN_NOW'*/}
            {/*                    type='submit'*/}
            {/*                    className="btn btn-success btn-wide kt-ml-20"*/}
            {/*                    disabled={loading}>*/}
            {/*                    {*/}
            {/*                        loading*/}
            {/*                            ? <span><i className="fa fa-spinner fa-spin"/> JOINING... </span>*/}
            {/*                            : <span>JOIN NOW</span>*/}
            {/*                    }*/}
            {/*                </button>*/}
            {/*                <button*/}
            {/*                    id='JOIN_LATER'*/}
            {/*                    type='submit'*/}
            {/*                    className="btn btn-danger btn-wide kt-ml-20"*/}
            {/*                    disabled={loading}>*/}
            {/*                    {*/}
            {/*                        loading*/}
            {/*                            ? <span><i className="fa fa-spinner fa-spin"/> JOIN LATER </span>*/}
            {/*                            : <span>JOIN LATER</span>*/}
            {/*                    }*/}
            {/*                </button>*/}
            {/*            </>*/}
            {/*        </IntakeFormWrapper>*/}
            {/*    </div>*/}
            {/*</Modal>*/}


            {/*<PrivacyPolicy show={showPolicy} handler={handleToggleShowPolicy}/>*/}


            {/*<DisplayUrlContent*/}
            {/*    open={showTermsAndConditionModal}*/}
            {/*    url={Mmh_terms_and_service}*/}
            {/*    onToggleModal={handleToggleShowTermsAndConditionModal}>*/}
            {/*</DisplayUrlContent>*/}
            <IntakeFormWrapper
                onClickJoinTelemedicineNow={handleJoinTelemedicineNow}
                onClickJoinTelemedicineLater={handleJoinTelemedicineLater}
            />
        </>
    )
}

export default EditAssessmentDialogContainer;