export default class MeasurementResult {
    constructor(options) {
        options = options || {};

        this.QuestionId = options.QuestionId || 0;
        this.Key = options.Key || '';
        this.Value = options.Value || 0;
        this.ShortCode = options.ShortCode || '';
        this.Unit = options.Unit || '';
        this.Status = options.Status || '';
        this.RenderImageUrl = options.RenderImageUrl || '';
        this.Exercise = options.Exercise || '';
        this.RawVideoURL = options.RawVideoURL || '';
        this.RenderedVideoURL = options.RenderedVideoURL || '';
        this.RawImageUrl = options.RawImageUrl || '';
        this.IsUnableToPerformedExercise = options.IsUnableToPerformedExercise || false;
        this.IsSkipExercise = options.IsSkipExercise || false;
    }
}
