import answerValidationService from "../services/answerValidation/AnswerValidationService";

export default class Chat {
    constructor(options) {
        options = options || {};

        this.id = options.id || 0;
        this.botName = options.botName || '';
        this.audioUrl = options.audioUrl || "";
        this.intent = options.intent || "";
        this.responses = options.responses || [];
        this.responseType = options.responseType || "";
        this.sessionId = options.sessionId || 0;
        this.saveAnswer = options.saveAnswer || false;
        this.lastIntent = options.lastIntent || false;
        this.chatEnded = options.chatEnded || false;
        this.editable = options.editable || false;
        this.text = options.text || "";
        this.dialogue = options.dialogue || "";
        this.questionId = options.questionId || 0;
        this.type = options.type || 0;
        this.typeId = options.typeId || 0;
        this.typeName = options.typeName || '';
        this.disableBodyPartIds = options.disableBodyPartIds || [];
        this.lang = options.lang || '';
        this.lastQuestionInGroup = options.lastQuestionInGroup || false;
        this.title = options.title || '';
        this.buttonText = options.buttonText || '';
        this.hint = options.hint || '';
        this.header1 = options.header1 || '';
        this.header2 = options.header2 || '';
        this.scores = options.scores || [];
        this.bodyParts = options.bodyParts || [];
        this.skipped = options.skipped || false;
        this.types = options.types || [];

        // extra property
        this.startTime = options.startTime || new Date();
        this.mode = options.mode || false;
        this.answered = options.answered || false;
        this.answers = options.answers || [];
        this.selectedBodyParts = options.selectedBodyParts || [];
        this.selectedBodyRegions = options.selectedBodyRegions || [];
        this.selectedAnswers = options.selectedAnswers || [];
        this.selectedDatetime = options.selectedDatetime || new Date();
        this.inputText = options.inputText || '';
        this.bodyRegion = options.bodyRegion || 0;
        this.bodyRegionList = options.bodyRegionList || '';
        this.bodyMode = options.bodyMode || false; // front or back
        this.movementEdit = options.movementEdit || false;
        this.outcomeAssessmentEdit = options.outcomeAssessmentEdit || false;
        this.disabled = options.disabled || false;


        this.validationType = answerValidationService.determineValidationType(
            options.questionId || 0,
            this.responseType || ""
        );
    }
}
