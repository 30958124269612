import CONSTANT from "../../constants/Constant";

export const EDIT_ASSESSMENT_STATUS = {
    IDLE: 'idle',
    LOADING: 'loading',
    SUCCEEDED: 'succeeded',
    FAILED: 'failed'
};

export const initialEditAssessmentState = {
    data: null,
    status: EDIT_ASSESSMENT_STATUS.IDLE,
    error: {
        questionId: "",
        message: "",
        code: ""
    },
    baseData: {
        testId: '',
        bodyRegionList: [],
        invitationTypeName: '',
        invitationId: '',
        currentGroupId: CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key,
        currentSubGroupIndex: 0,
        currentQuestionId: 0,
        isReportAvailable: false,
        doesHaveOswestryQuestion: false,
        showInlineError: false,
        showIntakeForm: false,
        showPolicy: false,
        showInstruction: false,
        openBiometricMovementAndCautionQuestionContainer: false,
        showTermsAndConditionModal: false
    },
    ccQuestion: {},
    groups: [],
};