import CONSTANT from "../../../constants/Constant";
import InClinicBtn from "../../../assets/img/myAssessmensList/hospitalIcon.svg";
import React from "react";

const ResumeBtn = ({ InvitationTypeId, firstIncompleteGroupName, selectedTelemedicineInvitationId, notificationHandler, notificationData, InvitationId, IsLastIncomplete, isFromUpcomingMovement, isLoading }) => {
    const shouldButtonBeDisabled = () => {
        return isLoading ||
            (InvitationTypeId === CONSTANT.INVITATION_TYPE.InClinic && notificationData?.TestId && isFromUpcomingMovement);
    }
    return (
        <div className="d-flex justify-content-center align-items-center">
            {(InvitationTypeId === CONSTANT.INVITATION_TYPE.InClinic && firstIncompleteGroupName?.key === CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.key && !notificationData?.IsFollowup)
                ?
                    <button className="pointer-none opacity-0_4 button-dashboard bg-color-orange-dark border-0">
                        <span className="d-flex justify-content-center align-items-center color-white">
                            <img className="mr-3" src={InClinicBtn} alt="In-Clinic" /> In-Clinic
                        </span>
                    </button>
                :
                    <button
                        className={`${shouldButtonBeDisabled() ? " pointer-none opacity-0_4 button-dashboard bg-color-orange-dark border-0 " : " button button-dashboard " }`}
                        onClick={() => notificationHandler(notificationData, isFromUpcomingMovement)}
                        disabled={shouldButtonBeDisabled()}
                    >
                        {(IsLastIncomplete && !isFromUpcomingMovement)
                            ?  <span>
                                    {
                                        isLoading
                                            ? <i className="fa fa-spinner fa-spin mr-1" />
                                            : null
                                    }
                                    {
                                        notificationData?.IsFollowup
                                            ? "Resume Assessment"
                                            :
                                                (notificationData?.InvitationTypeId === CONSTANT.INVITATION_TYPE.Telemedicine && firstIncompleteGroupName?.key === CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.key)
                                                    ? "Join Now"
                                                    : `Resume ${firstIncompleteGroupName?.name}`
                                    }
                                </span>
                            : <>
                                {
                                    isLoading
                                        ?  <span><i className="fa fa-spinner fa-spin"/> Joining... </span>
                                        :
                                            <>
                                                {
                                                    notificationData?.TestId
                                                        ?
                                                            InvitationTypeId === CONSTANT.INVITATION_TYPE.InClinic
                                                                ? (
                                                                    <span className="d-flex justify-content-center align-items-center color-white">
                                                                        <img className="mr-3" src={InClinicBtn} alt="In-Clinic" /> In-Clinic
                                                                    </span>
                                                                )
                                                                : (
                                                                    <span>Join Now</span>
                                                                )
                                                        : <span>Start</span>
                                                }
                                            </>



                                }
                            </>
                        }
                    </button>
            }
        </div>
    )
}

export default ResumeBtn;