import CONSTANT from "../../../constants/Constant";
import { makeSerializable } from "../../../utils/Utils";
import { getQuestionIdsOfOSWScore } from "../../../components/editAssessmentNew/EditAssessmentUtils";
import answerValidationService from "../../../services/answerValidation/AnswerValidationService";

class Response {
    constructor({ AnswerId, AnswerTitle, AnswerOrderId, ColorCode, ConditionalQuestionList, Title, Description }) {
        this.id = AnswerId;
        this.name = AnswerTitle;
        this.answerOrderId = AnswerOrderId;
        this.colorCode = ColorCode;
        this.conditionalQuestionList = ConditionalQuestionList;
        this.title = Title;
        this.description = Description;
    }
}

class Question {
    constructor({ QuestionTitle, QuestionId, QuestionType, AnswerId, AnswerList, AnswerValue, Responses, Placeholder, IsDefaultHide }) {
        this.text = QuestionTitle ? QuestionTitle : "";
        this.questionId = QuestionId;
        this.responseType = QuestionType ? QuestionType : "";
        if((this.responseType === CONSTANT.ELEMENT_TYPES.CHECKBOX) || (this.responseType === CONSTANT.ELEMENT_TYPES.MULTISELECT)) {
            this.answerList = AnswerList ? AnswerList : [];
        }
        else {
            this.answerId = AnswerId ? AnswerId : 0;
            this.answerValue = AnswerValue ? AnswerValue : "";
        }
        this.responses = (Responses || []).map(response => makeSerializable(new Response(response)));
        this.error = { isValid: true, message: "" };
        this.dialogue = Placeholder ? Placeholder : "";
        // this.validationType = answerValidationService.determineValidationType(QuestionId, this.responseType);
        this.isDefaultHide = IsDefaultHide || false;
        //For OSW score question
        this.isScoreQuestion = getQuestionIdsOfOSWScore(CONSTANT.OUTCOME_SCORE_QUESTIONS).includes(QuestionId);
    }
}

class SubGroup {
    constructor({ Header1, Header2, PageCaption, ExamResponse }) {
        this.header1 = Header1 || "";
        this.header2 = Header2 || "";
        this.pageCaption = PageCaption || "";
        this.questions = (ExamResponse || []).map(examResponse => makeSerializable(new Question(examResponse)));
    }
}

class Group {
    constructor({ GroupId, GroupName, GroupCompleted, HeaderSectionView, GroupHeaderTitle }) {
        this.groupId = GroupId;
        this.groupName = GroupName;
        this.groupCompleted = GroupCompleted;
        this.groupTitle = GroupHeaderTitle || '';
        this.totalSubGroups = (HeaderSectionView || []).length || 0;
        this.subGroups = (HeaderSectionView || [])
            .filter(section => section.GroupId === CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.KEY)
            .map(section => makeSerializable(new SubGroup(section)));
    }
}

export { Group, SubGroup, Question, Response };