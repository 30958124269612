import React from "react";

const SkipButton = ({ chat, onClickSkip }) => {
    return (
        <>
            {chat.skipped && (
                <button type="button"
                        className="btn btn-outline-hover-primary kt-font-primary float-right kt-mt-10"
                        onClick={onClickSkip(chat)}>
                    <i className="fa fa-angle-double-right"/>
                    Skip
                </button>
            )}
        </>
    )
};

export default SkipButton;