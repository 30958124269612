import GLOBAL from "../../../../global";
import examService from "../../../../services/ExamService";
import CONSTANT from "../../../../constants/Constant";
import { isValid } from "date-fns";
import { calculateAge, storeUserInfoToLocalStorageFromGlobalConstant } from "../../../../utils/Utils";
import Assessment from "../../../../models/Assessment";
import { _getBodyRegion } from "./NewScreeningUtils";
import Answer from "../../../../models/Answer";
import { saveDemoInfoAtLocalStorage } from "../../../../services/LocalStorageService";

const providerEmail = CONSTANT.PROVIDER_EMAIL;

export function checkClientValidation(chat, message, chats) {
    let valid = true;
    let validationMessage = '';

    if (chat.questionId === CONSTANT.CHATBOT_ACCOUNT.PASSWORD) {
        if (message.length > 7 && /[a-z]/.test(message) && /[A-Z]/.test(message) && /[0-9]/.test(message)) {
            valid = true;
        } else {
            valid = false;
            validationMessage = 'Please enter password at least 1 digit, 1 capital letter, 1 small letter and 8 character long';
        }
    }

    if (chat.questionId === CONSTANT.CHATBOT_ACCOUNT.CONFIRM_PASSWORD) {
        chats = chats || [];
        const index = chats.findIndex(item => item.questionId === CONSTANT.CHATBOT_ACCOUNT.PASSWORD);

        if (index > -1) {
            const tempChat = chats[index];
            const password = tempChat.answers && tempChat.answers[0].name;

            if (password !== message) {
                valid = false;
                validationMessage = 'The password and confirmation password do not match.';
            }
        }
    }

    if (chat.questionId === CONSTANT.CHATBOT_ACCOUNT.EMAIL) {
        // eslint-disable-next-line
        const regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!regExp.test(message)) {
            valid = false;
            validationMessage = 'Please enter valid email.';
        }
    }

    if (chat.questionId === CONSTANT.CHATBOT_ACCOUNT.HEIGHT) {
        const height = +message;

        if (isNaN(message)) {
            valid = false;
            validationMessage = 'Please enter valid height.';

        } else if (Number(height) === height && height % 1 !== 0) {
            valid = false;
            validationMessage = 'Height must be integer';

        } else if (height < 12 || height > 107) {
            valid = false;
            validationMessage = 'Height must be between 12 and 107';
        }
    }

    if (chat.questionId === CONSTANT.CHATBOT_ACCOUNT.WEIGHT) {
        const weight = +message;

        if (isNaN(message)) {
            valid = false;
            validationMessage = 'Please enter valid weight.';

        } else if (Number(weight) === weight && weight % 1 !== 0) {
            valid = false;
            validationMessage = 'Weight must be integer';

        } else if (weight < 50 || weight > 500) {
            valid = false;
            validationMessage = 'Weight should be between 50 lbs to 500 lbs';
        }
    }

    if (chat.questionId === CONSTANT.CHATBOT_ACCOUNT.DATE_OF_BIRTH) {
        // noinspection JSUnresolvedFunction
        valid = isValid(new Date(message));

        const dateArray = message.split("/");
        const year = dateArray[2];
        const number = /^[0-9]+$/;
        const age = calculateAge(message);

        if (!valid) {
            valid = false;
            validationMessage = 'Please enter valid date.';

        } else if (year.length !== 4) {
            valid = false;
            validationMessage = 'Year must be 4 digit';

        } else if (!year.match(number)) {
            valid = false;
            validationMessage = 'All character of the year must be digit';

        } else if (age < 7 || age > 120) {
            valid = false;
            validationMessage = 'Age should be between 7 to 120 Years';
        }
    }

    return { valid: valid, message: validationMessage };
}

export const getServerAnswersForMsk = (chats, providerEmail) => {
    if(!GLOBAL.ASSESSMENT) return;

    const lastChat = chats[chats.length - 1];
    let typeIdOfCurrentQuestion = 0;
    let typeIdOfPreviousQuestion = 0;

    const assessment = new Assessment();
    assessment.ProviderEmail = providerEmail;
    assessment.TestId = (GLOBAL.ASSESSMENT && GLOBAL.ASSESSMENT['TestId']) || '';
    assessment.IntakeId = (GLOBAL.ASSESSMENT && GLOBAL.ASSESSMENT['IntakeId']) || '';
    assessment.BodyRegionList = _getBodyRegion(chats);
    assessment.Tenant = GLOBAL.TENANT;//BOT API
    assessment.DemographicsCompleted = GLOBAL.ASSESSMENT['DemographicsCompleted'] || false;
    assessment.ADLCompleted = GLOBAL.ASSESSMENT['ADLCompleted'] || false;
    assessment.MedicalHistoryCompleted = GLOBAL.ASSESSMENT['MedicalHistoryCompleted'] || false;
    assessment.ChiefComplaintsCompleted = GLOBAL.ASSESSMENT['ChiefComplaintsCompleted'] || false;
    //assessment.BiometricCompleted = GLOBAL.ASSESSMENT['BiometricCompleted'] || false;
    assessment.DoServerSideCalculation = GLOBAL.ASSESSMENT['DoServerSideCalculation'] || false;

    for (let chat of chats) {
        const answer = new Answer();
        let typeObjOfFirstIndex = chat?.types[0];
        let typeIdOfFirstIndex = typeObjOfFirstIndex?.id || 0;
        typeIdOfCurrentQuestion = typeIdOfFirstIndex;

        typeObjOfFirstIndex = lastChat?.types[0];
        typeIdOfFirstIndex = typeObjOfFirstIndex?.id || 0;
        typeIdOfPreviousQuestion = typeIdOfFirstIndex;

        answer.QuestionId = chat.questionId;
        answer.GroupId = typeIdOfCurrentQuestion;

        if (chat.intent === CONSTANT.CHATBOT_INTENT.BODY_REGION) continue;

        if (chat.responseType === CONSTANT.ELEMENT_TYPES.BUTTON
            || chat.responseType === CONSTANT.ELEMENT_TYPES.TEXT) {
            answer.AnswerId = (chat.answers && chat.answers.length > 0 && chat.answers[0].id) || null;
            answer.AnswerTitle = chat.answers && chat.answers.length > 0 && chat.answers[0].name;

            if (chat.responseType === CONSTANT.ELEMENT_TYPES.TEXT) {
                answer.AnswerId = null;
                answer.AnswerValue = answer.AnswerTitle;
            }

            assessment.Answers.push(answer);

        } else if (chat.responseType === CONSTANT.ELEMENT_TYPES.AUTOCOMPLETE) {
            if (chat.answers && chat.answers.length > 0) {
                chat.answers.forEach((item) => {
                    answer.AnswerId = null;
                    answer.AnswerTitle = item.name || '';
                    answer.AnswerValue = item.name || '';
                    assessment.Answers.push({ ...answer });
                });
            }

        } else if (chat.responseType === CONSTANT.ELEMENT_TYPES.DATE_PICKER) {
            if (chat.answers && chat.answers.length > 0) {
                answer.AnswerId = null;
                answer.AnswerValue = chat.answers[0].name;
                answer.AnswerTitle = chat.answers[0].name;
                assessment.Answers.push(answer);
            }

        } else if (chat.responseType === CONSTANT.ELEMENT_TYPES.DATETIME_PICKER) {
            if (chat.answers && chat.answers.length > 0) {
                answer.AnswerId = null;
                answer.AnswerValue = chat.answers[0].name;
                answer.AnswerTitle = chat.answers[0].name;
                assessment.Answers.push(answer);
            }

        } else {
            if (chat.answers && chat.answers.length > 0) {
                chat.answers.forEach((item) => {
                    answer.AnswerId = item.id || null;
                    answer.AnswerTitle = item.name || '';
                    answer.AnswerValue = item.name || '';
                    assessment.Answers.push({ ...answer });
                });
            }
        }

        if (typeIdOfCurrentQuestion === CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key) {
            saveDemoInfoAtLocalStorage(chat?.questionId, answer);
        } else if (typeIdOfPreviousQuestion === CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key) {
            assessment.DemographicsCompleted = true;
            GLOBAL.ASSESSMENT.DemographicsCompleted = true;

        } else if (typeIdOfPreviousQuestion === CONSTANT.ASSESSMENT_GROUP.MEDICAL_HISTORY.key) {
            assessment.MedicalHistoryCompleted = true;
            GLOBAL.ASSESSMENT.MedicalHistoryCompleted = true;

        } else if (typeIdOfPreviousQuestion === CONSTANT.ASSESSMENT_GROUP.ADL.key) {
            assessment.ADLCompleted = true;
            GLOBAL.ASSESSMENT.ADLCompleted = true;

        } else if (typeIdOfPreviousQuestion === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key) {
            assessment.ChiefComplaintsCompleted = true;
            GLOBAL.ASSESSMENT.ChiefComplaintsCompleted = true;

        } else if (typeIdOfPreviousQuestion === CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.key) {
            GLOBAL.ASSESSMENT.BiometricCompleted = true;
        }
    }

    return assessment;
};

export const _updateMskAnswers = (answers) => {
    if (GLOBAL.SAVED_ANSWERS.length > 0) {
        return answers.filter((answer) => {
            const savedAnswers = GLOBAL.SAVED_ANSWERS.filter((savedAnswer) => savedAnswer.QuestionId === answer.QuestionId)
            return savedAnswers.length === 0;
        });
    } else {
        return answers;
    }
}

export async function saveOrCreateNewAssessment(chats, invitationId) {
    if (!GLOBAL.ASSESSMENT) {
        const result = await createAssessment(chats, invitationId);
        const { success } = result;

        if(!success) return result;
    }

    let mskAnswers = getServerAnswersForMsk(chats, providerEmail);
    // if(!mskAnswers) return;
    // if (mskAnswers.Answers.length > 0) {
        // mskAnswers.Answers = _updateMskAnswers(mskAnswers.Answers);

        // const response = await examService.saveAssessments(mskAnswers);
        // const { success } = response;
        //
        // if (success) {
        //     GLOBAL.SAVED_ANSWERS.push(...mskAnswers.Answers);
        // }
        //
        // return response;
    // } else {
        return { data: {}, success: true };
    // }
}

const createAssessment = async (chats, invitationId) => {
    const lastChat = chats[chats.length - 1];

    if (lastChat.intent === CONSTANT.CHATBOT_INTENT.BODY_REGION) {
        const payload = {
            ProviderEmail: providerEmail,
            BodyRegionList: _getBodyRegion(chats),
        };

        if(invitationId) {
            payload.InvitationId = invitationId;
        }

        const responseAssessment = await examService.createAssessment(payload);
        const { success } = responseAssessment;

        if (!success) {
            return responseAssessment;
        }

        GLOBAL.SAVED_ANSWERS = [];
        GLOBAL.OUTCOME_ASSESSMENT = [];
        GLOBAL.SAVE_MOVEMENTS = [];
        GLOBAL.CHIEF_COMPLAINTS_ANSWERS = [];
        GLOBAL.ISANYCHIEFCOMPLAINTANSWEDED = false;
        GLOBAL.ASSESSMENT = responseAssessment?.data || '{}'; ////TODO: have to store in context api or redux
        GLOBAL.USER_INFO.IsNewUser = false; //As it only updates while login or register
        storeUserInfoToLocalStorageFromGlobalConstant();

        return responseAssessment;
    }

    return { success: true };
}