import ChiefComplaintDTO from "./Dto/ChiefComplaintDTO";
import { Group } from "./Dto/EditAssessmentDTO";
import CONSTANT from "../../constants/Constant";
import { makeSerializable } from "../../utils/Utils";

export const initEditAssessmentFeature = (state, response) => {
    const data = response?.data;

    if(!data) {
        state.data = null;
        return;
    }

    state.baseData = {
        ...state.baseData,
        testId: data?.TestId || "",
        bodyRegionList: data?.BodyRegionList || [],
        invitationTypeName: data?.InvitationTypeName || '',
        isReportAvailable: data?.IsReportReady || false,
        invitationId: data?.InvitationId || '',
        showInlineError: false,
    }

    //for redux as it detect class instance as not serializable
    const serializableCCForm = makeSerializable(new ChiefComplaintDTO(data.CheifComplaintsQuestion));
    state.ccQuestion = serializableCCForm;

    //for redux as it detect class instance as not serializable
    const groupInstance = data.ExamStageQuestionView.map(group => makeSerializable (new Group(group)));
    const groupsExcludeBiometric = excludeBiometricGroup(groupInstance);
    const groupsWithMidName = addShortNameAtGroup(groupsExcludeBiometric);
    state.groups = groupsWithMidName;
}

const excludeBiometricGroup = (groups) => {
    return groups.filter(group => group.groupId !== CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.key);
}

const addShortNameAtGroup = (groups) => {
    return groups.map(group => {
        for(const assessmentGroup in CONSTANT.ASSESSMENT_GROUP) {
            if(CONSTANT.ASSESSMENT_GROUP[assessmentGroup].key === group.groupId) {
                group.shortName = CONSTANT.ASSESSMENT_GROUP[assessmentGroup].shortName || "";
            }
        }

        return group;
    })
}

export const getPScore = (questions) => {
    let answeredQuestion = questions.filter(question =>question?.answerId !== 0);

    const score = (answeredQuestion.length || 0) * 5;
    return score;
}

export const getResponseCount = (questions, responseValue) => {
    let answeredQuestion = questions.filter(question =>
        question?.answerValue.toLowerCase() === responseValue.toLowerCase());

    const score = answeredQuestion.length || 0;
    return score;
}

export const getOSWScore = (questions) => {
    let OSWScore = 0;

    questions.forEach(question => {
        if(question?.answerId !== 0) {
            question.responses.map((response, idx) => {
                if(response?.id === question?.answerId) {
                    let score = ((idx)*2);
                    OSWScore += score;
                }
            })
        }
    })

    return OSWScore;
}