import playBtn from "../../../../../assets/img/chat/playFilledColor.svg";
import CONSTANT from "../../../../../constants/Constant";
import {deepClone, getMarkup, playAudio} from "../../../../../utils/Utils";
import MultiBarIndicator from "../../../MultiBarIndicator";
import React, {useContext, useEffect} from "react";
import CurrentQuestionResponse from "../CurrentQuestionResponse";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import routes from "../../../../../constants/Routes";
import { redirectTo } from "../../ChatServices";
import AppContext from "../../../../../contexts/AppContext";
import useCurrentQuestion from "./useCurrentQuestion";
import IntakeFormWrapper from "../../../intake-form/IntakeFormWrapper/IntakeFormWrapper";
import {useDispatch, useSelector} from "react-redux";
import {
    setChats, setIsOkButtonLoading,
    setQuestionHint,
    setQuestionHintModal,
    setQuestionHintTitle, updateChatAtIndex
} from "../../../../../features/chat/chatbotSlice";
import useChatbot from "../../hooks/useChatbot";
import {getAllTypesText} from "../../ChatUtils";
import {closeIntakeForm} from "../../../../../features/intakeForm/intakeFormSlice";
import useSweetAlert from "../../../hooks/useSweetAlert";

const CurrentQuestion = () => {
    const { hideSweetAlert } = useSweetAlert();
    const dispatch = useDispatch();
    const chatBotState = useSelector(state => state.chatBot);
    const botData = chatBotState?.botData || {};
    const chatBotUIState = chatBotState?.chatBotUIState || {};

    const chats = botData?.chats || [];
    const currentChatIndex = botData?.currentChatIndex ?? -1;
    const questionHint = botData?.questionHint || false;
    const questionHintTitle = botData?.questionHintTitle || false;
    const invitationTypeName = (botData?.invitationTypeName || '').toLowerCase();

    const { userInfo} = useContext(AppContext);
    const { SelfAssessmenInclinicMode } = userInfo.Permissions;
    const { REACT_APP_AWS_S3_GENERAL_AUDIO_URL } = process.env;
    const THANK_YOU_AUDIO_URL = REACT_APP_AWS_S3_GENERAL_AUDIO_URL + '/thank_you.mp3';

    const questionHintModal = chatBotUIState?.questionHintModal || false;
    const isOkButtonLoading = chatBotUIState?.isOkButtonLoading || false;


    const { conversationEnded, onClickJoinTelemedicineNow, onClickJoinTelemedicineLater } = useCurrentQuestion();
    const { clearAndCloseChatbot } = useChatbot();

    const intakeFormState = useSelector(state => state.intakeForm);
    const invitationId = intakeFormState?.invitationId || '';
    const waitingRoomId = intakeFormState?.waitingRoomId || '';
    const bodyRegionList = botData?.bodyRegionList || [];

    useEffect(() => {
        if(chats && currentChatIndex > -1 && chats[currentChatIndex].intent !== CONSTANT.CHATBOT_INTENT.CLICK_AUTOMATIC) {
            playAudio('remove').then();
            playAudio(chats[currentChatIndex]?.audioUrl).then();
        }
    }, [currentChatIndex]);


    const onClickQuestionHint = (chat) => (e) => {
        e.preventDefault();
        let hints = chat.hint || '';
        if (!hints.includes('target="_blank"')) {
            hints = hints.replace(/<a/gi, '<a target="_blank"');
        }

        // noinspection JSCheckFunctionSignatures
        dispatch(setQuestionHint(hints));
        dispatch(setQuestionHintTitle(chat.dialogue));
        toggleQuestionHintModal();
    };

    const toggleQuestionHintModal = () => {
        dispatch(setQuestionHintModal(!questionHintModal));
    };

    const onClickLastChatQuestion = (chat) => async () => {
        playAudio('remove').then();
        let deepCloneChat = deepClone(chat);

        if (deepCloneChat.botName === CONSTANT.CHAT_BOTS.MSK_BOT) {
            if (SelfAssessmenInclinicMode) {
                window.close();
                clearAndCloseChatbot();
                redirectTo(`${routes.myAssessments}/false`)
            }

            playAudio(THANK_YOU_AUDIO_URL).then(() => {
                clearAndCloseChatbot();
                redirectTo(`${routes.myAssessments}/false`)
            });

        } else if (deepCloneChat.botName === CONSTANT.CHAT_BOTS.INTAKE_BOT || deepCloneChat.botName === CONSTANT.CHAT_BOTS.POSTURE_BOT) {
            if (invitationTypeName === CONSTANT.REDIRECT_TYPE.TELEMEDICINE
                || invitationTypeName === CONSTANT.REDIRECT_TYPE.INCLINIC
                || invitationTypeName === CONSTANT.REDIRECT_TYPE.INCLINIC_ENCOUNTER) {

                dispatch(setIsOkButtonLoading(true));
                await conversationEnded(deepCloneChat);
                dispatch(setIsOkButtonLoading(false));
                deepCloneChat.disabled = true;

                const tempChats = deepClone(chats.slice());
                const indexC = tempChats.findIndex(item => item.id === chat.id);
                if (indexC > -1) {
                    tempChats[indexC] = chat;
                    dispatch(updateChatAtIndex({ index: indexC, chat: deepCloneChat }));
                }
            } else {
                redirectTo(`${routes.myAssessments}/true`);
                clearAndCloseChatbot();
            }
        }
    };

    const closeChatBot = () => {
        if(!userInfo?.Permissions?.CanPatientCreateAssessment) {
            redirectTo(`${routes.myAssessments}/true`);
        }
        clearAndCloseChatbot();
    }

    const onConfirmAlert = () => {
        dispatch(closeIntakeForm());
        hideSweetAlert();
        closeChatBot();
    };

    const handleOnClickJoinTelemedicineNow = async (values) => {
        await onClickJoinTelemedicineNow(values, invitationId, bodyRegionList);
    }

    const handleOnClickJoinTelemedicineLater = async (values) => {
        await onClickJoinTelemedicineLater(values, waitingRoomId);
    }

    if(!chats || chats.length === 0 || currentChatIndex < 0) return null;

    return (
        <div className="kt-chat__text w-100 kt-font-xl current-question">
            <div className="current-question-container">
                <div className="question">
                    {!chats[currentChatIndex]?.chatEnded && (
                        <>
                            <div className="heading">
                                <span className="kt-mt-5 text-left">{getAllTypesText(chats[currentChatIndex]?.types)}</span>
                                {!chats[currentChatIndex]?.chatEnded && chats[currentChatIndex]?.header1 && (
                                    <>
                                        <img className="ml-3" src={playBtn} alt="header"/>
                                        <span className="ml-3">
                                                    {chats[currentChatIndex]?.header1}
                                            </span>
                                    </>
                                )}
                            </div>

                            {/*Chat question*/}
                            {chats[currentChatIndex]?.responseType !== CONSTANT.ELEMENT_TYPES.BODYPART && (
                                <div className="question__text d-inline-block">
                                        <span className="color-primary-dark-1"
                                              dangerouslySetInnerHTML={getMarkup(chats[currentChatIndex]?.dialogue)}/>
                                    {chats[currentChatIndex]?.hint && (
                                        <a rel="noopener noreferrer"
                                           className="kt-font-xl"
                                           href={"!#"}
                                           onClick={onClickQuestionHint(chats[currentChatIndex])}>&nbsp;
                                            <i className="fas fa-info-circle"/>
                                        </a>
                                    )}
                                </div>

                            )}

                            <div className={`current-question__line-break ${chats[currentChatIndex]?.questionId === 303 ? 'mb-1vh' : ""}`}/>
                            <CurrentQuestionResponse />
                        </>
                    )}


                    {chats[currentChatIndex]?.chatEnded && (
                        <div className="kt-callout">
                            <div className="kt-callout__body">
                                <div className="kt-callout__content">
                                    <h3 className="kt-callout__title">{chats[currentChatIndex]?.title}</h3>
                                    <div className="kt-callout__desc">
                                            <span className="kt-font-xl"
                                                  dangerouslySetInnerHTML={getMarkup(chats[currentChatIndex]?.dialogue)}/>
                                        {chats[currentChatIndex]?.chatEnded && chats[currentChatIndex]?.intent === CONSTANT.CHATBOT_INTENT.ASSESSMENT_REPORT && (
                                            <MultiBarIndicator scores={chats[currentChatIndex]?.scores}/>
                                        )}
                                        {chats[currentChatIndex]?.buttonText && (
                                            <>
                                                <br/>
                                                <button
                                                    type="button"
                                                    className="btn btn-custom btn-bold btn-upper btn-font-sm  btn-success kt-mt-10"
                                                    disabled={chats[currentChatIndex]?.disabled || isOkButtonLoading}
                                                    onClick={onClickLastChatQuestion(chats[currentChatIndex])}>
                                                    {isOkButtonLoading ? <i className="fa fa-spinner fa-spin"/> : "" }{chats[currentChatIndex]?.buttonText}
                                                </button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

            </div>

            <Modal isOpen={questionHintModal} className="modal-lg">
                <ModalHeader toggle={toggleQuestionHintModal}>
                    <span dangerouslySetInnerHTML={getMarkup(questionHintTitle)}/>
                </ModalHeader>
                <ModalBody>
                    <div dangerouslySetInnerHTML={getMarkup(questionHint)}/>
                </ModalBody>
                <ModalFooter>
                    <Button className="btn btn-success btn-sm" onClick={toggleQuestionHintModal}>Ok</Button>
                </ModalFooter>
            </Modal>

            <IntakeFormWrapper
                onConfirmAlert={onConfirmAlert}
                onClickJoinTelemedicineNow={handleOnClickJoinTelemedicineNow}
                onClickJoinTelemedicineLater={handleOnClickJoinTelemedicineLater}
            />

        </div>
    )
}

export default CurrentQuestion;
