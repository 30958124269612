import { AsyncPaginate } from "react-select-async-paginate";
import {deepClone, loadOptions, makeSerializable} from "../../../../utils/Utils";
import React from "react";
import SkipButton from "../SkipButton";
import ShowSkipped from "../ShowSkipped";
import {useDispatch, useSelector} from "react-redux";
import {setBotAnswerAtIndex, setChatBotErrorMsg, setChats} from "../../../../features/chat/chatbotSlice";
import useChatbot from "../hooks/useChatbot";
import {toast} from "react-toastify";
import ChatRequestDetail from "../../../../models/ChatRequestDetail";
import {getSelectedValue} from "../ChatUtils";
import answerValidationService from "../../../../services/answerValidation/AnswerValidationService";

const DropdownQuestionsAnswers = ({ onClickSkip }) => {
    const dispatch = useDispatch();
    const { prepareChatbotAnswer } = useChatbot();
    const chatBotState = useSelector(state => state.chatBot);
    const botData = chatBotState?.botData || {};
    const chats = botData?.chats || [];
    const currentChatIndex = botData?.currentChatIndex ?? -1;

    // const { prepareChatbotAnswer, setChats, setChatBotErrorMsg } = chatContext;


    const chat = chats[currentChatIndex];

    const onChangeAutoComplete = (selectedValue) => {
        if(!selectedValue) {
            toast.error('Please select an answer');
            console.error('Please select an answer');
            return;
        }
        let answer = makeSerializable(new ChatRequestDetail({ ...selectedValue }));
        dispatch(setBotAnswerAtIndex({ index: currentChatIndex, answer }));
    };

    const onClickAutoCompleteAnswer = (chat) => {
        // if(chat.answers && chat.answers.length <= 0) {
        //     dispatch(setChatBotErrorMsg('Please select an answer'));
        //     return;
        // }

        let deepClonedChat = deepClone(chat);

        const questionId = deepClonedChat.questionId || 0;
        const answer = deepClonedChat.answers[0]?.name || '';
        const responseType = deepClonedChat.responseType || '';
        let validation = answerValidationService.validate(questionId, responseType, answer);

        if(!validation?.valid) {
            dispatch(setChatBotErrorMsg(validation?.message || ''));
            return;
        }
        prepareChatbotAnswer(chat);
    };

    return (
        <>
            {chat && !chat.answered && (
                <>
                    <div className="responses d-block">
                        <div className="form-group">
                            <AsyncPaginate
                                menuPlacement="top"
                                isClearable={false}
                                isSearchable={true}
                                isRtl={false}
                                backspaceRemovesValue={false}
                                closeMenuOnSelect={true}
                                name="chatbotAutoComplete"
                                className="mmh-multi-select kt-p0 kt-font-xl"
                                optionClassName="needsclick"
                                value={getSelectedValue(chat)}
                                loadOptions={loadOptions(deepClone(chat.responses))}
                                onChange={(value) => onChangeAutoComplete(value)}
                                classNamePrefix="mmh"
                            />
                        </div>
                        <button type="button" className="btn btn-primary btn-lg"
                                onClick={() => onClickAutoCompleteAnswer(chat)}>
                            OK
                        </button>
                    </div>
                    <SkipButton chat={chat} onClickSkip={onClickSkip} />
                </>
            )}
            {chat && chat.answered && (
                <div className="answers">
                    <div className="kt-list-timeline">
                        <div className="kt-list-timeline__items">
                            {chat.answers && chat.answers.length > 0 && (
                                chat.answers.map((chatAnswer, indexR) => {
                                    return (
                                        <div className="kt-list-timeline__item kt-m0" key={indexR}>
                                            <span
                                                className="kt-list-timeline__badge kt-list-timeline__badge--brand">
                                            </span>
                                            <span className="kt-list-timeline__text kt-font-xl">{chatAnswer.name}</span>
                                        </div>
                                    )
                                })
                            )}
                        </div>
                    </div>
                    <ShowSkipped chat={chat}/>
                </div>
            )}
        </>
    )
};

export default DropdownQuestionsAnswers;