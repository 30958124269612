import React, { useState, useRef, useEffect } from "react";
import { checkAllPainQuestionAnswered, getFirstNotAnsweredQuestionInd } from "../Services/MovementService";
import ConfirmationDialog from "../../Popups/ConfirmationDialog";
import PerfectScrollbar from "react-perfect-scrollbar";
import scrollIntoView from "scroll-into-view-if-needed";
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
import crossBtn from "../../../../assets/img/utils/cross.svg";
import CONSTANT from "../../../../constants/Constant";
import PainQuestionItem from "./PainQuestionItem";
import {
    getConditionalQuestionIds,
    willShowQuestion
} from "../utilities/PainQuestionFormUtils";

const  PainQuestionForm = ({painQuestionList, setPainQuestionList, isClickedOnSubmitBtn, onClickAnalyzeHandler, isAnalyzingVideo}) => {
    const [nextNotAnsweredPainQuestionInd, setNextNotAnsweredPainQuestionInd] = useState(-1);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    const painQuestionRef = useRef(null);
    const conditionalQuestionListSetRef = useRef(new Set());

    useEffect(() => {
        conditionalQuestionListSetRef.current = getConditionalQuestionIds(painQuestionList);

        painQuestionList.length > 0
            ? updateNextNotAnsweredQuestionInxState(painQuestionList)
            : setNextNotAnsweredPainQuestionInd(-1);

    }, [painQuestionList]);

    const updateNextNotAnsweredQuestionInxState = (painQuestionList, moveNextQuestionAsClickedNextOnCheckBoxQuestion = false) => {
        let newNextNotAnsweredPainQuestionInd = getFirstNotAnsweredQuestionInd(painQuestionList, [...conditionalQuestionListSetRef.current]);
        if(painQuestionList[nextNotAnsweredPainQuestionInd]?.QuestionType === CONSTANT.ELEMENT_TYPES.CHECKBOX && !moveNextQuestionAsClickedNextOnCheckBoxQuestion) {
            return;
        }

        setNextNotAnsweredPainQuestionInd(newNextNotAnsweredPainQuestionInd);
    }

    const onChangeAnswer = (question, answerId) => {
        let pQuestionList = painQuestionList;
        let pQuestion = pQuestionList.find(x => x.QuestionId === question.QuestionId);

        if(pQuestion) {
            if(pQuestion?.QuestionType === CONSTANT.ELEMENT_TYPES.CHECKBOX) {
                let tempAnswerList = pQuestion?.AnswerList ?? [];
                let doesAlreadySelectedCurrentAnswer = tempAnswerList.find(answer => answer === answerId);
                if(doesAlreadySelectedCurrentAnswer) {
                    tempAnswerList = tempAnswerList.filter(currentAnswerId => currentAnswerId !== answerId);
                } else {
                    tempAnswerList.push(answerId);
                }

                pQuestion.AnswerList = tempAnswerList;
                pQuestion.AnswerId = 0;
            } else {
                pQuestion.AnswerId = answerId;
                pQuestion.AnswerList = [];
            }
        }
        setPainQuestionList(prev => pQuestionList.slice());
        if(checkAllPainQuestionAnswered(pQuestionList.slice()) && question.QuestionType !== CONSTANT.ELEMENT_TYPES.CHECKBOX) {
            setShowConfirmDialog(true);
        }
        question.QuestionType !== CONSTANT.ELEMENT_TYPES.CHECKBOX && onExecuteScroll(question.QuestionId);
        updateNextNotAnsweredQuestionInxState(pQuestionList);
        clearAnswersForIrrelevantQuestions();
    };

    const clearAnswersForIrrelevantQuestions = () => {
        const allChildQuestionsId = [...conditionalQuestionListSetRef.current];
        const updatedPainQuestions = painQuestionList.map(question => {
            if (allChildQuestionsId.includes(question.QuestionId) && !willShowQuestion(question, painQuestionList, [...conditionalQuestionListSetRef.current])) {
                if(question.QuestionType === CONSTANT.ELEMENT_TYPES.CHECKBOX) {
                    return { ...question, AnswerList: [], AnswerId: 0 };
                } else {
                    return { ...question, AnswerList: [], AnswerId: 0 };
                }
            }
            return question;
        });

        setPainQuestionList(updatedPainQuestions);
    };


    const onClickNoConfirmDialog = () => {
        if(showConfirmDialog) closeConfirmDialog();
    }

    const closeConfirmDialog = () => {
        setShowConfirmDialog(false);
    }

    const onClickYesAtConfirmDialog = async () => {
        onClickAnalyzeHandler && await onClickAnalyzeHandler();
        closeConfirmDialog();
    }

    const getIndexOfQuestionId = (questionId) => {
        return painQuestionList.findIndex(item => item.QuestionId === questionId)
    }

    const getNextQuestionIndex = (questionId) => {
        const currentQuestionInd = getIndexOfQuestionId(questionId);
        const painQuestionsLength = painQuestionList.length;
        if(currentQuestionInd === painQuestionsLength - 1) return -1;
        else return currentQuestionInd + 1;
    }

    const getBlurStyle = () => {
        return (nextNotAnsweredPainQuestionInd > -1) ? " pointer-none blurry z-0" : "";
    }

    if(!painQuestionList || painQuestionList.length === 0)
        return;

    const onExecuteScroll = (questionId) => {
        try {
            const scrollIntoViewSmoothly =
                'scrollBehavior' in document.documentElement.style
                    ? scrollIntoView
                    : smoothScrollIntoView

            const nextPainQuestionIndex = getNextQuestionIndex(questionId);
            if(nextPainQuestionIndex === -1) return;
            const nextPainQuestionId = painQuestionList[nextPainQuestionIndex].QuestionId;

            setTimeout(() => {
                const element = document.getElementById(`pain-question-${nextPainQuestionId}`);

                if (!questionId && painQuestionRef.current) {
                    painQuestionRef.current.scrollTop = 0;
                    return;
                }

                if (element) {
                    // noinspection JSIgnoredPromiseFromCall
                    scrollIntoViewSmoothly(element);
                }
            }, 500);
        } catch (error) {
            console.error(error);
        }
    };

    return(
        <div className="position-relative">
            {nextNotAnsweredPainQuestionInd > -1 && (
                <div className="position-sticky top-0 w-100 z-10 bg-color-white pain-question__not-answered">
                    <div className="pain-question__container d-flex justify-content-center align-items-center flex-column">
                        <div>
                            {painQuestionList[nextNotAnsweredPainQuestionInd] && (
                                    <PainQuestionItem
                                        key={nextNotAnsweredPainQuestionInd}
                                        painQuestion={painQuestionList[nextNotAnsweredPainQuestionInd]}
                                        onChangeAnswer={onChangeAnswer}
                                        isClickedOnSubmitBtn={isClickedOnSubmitBtn}
                                    />
                                )}
                        </div>

                        {painQuestionList[nextNotAnsweredPainQuestionInd]?.QuestionType === CONSTANT.ELEMENT_TYPES.CHECKBOX && (
                            <button
                                type="button"
                                className="btn bg-color-bright-light-green kt-rounded text-white ml-auto"
                                onClick={() => updateNextNotAnsweredQuestionInxState(painQuestionList, true)}>
                                Next
                            </button>
                        )}
                    </div>
                    <div className="mr-3 mb-3 cursor-pointer pain-question__cross_btn" onClick={() => setNextNotAnsweredPainQuestionInd(-1)}>
                        <img src={crossBtn} alt="cross" className="w-100"/>
                    </div>
                </div>
            )}

            <PerfectScrollbar
                containerRef={el => (painQuestionRef.current = el)}
                className={`${getBlurStyle()}`}>
                <div className="pain-question-modal">
                    {painQuestionList.map((painQuestion,index) => (
                        <div className="">
                            {willShowQuestion(painQuestion, painQuestionList, [...conditionalQuestionListSetRef.current]) ? (
                                <PainQuestionItem
                                    key={index}
                                    painQuestion={painQuestion}
                                    onChangeAnswer={onChangeAnswer}
                                    isClickedOnSubmitBtn={isClickedOnSubmitBtn}
                                />)
                                : ""
                            }
                        </div>
                    ))}

                    <ConfirmationDialog
                        confirmText="Yes"
                        cancelText="No"
                        isYesInLeft={true}
                        show={showConfirmDialog}
                        onClickHeaderClose={onClickNoConfirmDialog}
                        toggleDialog={onClickNoConfirmDialog}
                        onConfirm={onClickYesAtConfirmDialog}
                        showLoadingInConform={isAnalyzingVideo}
                    >
                        Do you want to save?
                    </ConfirmationDialog>
                </div>
            </PerfectScrollbar>
        </div>
    );
}

export default PainQuestionForm;