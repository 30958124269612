import React, { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import "./IntakeForm.scss";
import DatePickerComponent from "./DatePickerComponent";
import GLOBAL from "../../../global";

const IntakeForm = (props) => {
    const [clickedButtonId, setClickedButtonId] = useState(null);

    const initialValuesForIntake = () => {
        return {
            PatientName: GLOBAL.USER_INFO['FullName'],
            PatientDateOfBirth: new Date(GLOBAL.USER_INFO['DateofBirth']),
            PatientSignature: "",
            Today: new Date(),
            PersonalRepresentative: "",
            Date: new Date(),
            terms_and_condition: false,
        };
    };

    const validationSchema = () => {
        return Yup.object().shape({
            PatientName: Yup.string().required("This field is required"),
            PatientDateOfBirth: Yup.date()
                .required("This field is required")
                .typeError("This field is required"),
            PatientSignature: Yup.string().required("This field is required"),
            Today: Yup.date()
                .required("This field is required")
                .typeError("This field is required"),
            PersonalRepresentative: Yup.string().required("This field is required"),
            Date: Yup.date()
                .required("This field is required")
                .typeError("This field is required"),
            terms_and_condition: Yup.boolean().oneOf(
                [true],
                "You must accept the terms and conditions"
            ),
        });
    };

    const onSubmitDocument = (values) => {
        if(props?.onClickSubmitIntakeForm) {
            props.onClickSubmitIntakeForm(values);
        }
        else if (clickedButtonId === "JOIN_NOW") {
            props.onClickJoinTelemedicineNow(values);
        } else if (clickedButtonId === "JOIN_LATER") {
            props.onClickJoinTelemedicineLater(values);
        }
    };

    return (
        <div>
            <Formik
                initialValues={initialValuesForIntake()}
                onSubmit={onSubmitDocument}
                validationSchema={validationSchema}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      // isSubmitting,
                  }) => (
                    <Form
                        className="kt-form kt-form--label-left "
                        onSubmit={(jsEvent) => {
                            const { id } = jsEvent.nativeEvent.submitter;
                            setClickedButtonId(id);
                            return handleSubmit(jsEvent);
                        }}>

                        <div className="modal_detail_patient_info_div mb-3 patient_acknowledge_info_form_border p-4 m-4">
                            <h3 className="patient_acknowledge_info_header">Patient Acknowledgement</h3>
                            <p className='patient_acknowledge_info_label_content'>
                                My signature verifies that I have been provided a copy of My MedicalHUB.{" "}
                                {/*<span*/}
                                {/*    onClick={props.handler}*/}
                                {/*    className="intake_form_Notice_of_Privacy_Practices_to_review">*/}
                                {/*    Notice of Privacy Practices to review.*/}
                                {/*</span>*/}
                                I understand that if I would like a copy of this Notice, My MedicalHUB's
                                will provide me with a copy of this document.
                            </p>
                            <div className="row">
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group form-md-line-input">
                                        <label
                                            className="col-auto col-form-label patient_acknowledge_info_label_content"
                                            htmlFor="PatientName">
                                            Patient's name*
                                        </label>
                                        <div className="col-md-9">
                                            <input
                                                className="form-control patient_acknowledge_info_input_field"
                                                id="PatientName"
                                                name="PatientName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.PatientName}
                                                type="text"
                                            />
                                            {errors.PatientName && touched.PatientName && (
                                                <div className="intake_form_error_msg">
                                                    {errors.PatientName}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group form-md-line-input">
                                        <label
                                            className="col-auto col-form-label patient_acknowledge_info_label_content"
                                            htmlFor="PatientDateOfBirth">
                                            Patient's date of birth
                                        </label>
                                        <div className="col-md-9">
                                            <div className="intake_form_datepicker_container">
                                                <DatePickerComponent name="PatientDateOfBirth" design="patient_acknowledge_info_input_field"/>
                                            </div>
                                            {errors.PatientDateOfBirth &&
                                                touched.PatientDateOfBirth && (
                                                    <div className="intake_form_error_msg">
                                                        {errors.PatientDateOfBirth}
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group form-md-line-input has-success">
                                        <label
                                            className="col-md-6 control-label patient_acknowledge_info_label_content"
                                            htmlFor="PatientSignature">
                                            Patient's signature*
                                        </label>
                                        <div className="col-md-9">
                                            <input
                                                className={`form-control patient_acknowledge_info_input_field`}
                                                type="text"
                                                id="PatientSignature"
                                                name="PatientSignature"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.PatientSignature}
                                            />
                                            {errors.PatientSignature && touched.PatientSignature && (
                                                <div className="intake_form_error_msg">
                                                    {errors.PatientSignature}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group form-md-line-input">
                                        <label className="col-md-3 control-label patient_acknowledge_info_label_content" htmlFor="Today">
                                            Today
                                        </label>
                                        <div className="col-md-9">
                                            <div className="intake_form_datepicker_container">
                                                <DatePickerComponent name="Today" design="patient_acknowledge_info_input_field"/>
                                            </div>
                                            {errors.Today && touched.Today && (
                                                <div className="intake_form_error_msg">
                                                    {errors.Today}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal_detail_patient_info_div patient_acknowledge_info_form_border p-4 m-4">
                            <h2 className="modal_detail_patient_info_title patient_acknowledge_info_header">
                                Patient Information
                            </h2>
                            <p className='patient_acknowledge_info_label_content'>
                                I understand that I have the right to revoke this authorization
                                at any time and that I have the right to inspect or copy the
                                protected health information to be disclosed as described in
                                this document. I understand that a revocation is not effective
                                in cases where the information has already been disclosed but
                                will be effective going forward.
                            </p>
                            <p className='patient_acknowledge_info_label_content'>
                                I understand that information used or disclosed as a result of
                                this authorization may be subject to redisclosure by the
                                recipient and may no longer be protected by federal or state
                                law.
                            </p>
                            <p className='patient_acknowledge_info_label_content'>
                                I understand that I have the right to refuse to sign this
                                authorization.
                                <span style={{ textDecoration: "underline" }}>
                  <strong>
                    This authorization shall be in effect until revoked by the
                    patient.
                  </strong>
                </span>
                            </p>
                            <div className="row mt-5">
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group form-md-line-input">
                                        <label
                                            className="col-auto control-label patient_acknowledge_info_label_content"
                                            htmlFor="PersonalRepresentative">
                                            Signature of Patient or Personal Representative*
                                        </label>
                                        <div className="col-md-9">
                                            <input
                                                className="form-control patient_acknowledge_info_input_field"
                                                type="text"
                                                id="PersonalRepresentative"
                                                name="PersonalRepresentative"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.PersonalRepresentative}
                                                aria-required="true"
                                            />
                                            {errors.PersonalRepresentative &&
                                                touched.PersonalRepresentative && (
                                                    <div className="intake_form_error_msg">
                                                        {errors.PersonalRepresentative}
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="form-group form-md-line-input">
                                        <label className="col-md-3 control-label patient_acknowledge_info_label_content" htmlFor="Date">
                                            Date
                                        </label>
                                        <div className="col-md-9">
                                            <div className="intake_form_datepicker_container">
                                                <DatePickerComponent name="Date" design="patient_acknowledge_info_input_field"/>
                                            </div>
                                            {errors.Date && touched.Date && (
                                                <div className="intake_form_error_msg">
                                                    {errors.Date}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="m-4">
                            <label className="kt-checkbox kt-checkbox--tick kt-checkbox--success patient_acknowledge_info_label_content">
                                <input
                                    type="checkbox"
                                    name="terms_and_condition"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                I agree to consent{" "}
                                <label
                                    onClick={props.termsAndConditionModalHandler}
                                    className="intake_form_Notice_of_Privacy_Practices_to_review ps-2 patient_acknowledge_info_label_content text-underline-hover"
                                >
                                    Terms & Services
                                </label> to telemedicine
                                <span></span>
                                {errors.terms_and_condition && touched.terms_and_condition &&
                                    <p className="intake_form_error_msg">{errors.terms_and_condition}</p>}
                            </label>
                            <div className="d-flex justify-content-center align-items-center">
                                {props.children}
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default IntakeForm;
