import "./Dashboard.scss"
import {useDispatch, useSelector} from "react-redux";
import React, {forwardRef, useContext, useEffect, useRef, useState} from 'react';
import { Redirect, useHistory, useLocation } from "react-router-dom";
import "react-step-progress-bar/styles.css";

import routes from "../../constants/Routes";
import GLOBAL from "../../global";
import AppContext from "../../contexts/AppContext";
import {getAssessmentCompletion, isEmptyObject} from "../../utils/Utils";
import CONSTANT from "../../constants/Constant";
import DashboardItem from "./DashboardItem";
import {_getInCompleteAssessmentType} from "./dashboardUtills"
import {toast} from "react-toastify";
import NotificationInfo from "./notification/NotificationInfo";
import ConfirmationDialog from "../shared/Popups/ConfirmationDialog";
import Info from "../../assets/img/utils/info.svg";
import BiometricMovementAndCautionQuestionContainer from "../shared/MovementModal/BiometricMovementAndCautionQuestionContainer";
import {createAssessment} from "./notification/data/api";
import ResumeBtn from "./notification/ResumeBtn";
import MyAssessmentsDetail from "../my-assessments/MyAssessmentsDetail";
import {redirectTo} from "../shared/chatbotNew/ChatServices";
import IntakeFormWrapper
    from "../shared/intake-form/IntakeFormWrapper/IntakeFormWrapper";
import Assessment from "../../models/Assessment";
import SweetAlert from "react-bootstrap-sweetalert";
import StepBar from "../shared/stepbar/StepBar";
import {
    initIntakeForm,
    closeIntakeForm
} from "../../features/intakeForm/intakeFormSlice";
import {setOpenChatBot} from "../../features/chat/chatbotSlice";
import {saveIntake} from "../shared/intake-form/intake-form-service";

const DashboardBody = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { allNotifications, isLoadingNotification } = props;
    const { authenticated, setTelemedicineProperties, resetTelemedicineKeys, setFollowupForAssessmentPage, setLastIncompletedAssessmentsForAssessmentPage, setSelectedTabForEditAssessment } = useContext(AppContext);

    const intakeFormState = useSelector(state => state.intakeForm);
    const showIntakeForm = intakeFormState?.showIntakeForm || false;

    const patientId = (GLOBAL.USER_INFO && GLOBAL.USER_INFO['ContactId']) || '';
    const [lastIncompletedAssessments, setLastIncompletedAssessments] = useState([]);
    const [followUpAssessments, setFollowUpAssessments] = useState([]);
    const [redirectToAssessmentPage, setRedirectToAssessmentPage] = useState(false);

    const [openBiometricMovementAndCautionQuestionContainer, setOpenBiometricMovementAndCautionQuestionContainer] = useState(false);
    const [isShowIsFollowUpPopup, setIsShowIsFollowUpPopup] = useState(false);
    const [showLoadingInConformTelemedicine, setShowLoadingInConformTelemedicine] = useState(false);
    const [selectedTelemedicineInvitationId, setSelectedTelemedicineInvitationId] = useState(null);
    const [invitationListDoNotHaveTestIds, setInvitationListDoNotHaveTestIds] = useState([]);
    const [showLoadingAtJoinNowBtn, setShowLoadingAtJoinNowBtn] = useState(false);
    const [showDetailInstruction, setShowDetailInstruction] = useState(false);

    const selectedBodyRegionsAndTestId = useRef({});
    const notificationInformation = useRef({});
    const conformationAlertContentRef = useRef({});

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        let tempLastIncompletedAssessments = [];
        let followUpAssessments = [];
        let tempInvitationListDoNotHaveTestId = [];
        if(allNotifications && allNotifications.length > 0) {
            tempLastIncompletedAssessments = allNotifications.filter(notification => notification.IsLastIncomplete && !notification.IsFollowup);

            tempInvitationListDoNotHaveTestId = allNotifications.filter(notification => !notification.TestId);
            followUpAssessments = allNotifications.filter(notification => notification.IsFollowup);

            setLastIncompletedAssessments(tempLastIncompletedAssessments);
            setInvitationListDoNotHaveTestIds(tempInvitationListDoNotHaveTestId);
            setFollowUpAssessments(followUpAssessments);
        }
        handleNewUser();
        handleRemoteAssistance();

    }, [allNotifications]);

    const handleRemoteAssistance = () => {
        let query = new URLSearchParams(location?.search);
        let invitationId = query.get('InvGId') || '';
        let bodyRegionListParam = query.get('BodyRegionIds') || '';
        let bodyRegionList = bodyRegionListParam.split(',').map(Number).filter(Boolean);
        let testId = query.get('TestId') || '';
        let isRemoteAssistance = query.get('RA') === 'true'; // Will open self as telemedicine?
        if(isRemoteAssistance && invitationId && bodyRegionList.length > 0 && testId) {
            setTelemedicineProperties(invitationId, bodyRegionList, null, removeURLQueryParams);
        }
    }

    const removeURLQueryParams = () => {
        history.replace({ pathname: location.pathname });
    }

    const handleNewUser = () => {
        if(GLOBAL.USER_INFO?.IsNewUser && allNotifications.length > 0) {
            if(!GLOBAL.USER_INFO?.CreditCardCount && GLOBAL.IS_SUBSCRIPTION_ENABLED) {
                return;
            }

            const invitationData = allNotifications.slice(0, 1)[0];
            dispatch(setOpenChatBot({ invitationData, patientId }));
        }
    }


    if (!authenticated) {
        return <Redirect to={`${routes.landing}`}/>;
    }

    const onCloseTelemedicineModal = () => {
        resetTelemedicineKeys();
        setSelectedTelemedicineInvitationId(null);
        setShowLoadingAtJoinNowBtn(false);
        setShowLoadingInConformTelemedicine(false);
    }

    const onOpenTelemedicineModal = () => {
        setIsShowIsFollowUpPopup(false);
        conformationAlertContentRef.current = {};
    }

    const stepBar = (assessment = {}) => {
        const tempAssessment = isEmptyObject(assessment) ? lastIncompletedAssessments : assessment;

        return (
            <div className="padding-bottom-1-rem">
                <div className="margin-bottom-1-rem"></div>
                <StepBar groups={getAssessmentCompletion(tempAssessment?.AssessmentStatus)}
                         isShowName={true}
                         isShowFullName={false}
                />
            </div>
        )
    }


    //TODO: Will be removed after testing
    const preparePayload = (BodyRegionList, InvitationId) => {
        return {
            BodyRegionList,
            InvitationId
        }
    }

    const redirectToEditAssessmentPage = (notification) => {
        notificationInformation.current = notification
        let firstInCompletedGroup = _getInCompleteAssessmentType(notification?.AssessmentStatus);
        let keyOfFirstIncompleteGroup = CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key;
        if(!isEmptyObject(firstInCompletedGroup)) {
            keyOfFirstIncompleteGroup = firstInCompletedGroup?.key || CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key;
            if(keyOfFirstIncompleteGroup === CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.key) keyOfFirstIncompleteGroup = CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key
        }
        setSelectedTabForEditAssessment(keyOfFirstIncompleteGroup);

        setRedirectToAssessmentPage(true)
    }

    const startHandler = async (notification) => {
        if(notification.IsFormBasedIntakeEnabled && !notification.TestId) {
            let testId = await createAssessment(preparePayload(notification.BodyRegionList, notification.InvitationId))
            if(testId) {
                redirectToEditAssessmentPage({...notification, TestId: testId})
            } else {
                toast.error(<div>Failed to create assessment.</div>);
            }
        } else if(notification.TestId) {
            redirectToEditAssessmentPage(notification)
        } else {
            dispatch(setOpenChatBot({ invitationData: notification, patientId }));
        }
    }

    const openBiometricCautionQuestionModal = (notification) => {
        setOpenBiometricMovementAndCautionQuestionContainer(true);
        selectedBodyRegionsAndTestId.current = {
            testId: notification.TestId,
            selectedBodyRegions: notification.BodyRegionList
        };
    }

    const handleLastInCompleteAssessmentAction = (notification) => {
        const firstInCompleteGroup = _getInCompleteAssessmentType(notification.AssessmentStatus).key;

        if(firstInCompleteGroup === CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.key && +notification.InvitationTypeId === CONSTANT.INVITATION_TYPE.Self) {
            openBiometricCautionQuestionModal(notification);
        } else if (firstInCompleteGroup === CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.key && +notification.InvitationTypeId === CONSTANT.INVITATION_TYPE.Telemedicine) {
            handleJoinTelemedicine(notification);
        } else {
            // redirectToEditAssessmentPage(notification)
            dispatch(setOpenChatBot({ invitationData: notification, patientId, testIdToResumeChat: notification?.TestId }));
        }
    }

    const handleJoinTelemedicine = (notification) => {
        const isIntakeSigned = (GLOBAL.USER_INFO && GLOBAL.USER_INFO['IsIntakeSigned']) || false;

        if(!isIntakeSigned) {
            dispatch(initIntakeForm({
                showIntakeForm: true,
                bodyRegionList: notification.BodyRegionList || [],
                invitationTypeName: notification.InvitationTypeName || '',
            }));
        } else {
            joinTelemedicine(notification)
        }
    }

    const onClickSubmitIntakeForm = async (values) => {
        GLOBAL.ASSESSMENT = new Assessment({ TestId: notificationInformation.current?.TestId });
        const response = await saveIntake(values);
        const { data, success, error } = response;
        if(!success) {
            toast.error(error || 'Failed to save intake form');
            return;
        }
        joinTelemedicine(notificationInformation.current);
    }

    const joinTelemedicine = (notification) => {
        dispatch(closeIntakeForm());
        setTelemedicineProperties(notification.InvitationId, notification.BodyRegionList, onOpenTelemedicineModal, onCloseTelemedicineModal);
        setSelectedTelemedicineInvitationId(notification.InvitationId);
        setShowLoadingAtJoinNowBtn(true);
    }

    const handleFollowupNotification = (notification) => {
        notificationInformation.current = notification;

        if (notification.InvitationTypeId === CONSTANT.INVITATION_TYPE.Telemedicine) {
            setTelemedicineAlert();
        } else if (notification.InvitationTypeId === CONSTANT.INVITATION_TYPE.Self) {
            setSelfInvitationAlert();
        } else if(notification.InvitationTypeId === CONSTANT.INVITATION_TYPE.InClinic) {
            setInClinicAlert();
        }

        setIsShowIsFollowUpPopup(true);
    };

    const setTelemedicineAlert = () => {
        const commonContent = getCommonAlertContentForInclinic();
        Object.assign(conformationAlertContentRef.current, {
            yesBtnText: "Join Telemedicine",
            noBtnText: "Update My Background",
            content: commonContent,
            onClickYes: () => onClickJoinTelemedicine(),
            onClickNo: () => redirectToEditAssessmentPage(notificationInformation.current),
            headerText: "Update Your Information?"
        });
    };

    const setInClinicAlert = () => {
        const commonContent = getCommonAlertContentForInclinic();
        Object.assign(conformationAlertContentRef.current, {
            noBtnText: "Update My Background",
            content: commonContent,
            onClickNo: () => redirectToEditAssessmentPage(notificationInformation.current),
            headerText: "Update Your Information?",
            showYesBtn: false
        });
    };

    const setSelfInvitationAlert = () => {
        const commonContent = getCommonAlertContentForInclinic();
        Object.assign(conformationAlertContentRef.current, {
            yesBtnText: "Resume Assessment",
            noBtnText: "Update My Background",
            content: commonContent,
            onClickYes: () => {
                openBiometricCautionQuestionModal(notificationInformation.current);
                setIsShowIsFollowUpPopup(false);
                conformationAlertContentRef.current = {}
            },
            onClickNo: () => redirectToEditAssessmentPage(notificationInformation.current),
            headerText: "Update Your Information?"
        });
    };

    const getCommonAlertContentForInclinic = () => (
        <>
            <p>Would you like to update your background information (Activities of Daily Living, Medical History, Chief Complaints)?
                <span
                    onClick={() => setShowDetailInstruction(true)}
                    className="edit-assessment-info-container kt-ml-10"
                >
                <img src={Info} alt="Information" className='tele_eye_btn cursor-pointer' />
            </span>
            </p>
        </>
    );

    const notificationHandler = async (notification, isFromUpcomingMovement = false) => {
        if(notification.IsFollowup) {
            handleFollowupNotification(notification);
        } else if(notification.IsLastIncomplete && !isFromUpcomingMovement) {
            notificationInformation.current = notification;
            await handleLastInCompleteAssessmentAction(notification)
        } else if(notification?.TestId && (+notification.InvitationTypeId === CONSTANT.INVITATION_TYPE.Telemedicine)) {
            setTelemedicineProperties(notification.InvitationId, notification.BodyRegionList, onOpenTelemedicineModal, onCloseTelemedicineModal);
            setSelectedTelemedicineInvitationId(notification.InvitationId);
        }
        else {
            await startHandler(notification)
        }
    }

    const onClickJoinTelemedicine = () => {
        handleJoinTelemedicine(notificationInformation.current);
        setShowLoadingInConformTelemedicine(true);
        setIsShowIsFollowUpPopup(false);
        conformationAlertContentRef.current = {}
    }

    const handleClickMore = (type) => {
        if(type === CONSTANT.ASSESSMENT_TYPE.FOLLOW_UP.key) {
            setFollowupForAssessmentPage(true);
        } else if(type === CONSTANT.ASSESSMENT_TYPE.LAST_INCOMPLETE.key) {
            setLastIncompletedAssessmentsForAssessmentPage(true);
        }

        redirectTo(routes.myAssessments + '/false');
    }


    return (
        <>
            {redirectToAssessmentPage && notificationInformation.current?.TestId &&
                notificationInformation.current?.BodyRegionList && notificationInformation.current?.BodyRegionList instanceof Array
                &&
                <>
                    <Redirect
                        to={`${routes.editAssessment}/${notificationInformation.current.TestId}?groupId=${_getInCompleteAssessmentType(notificationInformation.current?.AssessmentStatus)?.key}`}/>
                </>

            }
            {!isLoadingNotification && (
                <div className="col-xl-12">
                    {(invitationListDoNotHaveTestIds.length > 0) ? (
                            <DashboardItem title={`New Assessment Invitation`} largeTitle="NEW ASSESSMENT INVITATION" overflow={true} showOnlyOneImage={false}>
                                <div>
                                    {invitationListDoNotHaveTestIds.map((item, index) => (
                                        <div className="dashboard-item" key={index}>
                                            <div className="">
                                                <div className="d-flex justify-content-between">
                                                    <div className="d-flex justify-content-center align-content-center flex-wrap dashboard-item-wrapper">
                                                        <div className="">
                                                            <NotificationInfo
                                                                notificationData={{
                                                                    MeetingDate: item?.MeetingDate,
                                                                    BodyRegionList: item?.BodyRegionList,
                                                                    InvitationTypeName: item?.InvitationTypeName,
                                                                    InvitationTypeId: item?.InvitationTypeId,
                                                                }}
                                                            >
                                                                <ResumeBtn
                                                                    InvitationTypeId={item?.InvitationTypeId}
                                                                    firstIncompleteGroupName={_getInCompleteAssessmentType(item?.AssessmentStatus)}
                                                                    selectedTelemedicineInvitationId={selectedTelemedicineInvitationId}
                                                                    isLoading={!showLoadingAtJoinNowBtn && selectedTelemedicineInvitationId === item.InvitationId}
                                                                    notificationHandler={notificationHandler}
                                                                    notificationData={item}
                                                                    InvitationId={item?.InvitationId}
                                                                    IsLastIncomplete={item?.IsLastIncomplete}
                                                                    isFromUpcomingMovement={true}
                                                                />
                                                            </NotificationInfo>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dashboard-item-spepbar-container" style={{height: "1rem"}}></div>
                                        </div>
                                    ))}
                                </div>
                            </DashboardItem>
                        )
                        :
                        null
                    }

                    {lastIncompletedAssessments.length > 0 ? (
                        <DashboardItem title="Last Incompleted Assessment" largeTitle="INCOMPLETE ASSESSMENT"
                                       showOnlyOneImage={false}>
                            {lastIncompletedAssessments.slice(0,2).map((item, index) => (
                                <div className="dashboard-item" key={index}>
                                    <div className="">
                                        <div
                                            className="d-flex justify-content-between align-content-center flex-wrap dashboard-item-wrapper">
                                            <div className="notification wrapper">
                                                <NotificationInfo
                                                    notificationData={{
                                                        MeetingDate: item?.MeetingDate,
                                                        TestId: item?.TestId,
                                                        BodyRegionList: item?.BodyRegionList,
                                                        InvitationTypeName: item.InvitationTypeName,
                                                        InvitationTypeId: item?.InvitationTypeId,
                                                    }}
                                                >
                                                    <ResumeBtn
                                                        InvitationTypeId={item?.InvitationTypeId}
                                                        firstIncompleteGroupName={_getInCompleteAssessmentType(item?.AssessmentStatus)}
                                                        isLoading={showLoadingAtJoinNowBtn && selectedTelemedicineInvitationId === item.InvitationId}
                                                        notificationHandler={notificationHandler}
                                                        notificationData={{
                                                            ...item
                                                        }}
                                                        InvitationId={item?.InvitationId}
                                                        IsLastIncomplete={item?.IsLastIncomplete}
                                                    />
                                                </NotificationInfo>
                                            </div>
                                        </div>
                                    </div>
                                    {stepBar(item)}
                                    <div className="dashboard-item-spepbar-container" style={{height: "1rem"}}></div>
                                </div>
                            ))}
                            {lastIncompletedAssessments.length > 2 && (
                                <div className="w-full pb-3 d-flex justify-content-center">
                                    <button
                                        className="button button-dashboard"
                                        onClick={() => handleClickMore(CONSTANT.ASSESSMENT_TYPE.LAST_INCOMPLETE.key)}>
                                        More
                                    </button>
                                </div>
                            )}
                        </DashboardItem>
                    )
                        :
                        null
                    }

                    {followUpAssessments.length > 0 ? (
                        <DashboardItem title="Follow Up Assessment" largeTitle="FOLLOW-UP ASSESSMENT" showOnlyOneImage={false}>
                            {followUpAssessments.slice(0, 2).map((item, index) => (
                                <div className="dashboard-item" key={index}>
                                    <div className="">
                                        <div className="d-flex justify-content-between align-content-center flex-wrap dashboard-item-wrapper">
                                            <div className="notification wrapper">
                                                <NotificationInfo
                                                    notificationData={{
                                                        MeetingDate: item?.MeetingDate,
                                                        TestId: item?.TestId,
                                                        BodyRegionList: item?.BodyRegionList,
                                                        InvitationTypeName: item.InvitationTypeName,
                                                        InvitationTypeId: item?.InvitationTypeId,
                                                    }}
                                                >
                                                    <ResumeBtn
                                                        InvitationTypeId={item?.InvitationTypeId}
                                                        firstIncompleteGroupName={_getInCompleteAssessmentType(item?.AssessmentStatus)}
                                                        isLoading={showLoadingAtJoinNowBtn && selectedTelemedicineInvitationId === item.InvitationId}
                                                        notificationHandler={notificationHandler}
                                                        notificationData={{
                                                            ...item
                                                        }}
                                                        InvitationId={item?.InvitationId}
                                                        IsLastIncomplete={item?.IsLastIncomplete}
                                                    />
                                                </NotificationInfo>
                                            </div>
                                        </div>
                                    </div>
                                    {stepBar(item)}
                                    <div className="dashboard-item-spepbar-container" style={{height: "1rem"}}></div>
                                </div>
                            ))}
                            {followUpAssessments.length > 2 && (
                                <div className="w-full pb-3 d-flex justify-content-center">
                                    <button
                                        className="button button-dashboard"
                                        onClick={() => handleClickMore(CONSTANT.ASSESSMENT_TYPE.FOLLOW_UP.key)}>
                                        More
                                    </button>
                                </div>
                            )}
                        </DashboardItem>
                    )
                        :
                        null
                    }

                    <h3 className="color-primary my-3">My Assessments</h3>
                    <MyAssessmentsDetail
                        showCompletedAssessments={false}/>

                </div>
            )}
            <ConfirmationDialog
                headerText={conformationAlertContentRef.current.headerText}
                show={isShowIsFollowUpPopup}
                cancelText={conformationAlertContentRef.current.noBtnText}
                confirmText={conformationAlertContentRef.current.yesBtnText}
                showLoadingInConform={showLoadingInConformTelemedicine}
                onConfirm={conformationAlertContentRef.current.onClickYes}
                toggleDialog={conformationAlertContentRef.current.onClickNo}
                onClickHeaderClose={() => {
                    setIsShowIsFollowUpPopup(false);
                    conformationAlertContentRef.current = {};
                }}
                showYesBtn={conformationAlertContentRef.current?.showYesBtn !== undefined ? conformationAlertContentRef.current?.showYesBtn : true }
            >
                {conformationAlertContentRef.current.content}
            </ConfirmationDialog>

            <SweetAlert
                confirmBtnCssClass="btn btn-sm btn-success"
                showConfirm= {true}
                showCancel={false}
                show={showDetailInstruction}
                title=""
                type="info"
                onConfirm={() => setShowDetailInstruction(false)}
                confirmBtnText="Ok"
                closeOnClickOutside={false}>
                <div className='tele_sweetalert_message'>
                    If there have been any notable changes to your weight, daily activities, or your acute or chronic chief complaints,
                    please update these entries by clicking “Update My Background”
                </div>
            </SweetAlert>

            {openBiometricMovementAndCautionQuestionContainer &&
                <BiometricMovementAndCautionQuestionContainer
                    data={{
                        testId: selectedBodyRegionsAndTestId.current?.testId,
                        selectedBodyRegions: {bodyRegionList : selectedBodyRegionsAndTestId.current?.selectedBodyRegions },
                        openBiometricMovementAndCautionQuestionContainer,
                    }}
                    setOpenBiometricMovementAndCautionQuestionContainer={setOpenBiometricMovementAndCautionQuestionContainer}
                />
            }

            {showIntakeForm && !isEmptyObject(notificationInformation?.current) &&(
                <IntakeFormWrapper
                    showSubmitBtn={true}
                    onClickSubmitIntakeForm={onClickSubmitIntakeForm}
                    ReferType={CONSTANT.REDIRECT_TYPE.TELEMEDICINE}
                />
            )}
        </>
    )
});

export default DashboardBody;
