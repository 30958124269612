const userInfo = null;
const token = "YXBpdXNlcjpZV2xoYVlUUmNHbDFjMlZ5T2lRa1RVWVRFUk1ESXc=";
const vaToken = "dmF1c2VyOllXbGhhWVRSY0dsMVN1TmFOMlZ5T2lRa1RVWVRFUk1ESXc=";
{/*////TODO: WILL_DELETE_BEFORE_MERGE */}
const tenant = "dev";
const tenantId = 0;
const whiteLogoUrl = "https://mmhcdn.s3.us-east-2.amazonaws.com/logos/mmh-logo-white.png";
const assessment = null;
const outcomeAssessment = [];
const savedAnswers = [];
const saveMovements = [];
const chiefComplaintSAnswers = [];
const isAnyChiefComplaintAnswered = false;
const alreadyCalledApi = false;
const movementCancel = false;
const AUDIO_PLAY_ENABLED = false;
const AUDIO_PLAY_ENABLE_FOR_MOVEMENT = true;
const FCM_TOKEN = '';
const TENANT_FEATURES = [];
const SELECTED_CAMERA_DEVICE = {};
const SELECTED_MICROPHONE_DEVICE = {};
const SELECTED_SPEAKER_DEVICE = {};
const SUBSCRIPTION_FEATURES = [];
const GENERAL_AUDIOS = [];
const BIOMETRIC_PROGRESS = 0;
const IS_WELCOME_VOICE_PLAYED = false;
const SELECTED_ASSESSMENT_TYPE = '';
const COUNTRY_INDICATION_CHAT = undefined;
const COUNTRY_INDICATION_CHAT_RESPONSE = undefined;
const source = "PatientPortal";
const geoLocation = {
    latitude: 0,
    longitude: 0
};
const logoURL = '';
// const options = {};
let parentAndChildQuestionIds = {};
let setParentAndChildQuestionIds = (obj) => {
    parentAndChildQuestionIds = obj;
};

const URL_PARAMS = {
    IS_INTAKE_SIGNED: false,
    REDIRECT_TYPE: '',
    REFER_TYPE: '',
    INVITATION_ID: null,
    BODY_REGION_LIST: [],
    EMAIL_ADDRESS: '',
    AUTO_LOGIN: false,
    PATIENT_ID: null,
    IS_FOLLOWUP: false,
    TEST_ID: '',
    IS_REPORT_DOWNLOAD: '',
    ROLE: 2
};

const GLOBAL = {
    USER_INFO: userInfo,
    ASSESSMENT: assessment, ////TODO: HAVE TO MOVE THIS INTO REDUX
    OUTCOME_ASSESSMENT: outcomeAssessment,
    SAVED_ANSWERS: savedAnswers,
    BASE_TOKEN: token,
    VA_BASE_TOKEN: vaToken,
    TENANT: tenant,
    TenantId: tenantId,
    Source: source,
    WHITE_LOGO_URL: whiteLogoUrl,
    ALREADY_CALLED_API: alreadyCalledApi,
    MOVEMENT_CANCEL: movementCancel,
    GEOLOCATION: geoLocation,
    LOGO_URL: logoURL,
    URL_PARAMS: URL_PARAMS,
    AUDIO_PLAY_ENABLED: AUDIO_PLAY_ENABLED,
    AUDIO_PLAY_ENABLE_FOR_MOVEMENT: AUDIO_PLAY_ENABLE_FOR_MOVEMENT,
    SAVE_MOVEMENTS: saveMovements,
    CHIEF_COMPLAINTS_ANSWERS: chiefComplaintSAnswers,
    ISANYCHIEFCOMPLAINTANSWEDED: isAnyChiefComplaintAnswered,////NOTE: Have to remove this after install redux
    FCM_TOKEN: FCM_TOKEN,
    TENANT_FEATURES: TENANT_FEATURES,
    GENERAL_AUDIOS: GENERAL_AUDIOS,
    SUBSCRIPTION_FEATURES: SUBSCRIPTION_FEATURES,
    SELECTED_CAMERA_DEVICE: SELECTED_CAMERA_DEVICE,
    SELECTED_MICROPHONE_DEVICE: SELECTED_MICROPHONE_DEVICE,
    SELECTED_SPEAKER_DEVICE: SELECTED_SPEAKER_DEVICE,
    BIOMETRIC_PROGRESS: BIOMETRIC_PROGRESS,
    IS_WELCOME_VOICE_PLAYED: IS_WELCOME_VOICE_PLAYED,
    SELECTED_ASSESSMENT_TYPE: SELECTED_ASSESSMENT_TYPE,
    COUNTRY_INDICATION_CHAT: COUNTRY_INDICATION_CHAT,
    COUNTRY_INDICATION_CHAT_RESPONSE: COUNTRY_INDICATION_CHAT_RESPONSE,
    DEVICE_WIDTH: 576,
    IsColorRedForFullBox: true,
    IS_PATIENT_IN_BOX: false,
    IS_PATIENT_FACING_LEFT: false,
    IS_PATIENT_FACING_RIGHT: false,
    IS_CAMERA_READY_FOR_BIOMETRIC: false,
    left: 150,
    top: 40,
    right:300,
    bottom: 400,
    IS_LOGOUT_FOR_INACTIVE: false,
    DRAW_BOX: true,
    // OPTIONS: options,
    parentAndChildQuestionIds: parentAndChildQuestionIds,
    setParentAndChildQuestionIds: setParentAndChildQuestionIds
};

export default GLOBAL;

