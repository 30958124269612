import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';
import "reflect-metadata";
import "es6-shim";

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import './index.scss';
import "./global";
import './i18n';
import AppProvider from './contexts/AppProvider';
import App from './components/App';
import { Provider } from "react-redux";
import store from "./store";

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <AppProvider>
        <Provider store={store}>
            <Router>
                <App/>
            </Router>
        </Provider>
    </AppProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
