export default class Assessment {
    constructor(options) {
        options = options || {};

        this.PatientId = options.patientId || '';
        this.TestId = options.TestId || '';
        this.BodyRegionList = options.BodyRegionList || [0];
        this.Tenant = options.Tenant || '';
        this.Results = options.Results || [];
        this.Answers = options.Answers || [];
        this.DemographicsCompleted = options.DemographicsCompleted || false;
        //this.BiometricCompleted = options.BiometricCompleted || false;
        this.ADLCompleted = options.ADLCompleted || false;
        this.MedicalHistoryCompleted = options.MedicalHistoryCompleted || false;
        this.ChiefComplaintsCompleted = options.ChiefComplaintsCompleted || false;
        this.DoServerSideCalculation = options.DoServerSideCalculation || false;
    }
}
