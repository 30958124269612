import { ChatbotButton } from "../ButtonComponent";
import React from "react";
import {
    defaultColor,
    getButtonDisplayStyle,
    getImageWithButton,
    getMarginStyle
} from "../ChatServices";
import SkipButton from "../SkipButton";
import CONSTANT from "../../../../constants/Constant";
import {isSelectedCurrentOption} from "../ChatUtils";
import {useSelector} from "react-redux";
import useCurrentQuestionResponse from "../hooks/useCurrentQuestionResponse";

const CheckBoxQuestionsAnswers = ({ onClickSkip }) => {
    const { onClickCheckbox, onClickCheckboxAnswer } = useCurrentQuestionResponse();
    const chatBotState = useSelector(state => state.chatBot);
    const botData = chatBotState?.botData || {};
    const chats = botData?.chats || [];
    const currentChatIndex = botData?.currentChatIndex ?? -1;

    const chat = chats[currentChatIndex];

    return  (
        <>
            {chat && (
                <>
                    <div className={`responses ${getButtonDisplayStyle(chat)}`}>
                        {chat.responses && chat.responses.length > 0 && (
                            chat.responses.map((chatResponse, indexR) => {
                                return (
                                    <ChatbotButton
                                        tabIndex="-1"
                                        type="button"
                                        id={`chk-${chat.questionId}-chatResponse-${chatResponse.id}`}
                                        key={indexR}
                                        color={defaultColor(chatResponse.color)}
                                        fill={isSelectedCurrentOption(chat, chatResponse).toString()}
                                        onClick={() => onClickCheckbox(chat, chatResponse)}
                                        className={`btn btn-outline-brand btn-elevate btn-pill-5 btn-lg btn-ans px-2 mt-2 ${getImageWithButton(chat, chatResponse)} ${chat.responses.length > 4 ? " min-w-15vw" : ""}`}>
                                        <div className="d-flex">
                                            <span className={`m-auto ${getMarginStyle(chat)}`}>
                                                {isSelectedCurrentOption(chat, chatResponse) ? <i className="fa fa-check"/> : "" } {chatResponse.name}
                                            </span>
                                        </div>
                                    </ChatbotButton>
                                )
                            })
                        )}

                    </div>

                    <button
                        type="button"
                        id={`btn-checkbox-${chat.questionId}`}
                        className="btn btn-check kt-mt-30 bg-color-bright-light-green kt-rounded d-block mx-auto text-white"
                        disabled={chat.answered && chat.intent === CONSTANT.CHATBOT_INTENT.BODY_REGION}
                        onClick={() => onClickCheckboxAnswer(chat)}>
                        OK
                    </button>
                    <SkipButton chat={chat} onClickSkip={onClickSkip} />
                </>
            )}
        </>
    )
};

export default CheckBoxQuestionsAnswers;