import DatePicker from "react-datepicker";
import {deepClone, getDateFormat, years} from "../../../../utils/Utils";
import CONSTANT from "../../../../constants/Constant";
import React from "react";
import SkipButton from "../SkipButton";
import ShowSkipped from "../ShowSkipped";
import ChatRequestDetail from "../../../../models/ChatRequestDetail";
import {format} from "date-fns";
import {useDispatch, useSelector} from "react-redux";
import useChatbot from "../hooks/useChatbot";
import {setChatBotErrorMsg} from "../../../../features/chat/chatbotSlice";
import answerValidationService from "../../../../services/answerValidation/AnswerValidationService";

const DatePickerQuestionsAnswers = ({ onClickSkip }) => {
    const { prepareChatbotAnswer, onChangeUpdateChatsState } = useChatbot();
    const dispatch = useDispatch();
    const chatBotState = useSelector(state => state.chatBot);
    const botData = chatBotState?.botData || {};
    const chats = botData?.chats || [];
    const currentChatIndex = botData?.currentChatIndex ?? -1;

    const chat = chats[currentChatIndex];

    const onChangeDatePicker = (date) => {
        let chatResponse = new ChatRequestDetail({ name: getDateFormat(date) })
        chat.answers = [{
            ...chatResponse
        }];
        onChangeUpdateChatsState(chat);
    };

    const onClickDatePickerAnswer = () => {
        let deepClonedChat = deepClone(chat);
        const questionId = deepClonedChat.questionId || 0;
        const answer = deepClonedChat.answers[0]?.name || '';
        const responseType = deepClonedChat.responseType || '';
        let validation = answerValidationService.validate(questionId, responseType, answer);
        if(!validation?.valid) {
            dispatch(setChatBotErrorMsg(validation?.message || ''));
        }
        prepareChatbotAnswer(deepClonedChat);


        // const { valid, message } = checkClientValidation(chat, chat.answers[0]?.name, chats);
        // if (!valid) {
        //     dispatch(setChatBotErrorMsg(message));
        //     return;
        // } else {
        //     dispatch(setChatBotErrorMsg(''));
        // }
        // prepareChatbotAnswer(chat);
    };


    return (
        <>
            {chat && !chat.answered && (
                <>
                    <div className="responses">
                        <div className="form-group mmh-date-picker mb-0">
                            <DatePicker
                                renderCustomHeader={
                                    ({
                                         date,
                                         changeYear,
                                         changeMonth,
                                         decreaseMonth,
                                         increaseMonth,
                                         prevMonthButtonDisabled,
                                         nextMonthButtonDisabled
                                     }) => (
                                        <div>
                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                {"<"}
                                            </button>
                                            <select
                                                value={date.getFullYear()}
                                                onChange={({ target: { value } }) => changeYear(value)}>
                                                {years.map(option => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>

                                            <select
                                                value={CONSTANT.MONTHS[date.getMonth()]}
                                                onChange={({ target: { value } }) =>
                                                    changeMonth(CONSTANT.MONTHS.indexOf(value))
                                                }>
                                                {CONSTANT.MONTHS.map(option => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>

                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                {">"}
                                            </button>
                                        </div>
                                    )}
                                id="date_picker_id"
                                name={`date_picker_${chat.id}`}
                                closeOnScroll={true}
                                fixedHeight={true}
                                inline
                                dateFormat="MM/dd/yyyy"
                                className="form-control"
                                selected={chat.answers[0]?.name ? new Date(chat.answers[0]?.name) : new Date()}
                                value={chat.answers[0]?.name ? new Date(chat.answers[0]?.name) : new Date()}
                                onChange={(date) => onChangeDatePicker(date)}
                            />
                        </div>
                        <button type="button" className="btn btn-primary btn-lg"
                                onClick={onClickDatePickerAnswer}>
                            OK
                        </button>
                    </div>
                    <SkipButton chat={chat} onClickSkip={onClickSkip} />
                </>
            )}
            {chat && chat.answered && (
                <div className="answers">
                    {chat.answers && chat.answers.length > 0 && (
                        chat.answers.map((chatAnswer, indexR) => {
                            return (
                                <span className="kt-font-brand" key={indexR}>
                                    {format(new Date(), 'dd/MM/yyyy hh:mm a')}
                                </span>
                            )
                        })
                    )}
                    <ShowSkipped chat={chat}/>
                </div>
            )}
        </>
    )
};

export default DatePickerQuestionsAnswers;