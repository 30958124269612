import CONSTANT from "../../constants/Constant";
import { answerValidationRuleForSpecificQuestionId } from "./answerValidationRuleForSpecificQuestionId";
import answerValidationMessagesConstant from "./answerValidationMessagesConstant";

class AnswerValidationService {
    static instance;

    constructor() {
        if (AnswerValidationService.instance) {
            return AnswerValidationService.instance;
        }
        AnswerValidationService.instance = this;

        // A lookup table to map answerValidation types to methods
        this.validationMethods = {
            [answerValidationMessagesConstant.INPUT_BOX.KEY]: this.validateInputBox.bind(this),
            [answerValidationMessagesConstant.CHECKBOX.KEY]: this.validateCheckBox.bind(this),
            [answerValidationMessagesConstant.RADIOBUTTON.KEY]: this.validateRadioButton.bind(this),
            [answerValidationMessagesConstant.DROP_DOWN.KEY]: this.validateDropDown.bind(this),
            [answerValidationMessagesConstant.HEIGHT.KEY]: this.validateHeight.bind(this),
            [answerValidationMessagesConstant.WEIGHT.KEY]: this.validateWeight.bind(this),
            [answerValidationMessagesConstant.DATE_OF_BIRTH.KEY]: this.validateDateOfBirth.bind(this),
            [answerValidationMessagesConstant.FIRST_AND_LAST_NAME.KEY]: this.validateFirstAndLastName.bind(this),
            [answerValidationMessagesConstant.PASSWORD.KEY]: this.validatePassword.bind(this),
            [answerValidationMessagesConstant.CONFIRM_PASSWORD.KEY]: this.validateConfirmPassword.bind(this),
            [answerValidationMessagesConstant.HEIGHT_FEET.KEY]: this.validateHeightInFeet.bind(this),
            [answerValidationMessagesConstant.HEIGHT_INCH.KEY]: this.validateHeightInInch.bind(this),
            [answerValidationMessagesConstant.GENDER_AS_SELECT.KEY]: this.validateGenderAsSelect.bind(this),
            [answerValidationMessagesConstant.DOMINANT_HAND_AS_SELECT.KEY]: this.validateDominantHandAsSelect.bind(this),
            [answerValidationMessagesConstant.TERMS_AND_CONDITIONS.KEY]: this.validateTermsAndConditions.bind(this),
        };
    }

    validate(QuestionId, responseType, value) {
        const validationType = this.determineValidationType(QuestionId, responseType);

        const validateMethod = this.validationMethods[validationType];
        if (validateMethod) {
            let result = validateMethod(value);
            return result;
        }

        // If no answerValidation method is found, return valid
        return { valid: true, message: '' };
    }

    validateInputBox(value) {
        if (value === '') {
            return { valid: false, message: answerValidationMessagesConstant.INPUT_BOX.REQUIRED };
        }

        return { valid: true, message: '' };
    }

    validateCheckBox(values) {
        if (!Array.isArray(values) || values.length === 0) {
            return { valid: false, message: answerValidationMessagesConstant.CHECKBOX.REQUIRED };
        }

        //for checkbox question we only send answerId in AnswerList property in edit-assessment that's why just checking
        //is the array empty or not

        return { valid: true, message:  '' };
    }

    // value is the id of the selected radio button
    validateRadioButton(answerId) {
        // Check if value is defined and not null; allow `0` and other falsy values except null/undefined
        // { id: 0 } we use as answer for label response type question in chatbot

        if (answerId === undefined || answerId === null || answerId === '') {
            return { valid: false, message: answerValidationMessagesConstant.RADIOBUTTON.REQUIRED };
        }

        return { valid: true, message: '' };
    }

    validateDropDown(answerId) {
        if (!answerId) {
            return { valid: false, message: answerValidationMessagesConstant.DROP_DOWN.REQUIRED };
        }

        return { valid: true, message: '' };
    }

    validateFirstAndLastName(value) {
        return answerValidationRuleForSpecificQuestionId[CONSTANT.CHATBOT_ACCOUNT.FIRST_NAME](value);
    }

    validatePassword(value) {
        return answerValidationRuleForSpecificQuestionId[CONSTANT.CHATBOT_ACCOUNT.PASSWORD](value);
    }

    validateConfirmPassword(value) {
        return answerValidationRuleForSpecificQuestionId[CONSTANT.CHATBOT_ACCOUNT.CONFIRM_PASSWORD](value);
    }

    validateHeightInFeet(value) {
        return answerValidationRuleForSpecificQuestionId[CONSTANT.CHATBOT_ACCOUNT.HEIGHT_IN_FEET](value);
    }

    validateHeightInInch(value) {
        return answerValidationRuleForSpecificQuestionId[CONSTANT.CHATBOT_ACCOUNT.HEIGHT_IN_INCH](value);
    }

    validateHeight(value) {
        return answerValidationRuleForSpecificQuestionId[CONSTANT.CHATBOT_ACCOUNT.HEIGHT](value);
    }

    validateWeight(value) {
        return answerValidationRuleForSpecificQuestionId[CONSTANT.CHATBOT_ACCOUNT.WEIGHT](value);
    }

    validateDateOfBirth(value) {
        return answerValidationRuleForSpecificQuestionId[CONSTANT.CHATBOT_ACCOUNT.DATE_OF_BIRTH](value);
    }

    validateGenderAsSelect(value) {
        return answerValidationRuleForSpecificQuestionId[CONSTANT.CHATBOT_ACCOUNT.GENDER_AS_SELECT](value);
    }

    validateDominantHandAsSelect(value) {
        return answerValidationRuleForSpecificQuestionId[CONSTANT.CHATBOT_ACCOUNT.DOMINANT_HAND_AS_SELECT](value);
    }

    validateTermsAndConditions(value) {
        return answerValidationRuleForSpecificQuestionId[CONSTANT.CHATBOT_ACCOUNT.TERMS_AND_CONDITIONS](value);
    }

    determineValidationType(questionId, responseType) {
        switch (questionId) {
            case CONSTANT.CHATBOT_ACCOUNT.HEIGHT:
                return answerValidationMessagesConstant.HEIGHT.KEY;
            case CONSTANT.CHATBOT_ACCOUNT.WEIGHT:
                return answerValidationMessagesConstant.WEIGHT.KEY;
            case CONSTANT.CHATBOT_ACCOUNT.DATE_OF_BIRTH:
                return answerValidationMessagesConstant.DATE_OF_BIRTH.KEY;
            case CONSTANT.CHATBOT_ACCOUNT.FIRST_NAME:
            case CONSTANT.CHATBOT_ACCOUNT.LAST_NAME:
                return answerValidationMessagesConstant.FIRST_AND_LAST_NAME.KEY;
            case CONSTANT.CHATBOT_ACCOUNT.PASSWORD:
                return answerValidationMessagesConstant.PASSWORD.KEY;
            case CONSTANT.CHATBOT_ACCOUNT.CONFIRM_PASSWORD:
                return answerValidationMessagesConstant.CONFIRM_PASSWORD.KEY;
            case CONSTANT.CHATBOT_ACCOUNT.HEIGHT_IN_FEET:
                return answerValidationMessagesConstant.HEIGHT_FEET.KEY;
            case CONSTANT.CHATBOT_ACCOUNT.HEIGHT_IN_INCH:
                return answerValidationMessagesConstant.HEIGHT_INCH.KEY;
            case CONSTANT.CHATBOT_ACCOUNT.GENDER_AS_SELECT:
                return answerValidationMessagesConstant.GENDER_AS_SELECT.KEY;
            case CONSTANT.CHATBOT_ACCOUNT.DOMINANT_HAND_AS_SELECT:
                return answerValidationMessagesConstant.DOMINANT_HAND_AS_SELECT.KEY;
            case CONSTANT.CHATBOT_ACCOUNT.TERMS_AND_CONDITIONS:
                return answerValidationMessagesConstant.TERMS_AND_CONDITIONS.KEY;
        }

        switch (responseType) {
            case CONSTANT.ELEMENT_TYPES.TEXT:
            case CONSTANT.ELEMENT_TYPES.NUMBER:
            case CONSTANT.ELEMENT_TYPES.CALCULATION:
            case CONSTANT.ELEMENT_TYPES.DATE_PICKER:
                return answerValidationMessagesConstant.INPUT_BOX.KEY;

            case CONSTANT.ELEMENT_TYPES.BUTTON:
            case CONSTANT.ELEMENT_TYPES.LABEL: //in chatbot, we push { id: 0 } as answer for label response type question
                return answerValidationMessagesConstant.RADIOBUTTON.KEY;

            case CONSTANT.ELEMENT_TYPES.DROPDOWN:
                return answerValidationMessagesConstant.DROP_DOWN.KEY;

            case CONSTANT.ELEMENT_TYPES.CHECKBOX:
                return answerValidationMessagesConstant.CHECKBOX.KEY;

            default:
                return answerValidationMessagesConstant.GENERIC.KEY;
        }
    }
}

export default new AnswerValidationService();