import React, { Fragment, useContext, useEffect, useRef, useState, useMemo } from "react";
import { Modal } from "reactstrap";
import scrollIntoView from "scroll-into-view-if-needed";
import smoothScrollIntoView from "smooth-scroll-into-view-if-needed";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import { isValid } from "date-fns";
import { isMobile } from "react-device-detect"

import GLOBAL from "../../global";
import CONSTANT from "../../constants/Constant";
import routes from "../../constants/Routes";

import {
    getBodyRegionNames,
    formatDate,
    getPatientPortalType, getMarkup,
} from "../../utils/Utils";

import examService from "../../services/ExamService";
import Chat from "../../models/Chat";
import InputBox from "../shared/form-element/InputBox";
import Checkbox from "../shared/form-element/Checkbox";
import RadioButton from "../shared/form-element/RadioButton";
import { bodyPart } from "../../utils/BodyPart";
import MaskInput from "../shared/form-element/MaskInput";
import PainRate from "../shared/PainRate";
import AppContext from "../../contexts/AppContext";
import CloseButton from "../../assets/img/utils/cross-btn.svg"
import ShowAllQuestionsHeader from "./ShowAllQuestionsHeader";
import {getMeasuredExercises} from "../shared/MovementModal/Services/GetMeasurementRequestHandler";
import {getAllPainQuestions, mapRenderedPictureInPainQuestionsObject} from "./ShowAllQuestionsService";
import RadioBtnComponent from "../shared/MovementModal/PainQuestionForm/RadioBtnComponent";
import {getQueuedMovements} from "../shared/MovementModal/Services/BotService";

const ShowAllQuestionsDetails = (props) => {
    const search = useLocation().search;
    const tabId=new URLSearchParams(search).get("tab");
    const { userInfo} = useContext(AppContext);
    const { OutcomeAssessmentEnabled } = userInfo.Permissions;
    const { testId, bodyRegionList } = props;
    const [selectedTab, setSelectedTab] = useState(GLOBAL.SELECTED_ASSESSMENT_TYPE);
    const subscription = useRef(true);
    const [currentGroupId, setCurrentGroupId] = useState(CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key);
    const [currentGroupViewOrderId, setCurrentGroupViewOrderId] = useState(CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.viewOrder);
    const [groupQuestions, setGroupQuestions] = useState([]);
    const [subGroupQuestions, setSubGroupQuestions] = useState([]);
    const [allAnswers, setAllAnswers] = useState([]);
    const [, setForceUpdate] = useState(0);
    const [groups, setGroups] = useState([]);
    const [chat, setChat] = useState(new Chat());
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [formErrors, setFormErrors] = useState([]);
    const [isBodyPartSelected, setIsBodyPartSelected] = useState(false);
    const [subBodyRegions, setSubBodyRegions] = useState([]);
    const [loadingQuestions, setLoadingQuestions] = useState(false);
    const [subGroups, setSubGroups] = useState([]);
    const [subGroupId, setSubGroupId] = useState(0);
    const [nextQuestionOrderId, setNextQuestionOrderId] = useState(0);
    const [previousResponse, setPreviousResponse] = useState({});
    const [pageCaptions, setPageCaptions] = useState([]);
    const [pageCaptionId, setPageCaptionId] = useState(0);
    const [outcomeAssessmentBodyRegionKey, setOutcomeAssessmentBodyRegionKey] = useState(props.bodyRegionList);
    const [allQuestions, setAllQuestions] = useState([]);
    const [pageHeaderInfo, setPageHeaderInfo] = useState([]);
    const [allPainQuesWithExerciseNameAndRenderedPic, setAllPainQuesWithExerciseNameAndRenderedPic] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [groupTitle, setGroupTitle] = useState('');
    const [redirectToMyAssessments, setRedirectToMyAssessments] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [bodyRegionLists, setBodyRegionLists] = useState('');
    const previousRegion = useRef('');
    const [outcomeAssessmentQuestionsId, setOutcomeAssessmentQuestionsId] = useState([]);
    const [tabCount, setTabCount] = useState(0);
    const [viewImage , setViewImage] = useState("")
    const [isCompleted, setIsCompleted] = useState([]);
    const [assessmentNotFound, setAssessmentNotFound] = useState(false);
    const [showInstruction, setShowInstruction] = useState(false)

    const _divideQuestionOfMedicalHistory = (questions, subTab) => {
        const unknown = [];
        const subGroups = _getSubGroups(questions, CONSTANT.ASSESSMENT_GROUP.MEDICAL_HISTORY.key);
        let arrays = {};
        subGroups.forEach(subGroup => {
            arrays[subGroup] = [];
        });

        questions.forEach((question) => {
            if (subGroups.length >= 1 && (question.header1 === subGroups[0] || question.header2 === subGroups[0])) {
                arrays[subGroups[0]].push(question);
            } else if (subGroups.length >= 2 && (question.header1 === subGroups[1] || question.header2 === subGroups[1])) {
                arrays[subGroups[1]].push(question);
            } else if (subGroups.length >= 3 && (question.header1 === subGroups[2] || question.header2 === subGroups[2])) {
                arrays[subGroups[2]].push(question);
            } else if (subGroups.length >= 4 && (question.header1 === subGroups[3] || question.header2 === subGroups[3])) {
                arrays[subGroups[3]].push(question);
            } else if (subGroups.length >= 5 && (question.header1 === subGroups[4] || question.header2 === subGroups[4])) {
                arrays[subGroups[4]].push(question);
            } else {
                unknown.push(question);
            }
        });

        let array = [];
        subGroups.forEach(subGroup => {
            array.push(arrays[subGroup])
        });
        array.push(unknown)
        // setMedicalHistorySection(array);
        const subTabMaping = {
            Personal_Health_History: 0,
            Family_History: 1,
            Medications: 2,
            Surgery: 3,
            Exercise: 4
        }

        if (subTab === subTabMaping["Personal_Health_History"]) return subGroups.length > 0 ? arrays[subGroups[0]] : [];
        else if (subTab === subTabMaping["Family_History"]) return subGroups.length > 1 ? arrays[subGroups[1]] : [];
        else if (subTab === subTabMaping["Medications"]) return subGroups.length > 2 ? arrays[subGroups[2]] : [];
        else if (subTab === subTabMaping["Surgery"]) return subGroups.length > 3 ? arrays[subGroups[3]] : [];
        else if (subTab === subTabMaping["Exercise"]) return subGroups.length > 4 ? arrays[subGroups[4]] : [];
        else return unknown;
    };

    let redirectUrl = getPatientPortalType(GLOBAL.TENANT_FEATURES) === CONSTANT.PATIENT_PORTAL_TYPE.Consumer.name ?
        `${routes.myAssessments}/${false}` : routes.myMedicalHistory;

    useEffect(() => {
        const groups = _getGroups();
        const regionName = getBodyRegionNames(bodyRegionList);
        const group = groups.find((item) => item.key === CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key);

        if (group) {
            group.name = regionName;
            if(regionName === CONSTANT.BODY_REGIONS.POSTURE.name) {
                const index = groups.findIndex(group => group.name === CONSTANT.BODY_REGIONS.POSTURE.name);
                index > -1 && groups.splice(index, 1);
            }
        }

        if (regionName && regionName.length > 0) {
            previousRegion.current = regionName.split(',')[0];
        }

        setBodyRegionLists(regionName);
        if (!OutcomeAssessmentEnabled) {
            const index = groups.findIndex(group => group.key === CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key);
            index > -1 && groups.splice(index, 1);
        }

        setGroups(groups);
        const updatedChat = { ...chat, mode: false, bodyParts: bodyPart }
        setChat(updatedChat);

        let questionIds = [];
        const propertyNames = Object.keys(CONSTANT.OUTCOME_SCORE_QUESTIONS);
        propertyNames.forEach(name => {
            const singleRegionQuestionIds = Object.values(CONSTANT.OUTCOME_SCORE_QUESTIONS[name]);
            questionIds = [...questionIds, ...singleRegionQuestionIds];
        })

        setOutcomeAssessmentQuestionsId(questionIds);

        if (subscription.current && testId) {
            const fetchQuestionResponsesPageHeaderInfo = async () => {
                let {
                    questions,
                    savedResponses,
                    pageHeaderInfo,
                    isCompleted
                } = await _getQuestionsPreviousResponsesPageHeaderInfo();
                if(bodyRegionList.includes(CONSTANT.BODY_REGIONS.POSTURE.key)) {
                    // questions = questions.filter(question => question.typeName !== CONSTANT.ASSESSMENT_GROUP.MEDICAL_HISTORY.name);
                    // questions = [...questions, ...postureQuestions];

                    questions = questions.filter(question => question.bodyRegionList.includes(5));
                    questions = [...questions ];
                }

                setIsCompleted(isCompleted)
                let subBodyRegions = _getSubBodyRegions(savedResponses);
                const portalDisplayQuestions = _getPortalDisplayQuestions(questions);
                _setIsScoreQuestionFieldInQuestions(portalDisplayQuestions);
                const answers = _getSavedResponse(savedResponses, portalDisplayQuestions);
                getScoreAnswers(answers);

                setAllQuestions(portalDisplayQuestions);
                setPageHeaderInfo(pageHeaderInfo);
                setAllAnswers(answers);
                setSubBodyRegions(subBodyRegions);

                !selectedTab && await _fetchAndPrepareChiefComplaintOrGroupQuestionsForView({
                    questions: portalDisplayQuestions,
                    groupId: tabId !== null ? parseInt(tabId) : currentGroupId,
                    subGroupId: subGroupId
                });
            }

            fetchQuestionResponsesPageHeaderInfo();
        }

        return () => {
            subscription.current = false;
            setAllQuestions([]);
            setAllAnswers([]);
            GLOBAL.ASSESSMENT = null;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps,
    }, []);


    useEffect(() => {
        setTimeout(() => setForceUpdate(Date.now()), 2000);
    }, [subGroupQuestions, chat])

    useEffect(() => {
        const pageHeader = pageHeaderInfo.find((item) => item.GroupId === currentGroupId);
        let groupTitle = currentGroupId !== CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key ? (pageHeader ? pageHeader['Tittle'] : '') : '';
        setGroupTitle(groupTitle);

    }, [currentGroupId, pageHeaderInfo])

    ////TODO: Have to refactor this code
    useEffect(() => {
            let totalIsDefaultHideQuestions = 0;
            let tempAllQuestions = allQuestions;
            //at posture => medical history section we are only showing Personal Health History's question although we are getting all medical history questions from api
            if(props?.bodyRegionList.includes(CONSTANT.BODY_REGIONS.POSTURE.key)) {
                tempAllQuestions = tempAllQuestions.filter(question => (question.typeName !== CONSTANT.ASSESSMENT_GROUP.MEDICAL_HISTORY.name) || (question.typeName === CONSTANT.ASSESSMENT_GROUP.MEDICAL_HISTORY.name && question?.header1 === "Personal Health History"));
            }
            setSubGroupQuestions(tempAllQuestions);
            allQuestions.forEach(question => {

                if(question?.isDefaultHide) {
                    totalIsDefaultHideQuestions++;
                }
            })

    }, [allQuestions, allAnswers]);

    const _getGroups = () => {
        let groups = [];
        for (const [, value] of Object.entries(CONSTANT.ASSESSMENT_GROUP)) {
            if (value.key !== CONSTANT.ASSESSMENT_GROUP.BIOMETRIC.key) {
                groups.push(value);
            }
        }

        if (bodyRegionList.length === 1 && bodyRegionList.includes(CONSTANT.BODY_REGIONS.POSTURE.key)) {
            for (let i = groups.length - 1; i >= 0; i--) {
                if (groups[i].key === CONSTANT.ASSESSMENT_GROUP.ADL.key) {
                    groups.splice(i, 1);
                }
            }
        }

        return groups;
    }

    const _getPortalDisplayQuestions = (questions) => {
        return questions.filter((question) => question?.['isDisplayOnPortal']);
    }

    const _setIsScoreQuestionFieldInQuestions = (questions) => {
        const scoreQuestionsId = _getScoreQuestionsId();
        questions.forEach((question) => {
            question.isScoreQuestion = scoreQuestionsId.includes(question.questionId);
        })
    }

    const _getScoreQuestionsId = () => {
        let bodyRegions = [];
        for (const [, value] of Object.entries(CONSTANT.BODY_REGIONS)) {
            bodyRegions.push(value);
        }

        let scoreQuestions = [];
        bodyRegions.forEach((region) => {
            if (CONSTANT.OUTCOME_SCORE_QUESTIONS?.[region.name]) {
                for (const [, id] of Object.entries(CONSTANT.OUTCOME_SCORE_QUESTIONS?.[region.name])) {
                    scoreQuestions.push(id);
                }
            }
        });

        return scoreQuestions;
    }

    const _getSavedResponse = (savedResponses, questions) => {
        let answers = [];
        const scoreQuestionsId = _getScoreQuestionsId();
        if (savedResponses && savedResponses?.length > 0) {
            savedResponses.forEach((value) => {
                let question = questions.find((question) => question.questionId === value.QuestionId);
                if (!question) {
                    question = {
                        questionId: value.QuestionId,
                        bodyRegionList: value.BodyRegionList,
                        isScoreQuestion: false
                    }
                }
                const isScoreQuestion = scoreQuestionsId.includes(question?.questionId);
                const answer = _prepareAnswer(question, value.AnswerId, value.AnswerValue, value['ViewOrder'], value.GroupId, isScoreQuestion);

                if (value.QuestionId !== CONSTANT.ASSESSMENT_QUESTIONS.OUTCOME_BODY_REGION_SELECTION) {
                    answers.push(answer);
                }

                if (question?.['hasConditionalQuestion']) {
                    _enableAndGetConditionalQuestionsIfExists(questions, savedResponses, question, value.AnswerId);
                }
            });
        }

        return answers;
    }

    const fetchData = async (testId, groupList, bodyPart) => {
        setLoadingQuestions(true);
        const payload = {
            TestId: testId,
            GroupIdList: groupList,
            BodyLocation: bodyPart,
        };

        const response = await examService.getPreviousResponsesByTestId(payload);
        const { data, success, error } = response;
        setLoadingQuestions(false);

        if (!success) {
            console.error(error);
            if(error === 'Exam is not found.') {
                setAssessmentNotFound(true);
            }
            return;
        }
        return data || '{}'
    }

    const _fetchAndPrepareChiefComplaintOrGroupQuestionsForView = async (
        {
            questions,
            groupId,
            subGroupId,
            bodyPart
        }) => {
        let groupQuestions;
        groupQuestions = _filterQuestionByGroup(questions, groupId);
        await _prepareQuestionsForView(groupQuestions || [], groupId, subGroupId);
    };

    const _filterQuestionByGroup = (questions, groupId) => {
        return questions.filter((question) => question['typeId'] === groupId);
    }

    const _prepareQuestionsForView = (questions, groupId, subGroupId) => {
        if (questions?.length > 0) {
            setGroupQuestions(questions);
            const subGroups = _getSubGroups(questions, groupId);
            if (groupId === CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key && questions[0].questionId === CONSTANT.ASSESSMENT_QUESTIONS.OUTCOME_BODY_REGION_SELECTION) {
                subGroups.push("");
            }
            const pageCaptionsOfSubGroup = _getPageCaptionsOfSubGroup(questions, subGroups[subGroupId], groupId);

            setSubGroups(subGroups);
            setPageCaptions(pageCaptionsOfSubGroup);

            if (groupId !== CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key) {
                _filterQuestionsBySubGroupPageCaptionCondition(subGroups[subGroupId], pageCaptionsOfSubGroup[0], questions);
            } else {
                let index = 0;
                questions.forEach((item) => {
                    item.orderId = index++;
                });
                setSubGroupQuestions(questions);
                let maxOrderId = Number.MAX_VALUE;
                let questionToFocus = null;

                if (questions && questions.length > 0) {
                    questions.forEach(question => {
                        if (question.orderId < maxOrderId && !question.isDefaultHide) {
                            maxOrderId = question.orderId;
                            questionToFocus = question;
                        }
                    });
                }

                if (questionToFocus) {
                    onExecuteScroll(questionToFocus.questionId, true);
                }
            }

        } else {
            setSubGroups([]);
            setSubGroupQuestions([]);
        }
    }

    const _getSubGroups = (questions, groupId) => {
        let header = 'header1';
        if (groupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key) {
            header = 'header2';
        }

        return questions.map((question) => question[header]).filter((value, index, self) => self.indexOf(value) === index);
    }

    const _getPageCaptionsOfSubGroup = (questions, subGroup, groupId) => {
        let header = 'header1';
        if (groupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key) {
            header = 'header2';
        }

        const subGroupQuestions = questions.filter((item) => item[header] === subGroup);
        return subGroupQuestions.map((question) => question['pageCaption']).filter((value, index, self) => self.indexOf(value) === index);
    }

    const _getQuestionsPreviousResponsesPageHeaderInfo = async () => {
        setLoadingData(true);
        let groupList = [];
        if(bodyRegionList.includes(CONSTANT.BODY_REGIONS.POSTURE.key)) {
            groupList = [CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key
                , CONSTANT.ASSESSMENT_GROUP.MEDICAL_HISTORY.key];
        } else {
            groupList = [CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key
                , CONSTANT.ASSESSMENT_GROUP.ADL.key
                , CONSTANT.ASSESSMENT_GROUP.MEDICAL_HISTORY.key
                , CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key];
        }

        const responseData = await fetchData(testId, groupList, "");

        let measuredExercise = await getMeasuredExercises(testId, bodyRegionList);
        let tempAllPainQuestionsWithRenderedPic = getAllPainQuestions(measuredExercise);
        let queueStatusOfExercises = await getQueuedMovements(testId);
        tempAllPainQuestionsWithRenderedPic = mapRenderedPictureInPainQuestionsObject(tempAllPainQuestionsWithRenderedPic, queueStatusOfExercises);


        setAllPainQuesWithExerciseNameAndRenderedPic(tempAllPainQuestionsWithRenderedPic);

        setLoadingData(false);

        setPreviousResponse(responseData || {});
        const allQuestions = responseData?.['Questions'] || [];
        const allResponses = responseData?.['ExamResponse'] || [];
        const pageHeaderInfo = responseData?.['PageHeaderInformation'] || [];
        const chiefComplaintVas = responseData?.['ChiefComplaintVas'] || [];
        const isCompleted = [false, responseData?.["DemographicsCompleted"] || false, responseData?.["MedicalHistoryCompleted"] || false, responseData?.["ADLCompleted"] || false,
            responseData?.["ChiefComplaintsCompleted"] || false, false, false, false, responseData?.["OswestryCompleted"] || false]

        return {
            questions: allQuestions,
            savedResponses: allResponses,
            pageHeaderInfo: pageHeaderInfo,
            chiefComplaintVas: chiefComplaintVas,
            isCompleted: isCompleted
        };
    };

    const _getSubBodyRegions = (allAnswer) => {
        return allAnswer.map((answer) => answer['SelectedBodyLocation']).filter((value, index, self) => self.indexOf(value) === index);
    }

    const _filterQuestionsBySubGroupPageCaptionCondition = (subGroupTitle, pageCaption, questions) => {
        let tempAllQuestions = questions; // .filter((question) => question.header1 === subGroupTitle && question['pageCaption'] === pageCaption);
        let index = 0;
        tempAllQuestions.forEach((item) => {
            if (!item['isDefaultHide']) {
                item.orderId = index++;
            }
        });

        if (tempAllQuestions.length > 0 && tempAllQuestions[0].typeName === "Medical History") {
            tempAllQuestions = _divideQuestionOfMedicalHistory(tempAllQuestions, tabCount);
        }

        if(tempAllQuestions.length > 0)
            setNextQuestionOrderId(tempAllQuestions[0].questionId);
        setSubGroupQuestions(allQuestions);
    }

    const onChangeCollectResponse = ({ question, answerId, answerText, answerIndex, title, description, checked, willSave = true }) => {
        return;
    };

    const _enableAndGetConditionalQuestionsIfExists = (questions, allAnswers, question, answerId) => {
        question.responses.forEach((response) => {
            const filteredQuestions = questions.filter((question) => response?.['displayQuestionList']?.includes(question.questionId));
            filteredQuestions.forEach((filteredQuestion) => {
                if (response.id !== answerId) {
                    filteredQuestion.isDefaultHide = true;
                    const index = allAnswers.findIndex((item) => item?.QuestionId === filteredQuestion.questionId);
                    index > -1 && allAnswers.splice(index, 1);
                } else {
                    filteredQuestion.isDefaultHide = false;
                }
            });
        })
    }

    const getScoreAnswers = (allAnswer) => {
        const newScoreAnswers = [];
        const scoresAnswers = _calculateAndGetScoresAnswersOfOutcomeAssessmentQuestions(allAnswer);

        if (scoresAnswers?.length > 0) {
            scoresAnswers.forEach((scoreAnswer) => {
                const answer = allAnswers.find((answer) => answer.QuestionId === scoreAnswer.QuestionId);
                if (answer) {
                    const question = {
                        questionId: scoreAnswer.QuestionId,
                        bodyRegionList: outcomeAssessmentBodyRegionKey
                    }

                    _updateAnswer(question, scoreAnswer.AnswerId, scoreAnswer.AnswerTitle);
                } else {
                    newScoreAnswers.push(scoreAnswer);
                }
            })
        }

        return newScoreAnswers;
    }

    const _prepareAnswer = (question, answerId, answerText, answerIndex, groupId, isScoreQuestion) => {
        return {
            QuestionId: question.questionId,
            AnswerId: (answerId && answerId) || '',
            AnswerValue: (answerText && answerText) || '',
            AnswerTitle: (answerText && answerText) || '',
            GroupId: groupId,
            Index: answerIndex,
            BodyRegionList: question.bodyRegionList,
            BodyRegion: question.bodyRegion && question.bodyRegion.length > 0 ? question.bodyRegion[0] : '',
            IsScoreAnswer: question?.isScoreQuestion || isScoreQuestion || false,
        };
    }

    const _calculateAndGetScoresAnswersOfOutcomeAssessmentQuestions = (allAnswer) => {
        const selectedBodyRegionAnswers = _getSelectedOutcomeAssessmentAnswers(allAnswer);
        const answerListExceptScoreAnswers = selectedBodyRegionAnswers.filter((item) => !item.IsScoreAnswer)
        const scores = _getScores(answerListExceptScoreAnswers);
        let distinctGroupTotalAnswers;
        distinctGroupTotalAnswers = _getDistinctGroupTotalScore(answerListExceptScoreAnswers);

        return getScoresAnswers(scores, distinctGroupTotalAnswers, answerListExceptScoreAnswers)
    }

    const _getBodyRegion = (questionId) => {
        let ques;
        subGroupQuestions.forEach(question => {
            if (question.questionId === questionId) {
                ques = question
            }
        })

        return ques && ques.bodyRegion && ques.bodyRegion.length > 0 ? ques.bodyRegion : ''
    }

    const getScoresAnswers = (scores, distinctGroupTotalAnswers = {}, answerListExceptScoreAnswers) => {
        let answers = [];
        let bodyRegions = [];
        for (const [, value] of Object.entries(CONSTANT.BODY_REGIONS)) {
            bodyRegions.push(value);
        }

        let distinctGroupTotalScore = []
        for (const [key, value] of Object.entries(distinctGroupTotalAnswers)) {
            distinctGroupTotalScore.push({ key, value });
        }

        bodyRegions.forEach((region) => {
            if (outcomeAssessmentBodyRegionKey.includes(region.key)) {
                const singleRegionScore = _getScores(answerListExceptScoreAnswers, region.key);
                let outcomeScores = []
                for (const [key, value] of Object.entries(singleRegionScore)) {
                    outcomeScores.push({ key, value });
                }

                let scoreQuestions = [];
                if (CONSTANT.OUTCOME_SCORE_QUESTIONS?.[region.name]) {
                    for (const [key, value] of Object.entries(CONSTANT.OUTCOME_SCORE_QUESTIONS?.[region.name])) {
                        scoreQuestions.push({ key, value });
                    }
                }

                scoreQuestions.forEach((scoreQuestion) => {
                    distinctGroupTotalScore.forEach((score) => {
                        if (score.key === scoreQuestion.key && scoreQuestion.value !== CONSTANT.OUTCOME_SCORE_QUESTIONS.Arms.Score) {
                            const question = {
                                questionId: scoreQuestion.value,
                                bodyRegionList: [outcomeAssessmentBodyRegionKey],
                                isScoreQuestion: true,
                                bodyRegion: _getBodyRegion(scoreQuestion.value)
                            }
                            const answer = _prepareAnswer(question, score.value, score.value, 0, currentGroupId);
                            answers.push(answer);
                        }
                    })
                })

                scoreQuestions.forEach((scoreQuestion) => {
                    outcomeScores.forEach((score) => {
                        if (score.key === scoreQuestion.key) {
                            const question = {
                                questionId: scoreQuestion.value,
                                bodyRegionList: [outcomeAssessmentBodyRegionKey],
                                isScoreQuestion: true,
                                bodyRegion: _getBodyRegion(scoreQuestion.value)
                            }
                            const answer = _prepareAnswer(question, score.value, score.value, 0, currentGroupId);
                            answers.push(answer);
                        }
                    })
                })
            }
        })

        return answers;
    }

    const _getDistinctGroupTotalScore = (answers) => {
        let distinctGroupTotal = {};
        CONSTANT.ARMS_ASSESSMENT_RESPONSE_TYPES.forEach((item) => {
            const totalAnswers = answers.filter((answer) => answer.AnswerValue === item);
            distinctGroupTotal = { ...distinctGroupTotal, [item]: totalAnswers.length };
        })

        return distinctGroupTotal;
    }

    const _getScores = (answers, regionKey) => {
        return _prepareScore(answers, regionKey);
    }

    const _prepareScore = (answers, regionKey) => {
        let OSWScore = 0;
        let scorePoint = 1;

        let singleRegionAnswer = [];
        answers.forEach((answer) => {
            if (answer.BodyRegion === regionKey && answer.QuestionId !== 10000687) {
                singleRegionAnswer.push(answer)
            }
        })

        const earnedScore = _getEarnedScoreOfSelectedBodyRegion(singleRegionAnswer);

        if (earnedScore > -1) {
            scorePoint = singleRegionAnswer.length * 5;
            OSWScore = Math.round((earnedScore / 50) * 100) + '%';
        }

        return { OSWScore: OSWScore, PScore: scorePoint, Score: scorePoint };
    };
    const NECK_UNCALCULATING_QUESTION_ID = 10000687;
    const _getEarnedScoreOfSelectedBodyRegion = (answers) => {
        let earnedScore = 0;
        answers.forEach((answer) => {
            if (answer.Index && answer.questionId !== NECK_UNCALCULATING_QUESTION_ID) {
                earnedScore += answer.Index;
            }
        })

        return earnedScore;
    }

    const _getSelectedOutcomeAssessmentAnswers = (answers) => {
        const filteredAnswers = []
        answers.forEach((answer) => {
            if (answer?.BodyRegionList?.length > 0 && answer.GroupId === CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key && answer.QuestionId !== CONSTANT.ASSESSMENT_QUESTIONS.OUTCOME_BODY_REGION_SELECTION) {
                answer.BodyRegionList.forEach((region) => {
                    if (outcomeAssessmentBodyRegionKey.includes(region.key) > -1) {
                        filteredAnswers.push(answer);
                    }
                })
            }
        });

        return filteredAnswers;
    }

    const _updateAnswer = (question, answerId, answerText, answerIndex, checked) => {
        if (allAnswers.length > 0) {
            let index = -1;

            if (question.responseType === CONSTANT.ELEMENT_TYPES.CHECKBOX) {
                index = allAnswers.findIndex((answer) => answer.QuestionId === question.questionId && answer.AnswerId === answerId);
            } else {
                index = allAnswers.findIndex((answer) => answer.QuestionId === question.questionId);
            }

            if (index > -1) {
                if (!answerId && !answerText) {
                    allAnswers.splice(index, 1);

                } else if (!checked && question.responseType === CONSTANT.ELEMENT_TYPES.CHECKBOX) {
                    allAnswers.splice(index, 1);

                } else {
                    allAnswers[index].AnswerId = (answerId && answerId) || '';
                    allAnswers[index].AnswerValue = (answerText && answerText) || '';
                    allAnswers[index].AnswerTitle = (answerText && answerText) || '';
                    allAnswers[index].BodyRegionList = question.bodyRegionList;
                    allAnswers[index].Index = answerIndex;
                }
            }
        }
    };

    const getValue = (responseType, questionId) => {
        let answer;
        let value = '';
        answer = allAnswers.find((answer) => answer.QuestionId === questionId);

        if (answer) {
            if (responseType === CONSTANT.ELEMENT_TYPES.TEXT || responseType === CONSTANT.ELEMENT_TYPES.CALCULATION
                || responseType === CONSTANT.ELEMENT_TYPES.NUMBER || responseType === CONSTANT.ELEMENT_TYPES.DATE_PICKER) {
                value = (answer.AnswerValue && answer.AnswerValue) || '';
            }
        }

        if (questionId === CONSTANT.CHATBOT_ACCOUNT.DATE_OF_BIRTH && isValid(new Date(value))) {
            value = formatDate(new Date(value), CONSTANT.DATE_FORMAT.MM_DD_YYYY);
        }

        return value;
    };

    const getCheckedOption = (questionId, responses) => {
        const answer = allAnswers.filter((answer) => (answer.QuestionId === questionId));
        const answerOption = allAnswers.filter((answer) =>
            responses && responses.length > 0 && responses.find((response) => response.id === answer.AnswerId));

        if (answer.length > 0 && answerOption.length > 0) {
            return answer[0].AnswerId;
        } else {
            return ''
        }
    };

    const _preparePayloadOptions = (option) => {
        let options = {}
        if(option.length >=8) {
            options = {
                DemographicsCompleted: option[CONSTANT.ASSESSMENT_GROUP.DEMOGRAPHICS.key] ,
                MedicalHistoryCompleted: option[CONSTANT.ASSESSMENT_GROUP.MEDICAL_HISTORY.key],
                ADLCompleted: option[CONSTANT.ASSESSMENT_GROUP.ADL.key],
                ChiefComplaintsCompleted: option[CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key],
                OswestryCompleted: option[CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key]
            }
        }

        return options;
    }

    const getButton = (title, task, styleClass, waitForSave = false) => {
        return { title: title, task: task, styleClass: styleClass, ...(waitForSave ? { waitForSave: true } : {}) }
    }

    const getError = (questionId) => {
        const error = formErrors.find((error) => error.questionId === questionId);
        if (error) {
            return error;
        } else {
            return '';
        }
    };

    const onExecuteScroll = (value, flag = false) => {
        try {
            const scrollIntoViewSmoothly =
                'scrollBehavior' in document.documentElement.style
                    ? scrollIntoView
                    : smoothScrollIntoView

            setTimeout(() => {
                const element = document.getElementById(`cc-question-${value}`);

                if (!value) {
                    window.scrollTo(0, 0);
                    return;
                }

                if (flag) {
                    //const elementPosition = document.getElementById(`cc-question-${value}`).offsetTop
                    //window.scrollTo({ top: elementPosition - 140, behavior: "smooth" })
                    scrollIntoViewSmoothly(element, { behavior: "smooth", block: "center" });
                    return;
                }

                if (element) {
                    // noinspection JSIgnoredPromiseFromCall
                    scrollIntoViewSmoothly(element, { behavior: "smooth", block: "start" });
                }
            }, 500);
        } catch (error) {
            console.error(error);
        }
    };

    const validationNotRequired = [10000023, 10000668,10000580];
    const checkValidityForRadioButton = (question) => {
        let flag = false;
        if(validationNotRequired.includes(question.questionId))  return flag;
        const id = getCheckedOption(question.questionId, question.responses)
        if (id === '') {
            return !flag;
        }

        const response = question.responses
        for (let i = 0; i < response.length; i++) {
            if (response[i].id === id) {
                flag = true;
                return !flag;
            }
        }

        return !flag;
    }

    const checkValidityForInputBox = (question) => {
        const value = getValue(question.responseType, question.questionId)
        const error = getError(question.questionId)

        if (outcomeAssessmentQuestionsId.includes(question.questionId) || validationNotRequired.includes(question.questionId)) {
            return '';
        }

        if (error) {
            return error;
        }

        if (value === '') {
            const errors = formErrors.filter((formError) => formError.questionId !== question.questionId);
            const error = { questionId: question.questionId, message: 'This field is required.' };
            errors.push(error);
            setFormErrors(errors);
            return error;
        }

        return ''
    }

    const _checkCurrentRegion = (region) => {
        if (currentGroupId === CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key) {
            if (previousRegion.current !== region) {
                previousRegion.current = region;
            }
        }

        return true;
    }


    const clickOnThumbnail = (url) => {
        setViewImage(url)
    }

    const getCurrentTabName = () => {
        for (const [, value] of Object.entries(CONSTANT.ASSESSMENT_GROUP)) {
            if (value.key === currentGroupId) {
                return value.name
            }
        }
        return ""
    }

    return (
        <>
            {redirectToMyAssessments && (<Redirect to={redirectUrl}/>)}
            <Modal className="modal-lg" isOpen={showInstruction} toggle={()=> setShowInstruction(false)}>
                <div className="edit-assessment-instruction-container">
                    <div className="edit-assessment-instruction-header-content kt-padding-b-30">
                        <p className="edit-assessment-instruction-header-content-title">{getCurrentTabName()}</p>
                        <img onClick={()=> setShowInstruction(false)} className="cursor-pointer" src={CloseButton} alt="Close Button" />
                    </div>
                    <label className="edit-assessment-instruction-info"  dangerouslySetInnerHTML={getMarkup(groupTitle)}/>
                </div>
            </Modal>
            <Modal isOpen={Boolean(viewImage)} toggle={()=> setViewImage("")} centered>
                <img alt="" src={viewImage} />
            </Modal>
            {loadingData && (
                <div className="edit-assessment-loading-message">
                    <div className="alert-text kt-ml-5 kt-mr-5 kt-font-xl kt-font-bold">
                        <h1>Please wait, loading your assessment... &nbsp;&nbsp;
                            <i className="fa fa-spinner fa-spin kt-font-primary"/>
                        </h1>
                    </div>
                </div>
            )}
            {assessmentNotFound ? <div className="edit-assessment-loading-message">
                <div className="alert-text kt-ml-5 kt-mr-5 kt-font-xl kt-font-bold">
                    <h1 className="text-center">We apologize, but we were unable to find the assessment you are searching for.</h1>
                </div>
            </div>: <>
                {!loadingData && (
                    <>
                        <ShowAllQuestionsHeader region={bodyRegionLists} invitationType={previousResponse?.['InvitationTypeText']} testId={testId} />
                        <div className="kt-wizard-v4" style={{margin: "3rem auto 0", width: "96%"}}>
                            <div className="kt-portlet h-90 position-relative shadow-none">
                                <div className="kt-portlet__body kt-portlet__body--fit">
                                    <div className="kt-grid bg-white">
                                        <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v4__wrapper">
                                            {loadingQuestions && (
                                                <div className="kt-spinner kt-spinner--lg kt-spinner--info kt-mt-100 kt-mb-100"/>
                                            )}
                                            {!loadingQuestions && (
                                                <form className="kt-form w-98 kt-padding-5" noValidate="novalidate">
                                                    {groups.length > 0 && (
                                                        groups.map((group, index) => {
                                                            return (
                                                                <Fragment key={index}>
                                                                    {currentGroupId && currentGroupViewOrderId && group.viewOrder === currentGroupViewOrderId && (
                                                                        <>
                                                                            {subGroups.length > 0 && (
                                                                                subGroups.map((subGroup, index) => {
                                                                                    return (
                                                                                        <Fragment
                                                                                            key={index}>
                                                                                            {((currentGroupId !== CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key
                                                                                                    && subGroups.indexOf(subGroup) === subGroupId)
                                                                                            ) && (
                                                                                                <>
                                                                                                    <div
                                                                                                        className={`edit-assessment-subgroup`}>
                                                                                                        {currentGroupId === CONSTANT.ASSESSMENT_GROUP.MEDICAL_HISTORY.key ? subGroups[tabCount] : subGroup}
                                                                                                    </div>
                                                                                                    {pageCaptions.length > 0 && (
                                                                                                        pageCaptions.map((pageCaption, index) => {
                                                                                                            return (
                                                                                                                <Fragment
                                                                                                                    key={index}>
                                                                                                                    {((currentGroupId !== CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key && pageCaptions.indexOf(pageCaption) === pageCaptionId) || currentGroupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key) && (
                                                                                                                        <>
                                                                                                                            {pageCaption && (<div className="edit-assessment-page-caption">{pageCaption}</div>)}
                                                                                                                            {subGroupQuestions && subGroupQuestions.length > 0 && (
                                                                                                                                <div className=" kt-mb-0 kt-padding-b-0 kt-mt-10" data-ktwizard-state="current">
                                                                                                                                    {subGroupQuestions.map((question, index) => {
                                                                                                                                            return (
                                                                                                                                                <Fragment
                                                                                                                                                    key={index}>
                                                                                                                                                    {((currentGroupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key && question.header2 === subGroup) ||
                                                                                                                                                            (currentGroupId !== CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key)) && !question['isDefaultHide'] &&
                                                                                                                                                        (
                                                                                                                                                            <>
                                                                                                                                                                {subGroup === 'RATE YOUR LEVEL OF PAIN' && (
                                                                                                                                                                    <label
                                                                                                                                                                        className="kt-section__title">
                                                                                                                                                                        <PainRate/>
                                                                                                                                                                    </label>
                                                                                                                                                                )}
                                                                                                                                                                {(currentGroupId === CONSTANT.ASSESSMENT_GROUP.OUTCOME_ASSESSMENT.key && previousRegion.current !== question.header1 &&
                                                                                                                                                                    _checkCurrentRegion(question.header1) && subGroup !== previousRegion.current) && (
                                                                                                                                                                    <div
                                                                                                                                                                        className="edit-assessment-subgroup">
                                                                                                                                                                        {previousRegion.current}
                                                                                                                                                                    </div>
                                                                                                                                                                )}
                                                                                                                                                                {(question.responseType === CONSTANT.ELEMENT_TYPES.TEXT
                                                                                                                                                                        || question.responseType === CONSTANT.ELEMENT_TYPES.CALCULATION
                                                                                                                                                                        || question.responseType === CONSTANT.ELEMENT_TYPES.NUMBER) &&
                                                                                                                                                                    question.questionId !== CONSTANT.CHATBOT_ACCOUNT.DATE_OF_BIRTH && (
                                                                                                                                                                        <InputBox
                                                                                                                                                                            disabled={question.isScoreQuestion}
                                                                                                                                                                            label={question.text}
                                                                                                                                                                            placeHolder={question.dialogue}
                                                                                                                                                                            value={getValue(question.responseType, question.questionId)}
                                                                                                                                                                            question={question}
                                                                                                                                                                            error={isClicked && checkValidityForInputBox(question)}
                                                                                                                                                                            onChange={onChangeCollectResponse}/>
                                                                                                                                                                    )}
                                                                                                                                                                {question.responseType === CONSTANT.ELEMENT_TYPES.BUTTON && question.responses && question.responses.length > 0 && (
                                                                                                                                                                    <RadioButton
                                                                                                                                                                        isFocused={question.questionId === nextQuestionOrderId}
                                                                                                                                                                        label={question.text}
                                                                                                                                                                        question={question}
                                                                                                                                                                        options={question.responses}
                                                                                                                                                                        checkedOption={getCheckedOption(question.questionId, question.responses)}
                                                                                                                                                                        onChange={onChangeCollectResponse}
                                                                                                                                                                        errorMessage={isClicked && checkValidityForRadioButton(question)}
                                                                                                                                                                        cssStyle={currentGroupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key || isMobile ? 'column' : 'row'}/>
                                                                                                                                                                )}
                                                                                                                                                                {((question.responseType === CONSTANT.ELEMENT_TYPES.CHECKBOX || question.responseType === CONSTANT.ELEMENT_TYPES.MULTISELECT) && question.responses && question.responses.length > 0) && (
                                                                                                                                                                    <Checkbox
                                                                                                                                                                        isFocused={question.questionId === nextQuestionOrderId}
                                                                                                                                                                        label={question.text}
                                                                                                                                                                        question={question}
                                                                                                                                                                        options={question.responses}
                                                                                                                                                                        checkedOption={getCheckedOption(question.questionId, question.responses)}
                                                                                                                                                                        onChange={onChangeCollectResponse}
                                                                                                                                                                        errorMessage={isClicked && checkValidityForRadioButton(question)}
                                                                                                                                                                        clickOnThumbnail={clickOnThumbnail}
                                                                                                                                                                        cssStyle={currentGroupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key || isMobile ? 'column' : 'row'}/>
                                                                                                                                                                )}
                                                                                                                                                                {((question.responseType === CONSTANT.ELEMENT_TYPES.TEXT && question.questionId === CONSTANT.CHATBOT_ACCOUNT.DATE_OF_BIRTH)
                                                                                                                                                                    || question.responseType === CONSTANT.ELEMENT_TYPES.DATE_PICKER) && (
                                                                                                                                                                    <MaskInput
                                                                                                                                                                        label={question.text}
                                                                                                                                                                        value={getValue(question.responseType, question.questionId)}
                                                                                                                                                                        placeHolder={question.dialogue}
                                                                                                                                                                        question={question}
                                                                                                                                                                        mask={(question.questionId === CONSTANT.CHATBOT_ACCOUNT.DATE_OF_BIRTH || question.responseType === CONSTANT.ELEMENT_TYPES.DATE_PICKER) ? '99/99/9999' : '99/99/9999'}
                                                                                                                                                                        error={isClicked && checkValidityForInputBox(question)}
                                                                                                                                                                        onChange={onChangeCollectResponse}/>
                                                                                                                                                                )}
                                                                                                                                                            </>
                                                                                                                                                        )}
                                                                                                                                                </Fragment>
                                                                                                                                            )
                                                                                                                                        }
                                                                                                                                    )}
                                                                                                                                </div>
                                                                                                                            )}

                                                                                                                            <h1 className="text-center">{allPainQuesWithExerciseNameAndRenderedPic.length > 0 ? "All Pain Questions" : "No Pain question"}</h1>

                                                                                                                            {allPainQuesWithExerciseNameAndRenderedPic.length > 0 && allPainQuesWithExerciseNameAndRenderedPic.map((exercise,index) => {
                                                                                                                                // if(painQuestion['Responses'] && painQuestion['Responses'].length > 0) {
                                                                                                                                    return (
                                                                                                                                        <div style={{ margin: "10px", padding: "5px", border: "2px solid blue" }}>
                                                                                                                                            <h5 className="font-bolder">Exercise Name: {exercise?.ExerciseDisplayName}</h5>
                                                                                                                                            {exercise?.rendered_picture && (
                                                                                                                                                <img style={{ height: "400px", objectFit: "cover" }} src={exercise?.rendered_picture} alt="Question Image" className="pain-question-modal-question__image" />
                                                                                                                                            )}
                                                                                                                                            {exercise?.painQuestions?.map((painQuestion, index) => {
                                                                                                                                                return (
                                                                                                                                                    <div key={index} className="mb-3 pain-question-modal-question" id={`pain-question-${painQuestion.QuestionId}`}>
                                                                                                                                                        <div>
                                                                                                                                                            <label className="pain-question-modal-question__title">{painQuestion['QuestionTitle']}</label>
                                                                                                                                                        </div>
                                                                                                                                                        <div>
                                                                                                                                                            {painQuestion['Responses'].map((response, key) => {
                                                                                                                                                                const isCheckBox = painQuestion?.QuestionType === CONSTANT.ELEMENT_TYPES.CHECKBOX;
                                                                                                                                                                const answerList = painQuestion?.AnswerList;

                                                                                                                                                                return (
                                                                                                                                                                    // <RadioBtnComponent
                                                                                                                                                                    //     className={`kt-radio kt-radio--bold kt-radio--brand mr-3 pain-question-modal-question__options cursor-not-allowed pointer-none`}
                                                                                                                                                                    //     type='radio'
                                                                                                                                                                    //     key={key}
                                                                                                                                                                    //     name={`pain${painQuestion.QuestionId}`}
                                                                                                                                                                    //     value={response.AnswerId}
                                                                                                                                                                    //     checked={response.AnswerId === painQuestion.AnswerId}
                                                                                                                                                                    //     onClick={() => { }}
                                                                                                                                                                    //     title={response.AnswerTitle}
                                                                                                                                                                    //     painQuestion={painQuestion}
                                                                                                                                                                    // />

                                                                                                                                                                    <RadioBtnComponent
                                                                                                                                                                        className="kt-radio kt-radio--bold kt-radio--brand mr-3 pain-question-modal-question__options white-space-no-wrap  cursor-not-allowed pointer-none"
                                                                                                                                                                        type={isCheckBox ? CONSTANT.ELEMENT_TYPES.CHECKBOX : CONSTANT.ELEMENT_TYPES.BUTTON}
                                                                                                                                                                        key={key}
                                                                                                                                                                        name={`pain${painQuestion.QuestionId}`}
                                                                                                                                                                        value={response.AnswerId}
                                                                                                                                                                        checked={(isCheckBox && answerList.includes(response.AnswerId)) || (!isCheckBox && (response.AnswerId === painQuestion.AnswerId))}
                                                                                                                                                                        onClick={() => { }}
                                                                                                                                                                        title={response.AnswerTitle}
                                                                                                                                                                    />
                                                                                                                                                                );
                                                                                                                                                            })}
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                )
                                                                                                                                            })}

                                                                                                                                        </div>
                                                                                                                                    )
                                                                                                                                // }
                                                                                                                            })}
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </Fragment>
                                                                                                            )
                                                                                                        })
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                        </Fragment>
                                                                                    )
                                                                                })
                                                                            )}
                                                                            {subGroupQuestions && subGroupQuestions.length === 0 && !loadingQuestions && (
                                                                                <>
                                                                                    {((currentGroupId === CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key && isBodyPartSelected) || currentGroupId !== CONSTANT.ASSESSMENT_GROUP.CHIEF_COMPLAINTS.key) && (
                                                                                        <div className="kt-mt-100 kt-mb-100 text-center">
                                                                                            No Available Question
                                                                                        </div>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                            {errorMessage && (
                                                                                <div
                                                                                    className="text-center kt-mt-10 kt-mb-10 kt-font-danger kt-font-lg">
                                                                                    {errorMessage}
                                                                                </div>
                                                                            )}
                                                                            {successMessage && (
                                                                                <div
                                                                                    className="text-center kt-mt-10 kt-mb-10 kt-font-success kt-font-lg">
                                                                                    {successMessage}
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </Fragment>
                                                            )
                                                        })
                                                    )}
                                                </form>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>}
        </>
    )
};

export default ShowAllQuestionsDetails;
