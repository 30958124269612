import { createSlice } from '@reduxjs/toolkit';
import { initialEditAssessmentState } from "./editAssessmentConstants";
import editAssessmentExtraReducers from "./editAssessmentExtraReducers";
import { toast } from "react-toastify";
import { makeSerializable } from "../../utils/Utils";
import CONSTANT from "../../constants/Constant";

const editAssessmentSlice = createSlice({
    name: 'editAssessment',
    initialState: initialEditAssessmentState,
    reducers: {
        setTestId: (state, action) => {
            state.baseData.testId = action.payload;
        },
        setCurrentGroupId: (state, action) => {
            state.baseData.currentGroupId = action.payload;
        },
        setShowIntakeForm: (state, action) => {
            state.baseData.showIntakeForm = action.payload;
        },
        toggleShowPolicy: (state) => {
            state.baseData.showPolicy = !state.baseData.showPolicy;
        },
        toggleShowTermsAndConditionModal: (state) => {
            state.baseData.showTermsAndConditionModal = !state.baseData.showTermsAndConditionModal;
        },
        setCurrentSubGroupIndex: (state, action) => {
            state.baseData.currentSubGroupIndex = action.payload;
        },
        setShowInlineError: (state, action) => {
            state.baseData.showInlineError = action.payload;
        },
        setCurrentQuestionId: (state, action) => {
            state.baseData.currentQuestionId = action.payload;
        },
        setShowInstruction: (state, action) => {
            state.baseData.showInstruction = action.payload;
        },
        setOpenBiometricMovementAndCautionQuestionContainer: (state, action) => {
            state.baseData.openBiometricMovementAndCautionQuestionContainer = action.payload;
        },
        setGroupCompleted: (state, action) => {
            const { groupId, isCompleted } = action.payload;
            const { groups } = state;

            const group = groups.find(group => group.groupId === groupId);
            if(!group) {
                return;
            }
            group.groupCompleted = isCompleted;
        },
        setQuestionAnswer: (state, action) => {
            const { questionId, answerId, answerValue, subGroupIndex } = action.payload;

            const { groups, baseData } = state;
            const { currentGroupId } = baseData;
            const currentGroup = groups.find(group => group.groupId === currentGroupId);
            const currentSubGroup = currentGroup.subGroups[subGroupIndex];

            const question = currentSubGroup.questions.find(question => question.questionId === questionId);
            if(!question) {
                toast.error('Question not found');
                console.error('Question not found');
                return;
            }

            if((question?.responseType === CONSTANT.ELEMENT_TYPES.CHECKBOX) || (question?.responseType === CONSTANT.ELEMENT_TYPES.MULTISELECT)) {
                let tempAnswerList = question?.answerList ?? [];
                let doesAlreadySelectedCurrentAnswer = tempAnswerList.find(answer => answer === answerId);
                if(doesAlreadySelectedCurrentAnswer) {
                    tempAnswerList = tempAnswerList.filter(currentAnswerId => currentAnswerId !== answerId);
                } else {
                    tempAnswerList.push(answerId);
                }

                question.answerList = tempAnswerList;
            } else {
                question.answerId = answerId;
                question.answerValue = answerValue;
            }
        },
        handleHideOrShowQuestion: (state, action) => {
            const { questionId, isDefaultHide } = action.payload;
            const { groups } = state;

            let tempQuestion = null;
            groups.map(group => {
            if(group.subGroups.length > 0) {
                group.subGroups.map(subGroup => {
                    if(subGroup.questions.length > 0) {
                        subGroup.questions.map(question => {
                            if(question.questionId === questionId) {
                                tempQuestion = question;
                            }
                        })
                    }
                })
            }
            })
            if(!tempQuestion) {
                // toast.error('Question not found');
                console.error('Question not found');
                return;
            }
            tempQuestion.isDefaultHide = isDefaultHide;
            if(isDefaultHide) {
                tempQuestion.answerId = 0;
                tempQuestion.answerValue = "";
            }
        },
        setAllQuestionsError: (state, action) => {
            const { groups, baseData } = state;
            const { currentGroupId, currentSubGroupIndex } = baseData;
            const currentGroup = groups.find(group => group.groupId === currentGroupId);
            const lengthOfSubGroup = currentGroup.subGroups.length;
            //CC do not have any subgroup
            if(lengthOfSubGroup === 0) {
                return;
            }
            const currentSubGroup = currentGroup.subGroups[currentSubGroupIndex];
            const questions = currentSubGroup.questions;


            const questionsWithValidations = action.payload;

            questionsWithValidations.forEach((questionWithValidation) => {
                const question = questions.find(question => question.questionId === questionWithValidation.questionId);
                if(!question) {
                    toast.error('Question not found');
                    console.error('Question not found');
                    return;
                }
                question.error = questionWithValidation.error;
            })
        },
        clearEditAssessmentData: (state) => {
            return initialEditAssessmentState;
        },
        updateCCQuestionAnswer: (state, action) => {
            const { answerId, answerValue } = action.payload;
            const { ccQuestion } = state;
            ccQuestion.answerId = answerId;
            ccQuestion.answerValue = answerValue;
        },
    },
    extraReducers: editAssessmentExtraReducers,
});

export const {
    setTestId,
    setOpenBiometricMovementAndCautionQuestionContainer,
    setCurrentGroupId,
    setCurrentSubGroupIndex,
    setQuestionAnswer,
    setShowInlineError,
    setAllQuestionsError,
    setCurrentQuestionId,
    clearEditAssessmentData,
    updateCCQuestionAnswer,
    setShowInstruction,
    setGroupCompleted,
    setShowIntakeForm,
    toggleShowPolicy,
    toggleShowTermsAndConditionModal,
    handleHideOrShowQuestion
} = editAssessmentSlice.actions;

export default editAssessmentSlice.reducer;
