const { REACT_APP_ROUTE_BASE_PATH } = process.env;

export default {
    landing: REACT_APP_ROUTE_BASE_PATH + "/",
    signIn: REACT_APP_ROUTE_BASE_PATH + "/sign-in",
    signUp: REACT_APP_ROUTE_BASE_PATH + "/sign-up",
    dashboard: REACT_APP_ROUTE_BASE_PATH + "/dashboard",
    userProfile: REACT_APP_ROUTE_BASE_PATH + "/user-profile",
    changePassword: REACT_APP_ROUTE_BASE_PATH + "/change-password",
    mySubscription: REACT_APP_ROUTE_BASE_PATH + "/my-subscription",
    myCalendar: REACT_APP_ROUTE_BASE_PATH + "/my-calendar",
    myAssessments: REACT_APP_ROUTE_BASE_PATH + "/my-assessments",
    myReports: REACT_APP_ROUTE_BASE_PATH + "/my-reports",
    leftSideMenu: REACT_APP_ROUTE_BASE_PATH + "/left-side",
    myTelemedicineInvitation: REACT_APP_ROUTE_BASE_PATH + "/my-telemedicine-invitation",
    myExercises: REACT_APP_ROUTE_BASE_PATH + "/my-exercises",
    assessment: REACT_APP_ROUTE_BASE_PATH + `/assessment/:bodyRegion`,
    myIntake: REACT_APP_ROUTE_BASE_PATH + "/my-intake",
    myDocuments: REACT_APP_ROUTE_BASE_PATH + "/my-documents",
    myPainlogs: REACT_APP_ROUTE_BASE_PATH + "/my-painlogs",
    newScreenings: REACT_APP_ROUTE_BASE_PATH + "/new-screenings",
    myMedicalHistory: REACT_APP_ROUTE_BASE_PATH + "/my-medical-history",
    editAssessment: REACT_APP_ROUTE_BASE_PATH + "/edit-assessment",
    editAssessmentOld: REACT_APP_ROUTE_BASE_PATH + "/edit-assessment-old",
    showAllQuestion: REACT_APP_ROUTE_BASE_PATH + "/show-all-questions",
    unauthorized: REACT_APP_ROUTE_BASE_PATH + "/unauthorized",
    forgot: REACT_APP_ROUTE_BASE_PATH + "/forgot",
    // otp: REACT_APP_ROUTE_BASE_PATH + "/otp",
    myStatistics: REACT_APP_ROUTE_BASE_PATH + "/my-statistics",
    // teamsEditAssessment: REACT_APP_ROUTE_BASE_PATH + "/teams-edit-assessment",
    cards: REACT_APP_ROUTE_BASE_PATH + "/cards",
    addCard: REACT_APP_ROUTE_BASE_PATH + "/add-card"
}
