import { makeSerializable } from "../../../utils/Utils";

class ChiefComplaintDTO {
    constructor({ QuestionId, QuestionTitle, AnswerId, AnswerValue, Responses }) {
        this.questionId = QuestionId;
        this.questionTitle = QuestionTitle;
        this.answerId = AnswerId;
        this.answerValue = AnswerValue;
        this.responses = Responses.map(response => makeSerializable(new Response(response)));
    }
}

class Response {
    constructor({ AnswerId, AnswerTitle, AnswerOrderId, ColorCode, ConditionalQuestionList, Title, Description }) {
        this.answerId = AnswerId;
        this.answerTitle = AnswerTitle;
        this.answerOrderId = AnswerOrderId;
        this.colorCode = ColorCode;
        this.conditionalQuestionList = ConditionalQuestionList;
        this.title = Title;
        this.description = Description;
    }
}

export default ChiefComplaintDTO;