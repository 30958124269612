//as many response type like text, number, calculation, date picker are using input box for validation that's why
//does not using CONSTANT.ELEMENT_TYPES for key in answerValidationMessagesConstant but using new key for them

const answerValidationMessagesConstant = Object.freeze({
    PASSWORD: Object.freeze({
        KEY: 'password',
        REQUIRED: 'Password is required.',
        TOO_SHORT: 'Password must have at least 8 characters.',
        MUST_HAVE_UPPERCASE: 'Password must have at least 1 upper case letter.',
        MUST_HAVE_LOWERCASE: 'Password must have at least 1 lower case letter.',
        MUST_HAVE_NUMBER: 'Password must have at least 1 number.',
        MUST_HAVE_SPECIAL_CHAR: 'Password must have at least 1 special character.',
    }),
    CONFIRM_PASSWORD: Object.freeze({
        KEY: 'confirm_password',
        REQUIRED: 'This field is required.',
        MISMATCH: 'Password doesn\'t match',
    }),
    EMAIL: Object.freeze({
        KEY: 'email',
        REQUIRED: 'Email is required.',
        INVALID: 'Please enter a valid email address.',
    }),
    HEIGHT: Object.freeze({
        KEY: 'height',
        INVALID: 'Please enter valid height.',
        NOT_INTEGER: 'Height must be integer',
        OUT_OF_RANGE: 'Height must be between 2.1 to 8.5 feet',
    }),
    WEIGHT: Object.freeze({
        KEY: 'weight',
        REQUIRED: 'This field is required.',
        INVALID: 'Please enter valid weight.',
        NOT_INTEGER: 'Weight must be integer',
        OUT_OF_RANGE: 'Weight should be between 30 lbs to 500 lbs.',
    }),
    DATE_OF_BIRTH: Object.freeze({
        KEY: 'date_of_birth',
        INVALID: 'Please enter a valid date of birth.',
        OUT_OF_RANGE: 'Age should be between 7 to 120 years.',
    }),
    DATE: Object.freeze({
        KEY: 'date',
        INVALID: 'Please enter valid date.',
        INVALID_MONTH: 'Month is not valid.',
        INVALID_YEAR_LESS_THAN_FOUR_DIGIT: 'Year must be 4 digit',
        INVALID_YEAR_NOT_DIGIT: 'All character of the year must be digit',
        INVALID_CHARACTER: 'All character of the year must be digit',
    }),
    RADIOBUTTON: Object.freeze({
        KEY: 'radio_button',
        REQUIRED: 'This field is required',
        PLEASE_SELECT: 'Please select an answer',
    }),
    DROP_DOWN: Object.freeze({
        KEY: 'dropdown',
        REQUIRED: 'Please select an option.',
    }),
    INPUT_BOX: Object.freeze({
        KEY: 'input_box',
        REQUIRED: 'This field is required.',
    }),
    CHECKBOX: Object.freeze({
        KEY: 'checkbox',
        REQUIRED: 'Please select at least one option.',
    }),
    FIRST_AND_LAST_NAME: Object.freeze({
        KEY: 'first_and_last_name',
        REQUIRED: 'This field is required.',
        MIN_LENGTH: 'Please enter at least two characters.',
    }),
    HEIGHT_FEET: Object.freeze({
        KEY: 'height_feet',
        REQUIRED: 'This field is required.',
        INVALID: 'Please enter valid height in feet.',
        NOT_INTEGER: 'Height in feet must be an integer.',
        OUT_OF_RANGE: 'Height in feet must be between 2 and 8.',
    }),
    HEIGHT_INCH: Object.freeze({
        KEY: 'height_inch',
        REQUIRED: 'This field is required.',
        INVALID: 'Please enter valid height in inches.',
        NOT_INTEGER: 'Height in inches must be an integer.',
        OUT_OF_RANGE: 'Height in inches must be between 0 and 11.',
        OUT_OF_RANGE_FEET: 'Height in inches must be between 1 and 11.'
    }),
    TERMS_AND_CONDITIONS: Object.freeze({
        KEY: 'accept_terms',
        REQUIRED: 'Accept Terms & Conditions is required.',
    }),
    GENDER_AS_SELECT: Object.freeze({
        KEY: 'gender_as_select',
        REQUIRED: 'This field is required.',
        INVALID_OPTION: 'Please select a valid option.',
    }),
    DOMINANT_HAND_AS_SELECT: Object.freeze({
        KEY: 'dominant_hand_as_select',
        REQUIRED: 'This field is required.',
        INVALID_OPTION: 'Please select a valid option.',
    }),
    GENERIC: Object.freeze({
        KEY: 'generic',
        REQUIRED: 'This field is required.',
    }),
});

export default answerValidationMessagesConstant;