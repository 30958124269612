import React, {useEffect, useState, useRef, useContext} from "react";
import OT from "@opentok/client";
import MovementGuideline from "./MovementGuideline";
import AppContext from "../../../contexts/AppContext";
import TelemedicineVideoToolbar from "./TelemedicineVideoToolbar";
import { toast } from 'react-toastify';
import MovementGuidelineAllModal from "./MovementGuidelineAllModal";
import SweetAlert from "react-bootstrap-sweetalert";

const TelemedicineDetails = ({  biometricExercisesGuideline, isSelectedTrainingMovementGide, setIsSelectedTrainingMovementGide, bodyRegionList }) => {
    const { telemedicine, toggleShowTelemedModal } = useContext(AppContext);
    const { telemedicineKeys, onCloseCallback } = telemedicine;


    const [selectedExercise, setSelectedExercise] = useState({});
    const [isAudioMuted, setIsAudioMuted] = useState(false);
    const [isVideoHidden, setIsVideoHidden] = useState(false);
    const [sweetAlertErrorMsg, setSweetAlertErrorMsg] = useState(false);
    const [subscriberCount, setSubscriberCount] = useState(0);

    const publisherVideoRef = useRef(null);
    let publisher = useRef(null);
    let subscriber = useRef(null);
    let session = useRef(null);
    let errorMsg = useRef("");
    let isCameraAccessDenied = useRef(false);


    const clearState = () => {
        setSelectedExercise({});
        setIsAudioMuted(false);
        setIsVideoHidden(false);
        setSweetAlertErrorMsg(false);
        errorMsg.current = "";
        isCameraAccessDenied.current = false;
        setSubscriberCount(0);
    }

    const reconnect = (isCameraBeingUsedByAnotherProgram = false) => {
        if(!isCameraBeingUsedByAnotherProgram) {
            toast.warn('Disconnected from the room. Attempting to reconnect');
        }

        clearState();
        initializeSession();

        if(!navigator.onLine) {
            setTimeout(reconnect, 5000);
        }
    }

    useEffect(()=> {
        initializeSession();
        drawCtx();

        return () => {
            if(session.current) {
                session.current.off('streamCreated');
                session.current.off('sessionDisconnected');
                session.current.off('signal');
                session.current.disconnect();
            }

            if (publisher.current) {
                publisher.current.destroy();
                publisher.current = null;
            }

            if (subscriber.current) {
                subscriber.current.destroy();
                subscriber.current = null;
            }
            onCloseCallback && onCloseCallback();
        }
    },[]);


    const initializeSession = () => {
        try {
            if(session.current) {
                session.current.off('streamCreated');
                session.current.off('sessionDisconnected');
                session.current.off('signal');
                session.current.disconnect();
            }
            if (publisher.current) {
                publisher.current.destroy();
                publisher.current = null;
            }

            if (subscriber.current) {
                subscriber.current.destroy();
                subscriber.current = null;
            }

            session.current = null;

            session.current = OT.initSession(telemedicineKeys?.opentokApiKey, telemedicineKeys?.opentokSessionId);

            session.current.on('streamCreated', (event) => {
                const subscriberOptions = {
                    insertMode: 'append',
                    width: '200px',
                    height: '200px'
                };
                subscriber.current = session.current.subscribe(event.stream, 'subscriber', subscriberOptions, handleError);
                if(subscriber.current) {
                    subscriber.current.on('error', function(err) {
                        handleError(err);
                    });
                    setSubscriberCount(prevCount => prevCount + 1);
                }
            });

            session.current.on('streamDestroyed', (event) => {
                setSubscriberCount(prevCount => prevCount - 1);
            });

            session.current.on('sessionDisconnected', (event) => {
                reconnect();
            })

            session.current.on("signal", function(event) {
                if(event && event.data){
                    let signalData = JSON.parse(event.data);
                    if(signalData.data){
                        let data = JSON.parse(signalData.data);
                        // if(data?.exerciseName && selectedExercise?.exerciseName === data.exerciseName) {
                        //     return;
                        // }

                        if(data?.exerciseName && biometricExercisesGuideline && biometricExercisesGuideline.length > 0){
                            let exercise = biometricExercisesGuideline.find(x => x.ExerciseValue === data.exerciseName);
                            if(exercise) {
                                let selectedExObj = {
                                    ExerciseName: exercise.ExerciseName,
                                    PatientGuidelineContent: exercise.PatientGuidelineContent,
                                    ExerciseMediaUrlPatient: exercise.ExerciseMediaUrlPatient
                                }
                                setSelectedExercise(selectedExObj);
                            }

                            if(data?.movementName && data?.movementStartStopStatus) {
                                let movementStr = data?.movementStartStopStatus;
                                if(movementStr) {
                                    toast.success(`${data?.movementName} ${movementStr}`);
                                }
                            }
                        } else if(data?.endMeeting) {
                            onCloseCallback && onCloseCallback();
                            toggleShowTelemedModal();
                        }
                    }
                }
            });

            // initialize the publisher
            const publisherOptions = {
                insertMode: 'append',
                width: '100%',
                height: '80vh'
            };
            publisher.current = OT.initPublisher('publisher', publisherOptions, handleError);
            if(publisher.current) {
                publisher.current.on('error', function(err) {
                    handleError(err);
                });
                publisher.current.on('videoDisabled', event => {
                    console.error('video disabled ', event);
                });
            }

            // Connect to the session
            session.current.connect(telemedicineKeys?.opentokToken, (error) => {
                if (error) {
                    handleError(error);
                } else {
                    // If the connection is successful, publish the publisher to the session
                    session.current.publish(publisher.current, handleError);
                }
            });
        } catch (e) {
            handleError(e);
        }

    }

    function handleError(error) {
        if (error) {
            if (error.name === 'OT_HARDWARE_UNAVAILABLE' || error.name === "NotReadableError") {
                errorMsg.current = "Your camera or microphone is being used by another application. Please close other applications and try again.";
                setSweetAlertErrorMsg(true);


            } else if(error?.name === "OT_USER_MEDIA_ACCESS_DENIED") {
                errorMsg.current = "You have denied access to your camera and microphone. Please allow access and refresh the window.";
                setSweetAlertErrorMsg(true);
                isCameraAccessDenied.current = true;
            }
        }
    }

    const onClickOkBtn = () => {
        setSweetAlertErrorMsg(false);
        errorMsg.current = "";
        if(!isCameraAccessDenied.current) {
            setTimeout(() => reconnect(true), 5000);
        }
    }

    const drawCtx = () => {
        if (publisherVideoRef.current) {
            let ctx = publisherVideoRef.current.getContext('2d');
            ctx.clearRect(0, 0, publisherVideoRef.current.width, publisherVideoRef.current.height);

            let canvasMidWidth = publisherVideoRef.current.width / 2;
            // let canvasMidHeight = publisherVideoRef.current.height / 2;

            ctx.lineWidth = 1;
            ctx.strokeStyle = "blue";

            ctx.beginPath();
            ctx.moveTo(canvasMidWidth, 0);
            ctx.lineTo(canvasMidWidth, publisherVideoRef.current.height);
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(canvasMidWidth - 50, publisherVideoRef.current.height - 5);
            ctx.lineTo(canvasMidWidth + 50, publisherVideoRef.current.height - 5);
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(canvasMidWidth - 49, publisherVideoRef.current.height - 35);
            ctx.lineTo(canvasMidWidth - 49, publisherVideoRef.current.height - 5);
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(canvasMidWidth + 49, publisherVideoRef.current.height - 35);
            ctx.lineTo(canvasMidWidth + 49, publisherVideoRef.current.height - 5);
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(canvasMidWidth - 50, publisherVideoRef.current.height - 35);
            ctx.lineTo(canvasMidWidth - 40, publisherVideoRef.current.height - 35);
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(canvasMidWidth + 50, publisherVideoRef.current.height - 35);
            ctx.lineTo(canvasMidWidth + 40, publisherVideoRef.current.height - 35);
            ctx.stroke();
        }
    };

    const onClickMuteUnmuteVideo = async () => {
        try {
            if(publisher.current) {
                if(publisher.current.getVideoSource() && !publisher.current.getVideoSource().track) {
                    // toast.error("Camera is not available");
                    reconnect(true);
                    return;
                }
                await publisher.current.publishVideo(isVideoHidden);
                setIsVideoHidden((prevState) => !prevState);
            } else {
                toast.error("Camera is not available");
            }
        } catch (e) {
            handleError(e)
        }
    }

    const onClickMuteUnmuteAudio = () => {
        try {
            if(publisher.current) {
                publisher.current.publishAudio(isAudioMuted);
                setIsAudioMuted((prevState) => !prevState);
            }
        } catch (e) {
            handleError(e);
        }
    }

    const onClickCameraSwitch = async () => {
        if (publisher.current) {
            try {
                await publisher.current.cycleVideo();
            } catch (error) {
                handleError(error);
            }
        }
    }

    return (
        <div className="row position-relative telemedicine-video-guideline-container">
            <>
                <div className="col-md-12 col-lg-9 telemedicine-video-guideline-container__video-continer">
                    <div id="videos">
                        <div id="subscriber"></div>
                        <div id="publisher"></div>
                    </div>

                    <canvas ref={publisherVideoRef} className="telemedicine-video-guideline-container__canvas"/>
                    <p className="telemedicine-video-guideline-container__message">{subscriberCount <= 0 ? "Please wait for the provider to join." : ""}</p>

                    <TelemedicineVideoToolbar
                        onClicks={{
                            onClickMuteUnmuteVideo,
                            onClickMuteUnmuteAudio,
                            onClickCameraSwitch
                        }}
                        controlStates={{
                            isVideoHidden,
                            isAudioMuted
                        }}
                    />
                </div>

                <div className="col-md-12 col-lg-3 telemedicine-video-guideline-container__guideline-container position-static">
                    <MovementGuideline selectedExercise={selectedExercise} isFromMovementGuidelineModal={false}/>
                </div>
            </>

            {isSelectedTrainingMovementGide && (
                <MovementGuidelineAllModal
                    isSelectedTrainingMovementGide={isSelectedTrainingMovementGide}
                    setIsSelectedTrainingMovementGide={setIsSelectedTrainingMovementGide}
                    // keys={keys}
                    bodyRegionList={bodyRegionList}
                    biometricExercisesGuideline={biometricExercisesGuideline}
                />
            )}

            <SweetAlert
                confirmBtnCssClass="btn btn-sm btn-success"
                showConfirm= {true}
                showCancel={false}
                show={sweetAlertErrorMsg}
                title=""
                type="info"
                onConfirm={onClickOkBtn}
                confirmBtnText="Ok"
                closeOnClickOutside={false}>
                <div className='tele_sweetalert_message'>
                    {errorMsg.current}
                </div>
            </SweetAlert>
        </div>
    );
}

export default TelemedicineDetails;
