import React, { Fragment } from "react";
import { getMarkup } from "../../../utils/Utils";
import { UncontrolledTooltip } from "reactstrap";

const CheckboxForEditAssessment = ({
                      isFocused,
                      label,
                      question,
                      checkedOption,
                      options,
                      onChange,
                      errorMessage,
                      cssStyle,
                      error,
                      clickOnThumbnail,
                      checkedOptionsIds,
                      disabled = false
                  }) => {
    const onChangeCheckbox = (
        e,
        answerId,
        answerText,
        index,
        title,
        description
    ) => {
        let isChecked = e.target.checked;

        onChange &&
        onChange({
            question: question,
            answerId: answerId,
            answerText: answerText,
            answerIndex: index,
            title: title,
            description: description,
            checked: !isChecked,
        });
    };

    const getFocusColor = () => {
        if (isFocused) {
            return { border: "1px solid #1176B4" };
        }
    };


    return (
        <>
            {cssStyle === "row" && (
                <div className="form-group row" style={getFocusColor()}>
                    <label
                        id={`cc-question-${question.questionId}`}
                        className="col-3 col-form-label heading-primary"
                    >
                        {label}
                    </label>
                    <div className="col-9 col-form-label">
                        <div>
                            {options &&
                                options.length > 0 &&
                                options.map((option, index) => {
                                    return (
                                        <label
                                            key={index}
                                            className="col-form-label heading-primary"
                                        >
                                            <div
                                                // type="checkbox"
                                                className={`edit-assessment-button edit-assessment-checkbox ${checkedOptionsIds.includes(option?.id) ? 'selected-button' : '' }`}
                                                defaultChecked={option.id === checkedOption}
                                                disabled={disabled}
                                                onClick={(e) =>
                                                    onChangeCheckbox(
                                                        e,
                                                        option.id,
                                                        option.name,
                                                        index,
                                                        option.title,
                                                        option.description
                                                    )
                                                }
                                            >
                                                {checkedOptionsIds.includes(option?.id) ?
                                                    <i className="fa fa-check mr-3"/> : ""}
                                                {option.name}
                                            </div>
                                        </label>
                                    );
                                })}
                        </div>
                        {errorMessage && (
                            <div className="invalid-feedback d-block">
                                This field is required
                            </div>
                        )}
                        {error && error.questionId === question.questionId && (
                            <span className="form-text text-muted kt-font-danger">
                * {error.message}
              </span>
                        )}
                    </div>
                </div>
            )}
            {cssStyle === "column" && (
                <div className="form-group kt-mb-10 pl-2" style={getFocusColor()}>
                    <div className="d-flex justify-content-between">
                        <div>
                            <label
                                id={`cc-question-${question.questionId}`}
                                className="kt-font-lg"
                                dangerouslySetInnerHTML={getMarkup(label)}
                            />
                            <div className="kt-checkbox-inline">
                                {options &&
                                    options.length > 0 &&
                                    options.map((option, index) => {
                                        return (
                                            <Fragment key={index}>
                                                <label
                                                    className="kt-checkbox kt-checkbox--brand"
                                                    id={`btn-${question.questionId}-${option.id}`}
                                                    key={index}
                                                >
                                                    <input
                                                        type="checkbox"
                                                        defaultChecked={option.id === checkedOption}
                                                        onChange={(e) =>
                                                            onChangeCheckbox(
                                                                e,
                                                                option.id,
                                                                option.name,
                                                                index,
                                                                option.title,
                                                                option.description
                                                            )
                                                        }
                                                    />
                                                    <label style={{ color: option.color }}>
                                                        {option.name}
                                                    </label>
                                                    <span />
                                                </label>
                                                {option.title && (
                                                    <UncontrolledTooltip
                                                        placement="top"
                                                        target={`btn-${question.questionId}-${option.id}`}
                                                    >
                                                        <strong>{option.title}</strong>
                                                        <br />
                                                        {option.description}
                                                    </UncontrolledTooltip>
                                                )}
                                            </Fragment>
                                        );
                                    })}

                            </div>
                        </div>
                        <div className="image-wrapper">
                            {question?.BodyLocationImageUrl &&(
                                <img style={{width:50,height:50,cursor:"pointer"}} src={question.BodyLocationImageUrl} alt="" onClick={()=>clickOnThumbnail(question?.BodyLocationImageUrl)}/>
                            )}
                        </div>
                    </div>

                    {errorMessage && (
                        <div className="invalid-feedback d-block">
                            This field is required
                        </div>
                    )}
                    {error && error.questionId === question.questionId && (
                        <span className="form-text text-muted kt-font-danger">
              * {error.message}
            </span>
                    )}
                </div>
            )}
        </>
    );
};

export default CheckboxForEditAssessment;