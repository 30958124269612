import MovementModal from "./MovementModal";
import { Modal, ModalHeader } from "reactstrap";
import TakeBiometricMovements from "../TakeBiometricMovements";
import React, { useEffect, useState } from "react";
import crossBtn from "../../../assets/img/utils/cross.svg";
import GLOBAL from "../../../global";
import routes from "../../../constants/Routes";

const BiometricMovementAndCautionQuestionContainer = (props) => {
    const { data, setOpenBiometricMovementAndCautionQuestionContainer } = props;
    const { testId, selectedBodyRegions, openBiometricMovementAndCautionQuestionContainer } = data;

    const [openMovementModal, setOpenMovementModal] = useState(false);
    const [cautionQuestionModal, setCautionQuestionModal] = useState(false);

    useEffect(() => {
        if(openBiometricMovementAndCautionQuestionContainer) {
            if(selectedBodyRegions?.bodyRegionList.find(item => item.toString() === "5")) {
                setOpenMovementModal(true);
                return;
            }
            setCautionQuestionModal(true);
        }
    }, [openBiometricMovementAndCautionQuestionContainer]);

    const onCloseMovementModal = () => {
        setOpenMovementModal(false);
        GLOBAL.ASSESSMENT = null;
        GLOBAL.ALREADY_CALLED_API = false;
        window.location.replace(`/#${routes.myAssessments}/false`)
        clearData();
    };

    const onClickMovementCell = () => () => {
        GLOBAL.ASSESSMENT = null;
        setOpenMovementModal(true);
    };

    const clearData = () => {
        setCautionQuestionModal(false);
        setOpenBiometricMovementAndCautionQuestionContainer(false);
        setOpenMovementModal(false);
    }

    const closeBtn = (
        <img src={crossBtn} onClick={clearData} type="button" alt="cross" />
    );

    return(
        <>
            {openBiometricMovementAndCautionQuestionContainer && (
                <>
                    {openMovementModal && (
                        <MovementModal
                            open={openMovementModal}
                            selectedRegion={selectedBodyRegions}
                            testId={testId}
                            onClose={onCloseMovementModal}/>
                    )}
                    {cautionQuestionModal && (
                        <Modal
                            isOpen={cautionQuestionModal}
                            contentClassName="custom-modal-style"
                            backdrop={'static'}
                            className="modal-dialog modal-xl kt-m-0 modal-dialog-centered">
                            <ModalHeader
                                toggle={clearData}
                                close={closeBtn}
                                className="d-flex align-items-center text-center movement-caution">
                                Caution
                            </ModalHeader>
                            <TakeBiometricMovements
                                setTakeBiometricMovementModal={setCautionQuestionModal}
                                data={{
                                    TestId: testId,
                                    BodyRegionList: selectedBodyRegions
                                }}
                                onClickMovementCell={onClickMovementCell}>
                            </TakeBiometricMovements>
                        </Modal>
                    )}
                </>
            )}
        </>

    )
}

export default BiometricMovementAndCautionQuestionContainer;