import React from "react";
import CONSTANT from "../../../../constants/Constant";
import RadioBtnComponent from "./RadioBtnComponent";

const PainQuestionItem = ({ painQuestion, onChangeAnswer, isClickedOnSubmitBtn }) => {
    const isCheckBox = painQuestion?.QuestionType === CONSTANT.ELEMENT_TYPES.CHECKBOX;
    const answerList = painQuestion?.AnswerList;
    const singleSelectAnswerId = painQuestion?.SingleSelectAnswerId ? painQuestion?.SingleSelectAnswerId : 0;

    const shouldResponseDisable = (answer) => {
        const answerId = answer?.AnswerId;

        if(isCheckBox && singleSelectAnswerId) {
            if(answerList.length > 0) {
                if(answerList.includes(singleSelectAnswerId) && answerId !== singleSelectAnswerId) {
                    return true;
                } else if(!answerList.includes(singleSelectAnswerId) && answerId === singleSelectAnswerId) {
                    return true;
                }
            }
        }

        return false;
    }

    const isAnswered = (question) => {
        if(isCheckBox) {
            return !(question?.AnswerList?.length > 0);
        } else {
            return !question?.AnswerId;
        }
    }

    return (
        <div className="mb-3 pain-question-modal-question" id={`pain-question-${painQuestion.QuestionId}`}>
            <div>
                <label className="pain-question-modal-question__title">{painQuestion.QuestionTitle}</label>
            </div>
            <div>
                {painQuestion.Responses.map((response, key) => (
                    <RadioBtnComponent
                        className="kt-radio kt-radio--bold kt-radio--brand mr-3 pain-question-modal-question__options white-space-no-wrap"
                        type={isCheckBox ? CONSTANT.ELEMENT_TYPES.CHECKBOX : CONSTANT.ELEMENT_TYPES.BUTTON}
                        key={key}
                        name={`pain${painQuestion?.QuestionId}`}
                        value={response?.AnswerId}
                        checked={(isCheckBox && answerList.includes(response?.AnswerId)) || (!isCheckBox && (response?.AnswerId === painQuestion?.AnswerId))}
                        onClick={() => onChangeAnswer(painQuestion, response?.AnswerId)}
                        title={response?.AnswerTitle}
                        disabled={shouldResponseDisable(response)}
                    />
                ))}
                {isClickedOnSubmitBtn && isAnswered(painQuestion) && (
                    <div className="color-red d-block">* Please answer this question.</div>
                )}
            </div>
        </div>
    );
};

export default PainQuestionItem;