import GLOBAL from "../../../../global";
import Assessment from "../../../../models/Assessment";
import {
    ASSESSMENT_SAVE_URL,
    UPDATE_USER_URL,
    TELEMED_SESSION_KEY_URL,
    EXERCISE_GUIDELINE_ALL,
    // RUN_EXERCISE_COMPLETE_URL
} from './UrlManager';
import axios from '../../../../../src/services/Axios';
import MeasurementResult from "../../../../models/MeasurementResult";
import CONSTANT from "../../../../constants/Constant";
import ExamService from "../../../../services/ExamService";
import { error, success } from "../../../../services/ResponseHandler";

const DEFAULT_RENDER_IMAGE_URL = "https://mmhcdn.s3.us-east-2.amazonaws.com/exercises/SpecialTest/UnableToPerform.png";
const RESPONSE_TYPE = { responseType: 'text' };

const getNewAssessment = (providerEmail,testId,bodyRegionList) => {
    let assessment = new Assessment();
    assessment.ProviderEmail = providerEmail;
    assessment.TestId = (GLOBAL.ASSESSMENT && GLOBAL.ASSESSMENT['TestId']) || testId;
    assessment.BodyRegionList = bodyRegionList || [];
    assessment.InvitationId = GLOBAL.URL_PARAMS.INVITATION_ID;
    assessment.DemographicsCompleted = (GLOBAL.ASSESSMENT && GLOBAL.ASSESSMENT['DemographicsCompleted']) || false;
    assessment.ADLCompleted = (GLOBAL.ASSESSMENT && GLOBAL.ASSESSMENT['ADLCompleted']) || false;
    assessment.MedicalHistoryCompleted = (GLOBAL.ASSESSMENT && GLOBAL.ASSESSMENT['MedicalHistoryCompleted']) || false;
    assessment.ChiefComplaintsCompleted = (GLOBAL.ASSESSMENT && GLOBAL.ASSESSMENT['ChiefComplaintsCompleted']) || false;
    assessment.Results = [];
    assessment.Answers = [];

    return assessment;
};

const _prepareMeasurementResults = (assessment, response, tempExercise = []) => {
    let measurement = [];
    let left = 0;
    let leftUnit = '';
    let right = 0;
    let rightUnit = '';
    let below = 0;
    let belowUnit = '';
    let below2 = 0;
    let below2Unit = '';
    let below3 = 0;
    let below3Unit = '';
    let below4 = 0;
    let below4Unit = '';
    let below5 = 0;
    let below5Unit = '';
    let below6 = 0;
    let below6Unit = '';
    let below7 = 0;
    let below7Unit = '';
    let below8 = 0;
    let below8Unit = '';
    let below9 = 0;
    let below9Unit = '';
    let below10 = 0;
    let below10Unit = '';
    let below11 = 0;
    let below11Unit = '';
    let below12 = 0;
    let below12Unit = '';
    let below13 = 0;
    let below13Unit = '';

    try{
        if (response.request_output && response.request_output.result && response.request_output.result.length > 0) {
            measurement = response.request_output.result;
        }

        if (measurement && measurement?.length > 0) {
            if (measurement[0]['left'] && measurement[0]['left'] !== 'none') {
                left = measurement[0].left;
                leftUnit = measurement[0].unit;

                let mmKeyLeft = {
                    QuestionId: tempExercise.QuestionId,
                    Key: "left",
                    Value: left,
                    ShortCode: tempExercise['LeftShortCode'] ? tempExercise['LeftShortCode'].trim() : "",
                    Unit: leftUnit,
                    Status: response.status,
                    RenderImageUrl: response.request_output['rendered_picture'],
                    Exercise: response.exerciseName,
                    RawVideoURL: response.request_output['raw_video'],
                    RenderedVideoURL: response.request_output['rendered_video'],
                    RawImageUrl: response.request_output['raw_picture']
                };

                mmKeyLeft = new MeasurementResult(mmKeyLeft);
                assessment.Results.push(mmKeyLeft);
                _prepareAnswerValueFromMeasurement(assessment, response.exerciseName, tempExercise.QuestionId, left, mmKeyLeft.Key);
            }

            if (measurement[1]['right'] && measurement[1]['right'] !== 'none') {
                right = measurement[1].right;
                rightUnit = measurement[1]['unit'];

                let mmKeyRight = {
                    QuestionId: tempExercise.QuestionId,
                    Key: "right",
                    Value: right,
                    ShortCode: tempExercise['RightShortCode'] ? tempExercise['RightShortCode'].trim() : "",
                    Unit: rightUnit,
                    Status: response.status,
                    RenderImageUrl: response.request_output['rendered_picture'],
                    Exercise: response.exerciseName,
                    RawVideoURL: response.request_output['raw_video'],
                    RenderedVideoURL: response.request_output['rendered_video'],
                    RawImageUrl: response.request_output['raw_picture']
                };

                mmKeyRight = new MeasurementResult(mmKeyRight);
                assessment.Results.push(mmKeyRight);
                _prepareAnswerValueFromMeasurement(assessment, response.exerciseName, tempExercise.QuestionId, right, mmKeyRight.Key);
            }

            if (measurement[2]['below'] && measurement[2]['below'] !== 'none') {
                below = measurement[2]['below'];
                belowUnit = measurement[2].unit;

                let mmKeyBelow = {
                    QuestionId: tempExercise.QuestionId,
                    Key: "below",
                    Value: below,
                    ShortCode: tempExercise['BelowShortCode'] ? tempExercise['BelowShortCode'].trim() : '',
                    Unit: belowUnit,
                    Status: response.status,
                    RenderImageUrl: response.request_output['rendered_picture'],
                    Exercise: response.exerciseName,
                    RawVideoURL: response.request_output['raw_video'],
                    RenderedVideoURL: response.request_output['rendered_video'],
                    RawImageUrl: response.request_output['raw_picture']
                };

                mmKeyBelow = new MeasurementResult(mmKeyBelow);
                assessment.Results.push(mmKeyBelow);
                _prepareAnswerValueFromMeasurement(assessment, response.exerciseName, tempExercise.QuestionId, below, mmKeyBelow.Key);
            }

            if (measurement[3]['below_2'] && measurement[3]['below_2'] !== 'none') {
                below2 = measurement[3]['below_2'];
                below2Unit = measurement[3]['unit'];

                let mmKeyBelow2 = {
                    QuestionId: tempExercise.QuestionId,
                    Key: "below_2",
                    Value: below2,
                    ShortCode: tempExercise['Below2ShortCode'] ? tempExercise['Below2ShortCode'].trim() : "",
                    Unit: below2Unit,
                    Status: response.status,
                    RenderImageUrl: response.request_output['rendered_picture'],
                    Exercise: response.exerciseName,
                    RawVideoURL: response.request_output['raw_video'],
                    RenderedVideoURL: response.request_output['rendered_video'],
                    RawImageUrl: response.request_output['raw_picture']
                };

                mmKeyBelow2 = new MeasurementResult(mmKeyBelow2);
                assessment.Results.push(mmKeyBelow2);
                _prepareAnswerValueFromMeasurement(assessment, response.exerciseName, tempExercise.QuestionId, below2, mmKeyBelow2.Key);
            }

            if (measurement[4]['below_3'] && measurement[4]['below_3'] !== 'none') {
                below3 = measurement[4]['below_3'];
                below3Unit = measurement[4]['unit'];

                let mmKeyBelow3 = {
                    QuestionId: tempExercise.QuestionId,
                    Key: "below_3",
                    Value: below3,
                    ShortCode: "",
                    Unit: below3Unit,
                    Status: response.status,
                    RenderImageUrl: response.request_output['rendered_picture'],
                    Exercise: response.exerciseName,
                    RawVideoURL: response.request_output['raw_video'],
                    RenderedVideoURL: response.request_output['rendered_video'],
                    RawImageUrl: response.request_output['raw_picture']
                };

                mmKeyBelow3 = new MeasurementResult(mmKeyBelow3);
                assessment.Results.push(mmKeyBelow3);
            }

            if (measurement[5]['below_4'] && measurement[5]['below_4'] !== 'none') {
                below4 = measurement[5]['below_4'];
                below4Unit = measurement[5]['unit'];

                let mmKeyBelow4 = {
                    QuestionId: tempExercise.QuestionId,
                    Key: "below_4",
                    Value: below4,
                    ShortCode: "",
                    Unit: below4Unit,
                    Status: response.status,
                    RenderImageUrl: response.request_output['rendered_picture'],
                    Exercise: response.exerciseName,
                    RawVideoURL: response.request_output['raw_video'],
                    RenderedVideoURL: response.request_output['rendered_video'],
                    RawImageUrl: response.request_output['raw_picture']
                };

                mmKeyBelow4 = new MeasurementResult(mmKeyBelow4);
                assessment.Results.push(mmKeyBelow4);
            }

            if (response.exerciseName === CONSTANT.EXERCISES_NAME.spineAlign) {
                if (measurement[6]['below_5'] && measurement[6]['below_5'] !== 'none') {
                    below5 = measurement[6]['below_5'];
                    below5Unit = measurement[6]['unit'];

                    let mmKeyBelow5 = {
                        QuestionId: tempExercise.QuestionId,
                        Key: "below_5",
                        Value: below5,
                        ShortCode: "",
                        Unit: below5Unit,
                        Status: response.status,
                        RenderImageUrl: response.request_output['rendered_picture'],
                        Exercise: response.exerciseName,
                        RawVideoURL: response.request_output['raw_video'],
                        RenderedVideoURL: response.request_output['rendered_video'],
                        RawImageUrl: response.request_output['raw_picture']
                    };

                    mmKeyBelow5 = new MeasurementResult(mmKeyBelow5);
                    assessment.Results.push(mmKeyBelow5);
                }
                if (measurement[7]['below_6'] && measurement[7]['below_6'] !== 'none') {
                    below6 = measurement[7]['below_6'];
                    below6Unit = measurement[7]['unit'];

                    let mmKeyBelow6 = {
                        QuestionId: tempExercise.QuestionId,
                        Key: "below_6",
                        Value: below6,
                        ShortCode: "",
                        Unit: below6Unit,
                        Status: response.status,
                        RenderImageUrl: response.request_output['rendered_picture'],
                        Exercise: response.exerciseName,
                        RawVideoURL: response.request_output['raw_video'],
                        RenderedVideoURL: response.request_output['rendered_video'],
                        RawImageUrl: response.request_output['raw_picture']
                    };

                    mmKeyBelow6 = new MeasurementResult(mmKeyBelow6);
                    assessment.Results.push(mmKeyBelow6);
                }
                if (measurement[8]['below_7'] && measurement[8]['below_7'] !== 'none') {
                    below7 = measurement[8]['below_7'];
                    below7Unit = measurement[8]['unit'];

                    let mmKeyBelow7 = {
                        QuestionId: tempExercise.QuestionId,
                        Key: "below_7",
                        Value: below7,
                        ShortCode: "",
                        Unit: below7Unit,
                        Status: response.status,
                        RenderImageUrl: response.request_output['rendered_picture'],
                        Exercise: response.exerciseName,
                        RawVideoURL: response.request_output['raw_video'],
                        RenderedVideoURL: response.request_output['rendered_video'],
                        RawImageUrl: response.request_output['raw_picture']
                    };

                    mmKeyBelow7 = new MeasurementResult(mmKeyBelow7);
                    assessment.Results.push(mmKeyBelow7);
                }
                if (measurement[9]['below_8'] && measurement[9]['below_8'] !== 'none') {
                    below8 = measurement[9]['below_8'];
                    below8Unit = measurement[9]['unit'];

                    let mmKeyBelow8 = {
                        QuestionId: tempExercise.QuestionId,
                        Key: "below_8",
                        Value: below8,
                        ShortCode: "",
                        Unit: below8Unit,
                        Status: response.status,
                        RenderImageUrl: response.request_output['rendered_picture'],
                        Exercise: response.exerciseName,
                        RawVideoURL: response.request_output['raw_video'],
                        RenderedVideoURL: response.request_output['rendered_video'],
                        RawImageUrl: response.request_output['raw_picture']
                    };

                    mmKeyBelow8 = new MeasurementResult(mmKeyBelow8);
                    assessment.Results.push(mmKeyBelow8);
                }
                if (measurement[10]['below_9'] && measurement[10]['below_9'] !== 'none') {
                    below9 = measurement[10]['below_9'];
                    below9Unit = measurement[10]['unit'];

                    let mmKeyBelow9 = {
                        QuestionId: tempExercise.QuestionId,
                        Key: "below_9",
                        Value: below9,
                        ShortCode: "",
                        Unit: below9Unit,
                        Status: response.status,
                        RenderImageUrl: response.request_output['rendered_picture'],
                        Exercise: response.exerciseName,
                        RawVideoURL: response.request_output['raw_video'],
                        RenderedVideoURL: response.request_output['rendered_video'],
                        RawImageUrl: response.request_output['raw_picture'],
                    };

                    mmKeyBelow9 = new MeasurementResult(mmKeyBelow9);
                    assessment.Results.push(mmKeyBelow9);
                }
                if (measurement[11]['below_10'] && measurement[11]['below_10'] !== 'none') {
                    below10 = measurement[11]['below_10'];
                    below10Unit = measurement[11]['unit'];

                    let mmKeyBelow10 = {
                        QuestionId: tempExercise.QuestionId,
                        Key: "below_10",
                        Value: below10,
                        ShortCode: "",
                        Unit: below10Unit,
                        Status: response.status,
                        RenderImageUrl: response.request_output['rendered_picture'],
                        Exercise: response.exerciseName,
                        RawVideoURL: response.request_output['raw_video'],
                        RenderedVideoURL: response.request_output['rendered_video'],
                        RawImageUrl: response.request_output['raw_picture']
                    };

                    mmKeyBelow10 = new MeasurementResult(mmKeyBelow10);
                    assessment.Results.push(mmKeyBelow10);
                }
                if (measurement[12]['below_11'] && measurement[12]['below_11'] !== 'none') {
                    below11 = measurement[12]['below_11'];
                    below11Unit = measurement[12]['unit'];

                    let mmKeyBelow11 = {
                        QuestionId: tempExercise.QuestionId,
                        Key: "below_11",
                        Value: below11,
                        ShortCode: "",
                        Unit: below11Unit,
                        Status: response.status,
                        RenderImageUrl: response.request_output['rendered_picture'],
                        Exercise: response.exerciseName,
                        RawVideoURL: response.request_output['raw_video'],
                        RenderedVideoURL: response.request_output['rendered_video'],
                        RawImageUrl: response.request_output['raw_picture']
                    };

                    mmKeyBelow11 = new MeasurementResult(mmKeyBelow11);
                    assessment.Results.push(mmKeyBelow11);
                }
                if (measurement[13]['below_12'] && measurement[13]['below_12'] !== 'none') {
                    below12 = measurement[13]['below_12'];
                    below12Unit = measurement[13]['unit'];

                    let mmKeyBelow12 = {
                        QuestionId: tempExercise.QuestionId,
                        Key: "below_12",
                        Value: below12,
                        ShortCode: "",
                        Unit: below12Unit,
                        Status: response.status,
                        RenderImageUrl: response.request_output['rendered_picture'],
                        Exercise: response.exerciseName,
                        RawVideoURL: response.request_output['raw_video'],
                        RenderedVideoURL: response.request_output['rendered_video'],
                        RawImageUrl: response.request_output['raw_picture']
                    };

                    mmKeyBelow12 = new MeasurementResult(mmKeyBelow12);
                    assessment.Results.push(mmKeyBelow12);
                }
                if (measurement[14]['below_13'] && measurement[14]['below_13'] !== 'none') {
                    below13 = measurement[14]['below_13'];
                    below13Unit = measurement[14]['unit'];

                    let mmKeyBelow13 = {
                        QuestionId: tempExercise.QuestionId,
                        Key: "below_13",
                        Value: below13,
                        ShortCode: "",
                        Unit: below13Unit,
                        Status: response.status,
                        RenderImageUrl: response.request_output['rendered_picture'],
                        Exercise: response.exerciseName,
                        RawVideoURL: response.request_output['raw_video'],
                        RenderedVideoURL: response.request_output['rendered_video'],
                        RawImageUrl: response.request_output['raw_picture']
                    };

                    mmKeyBelow13 = new MeasurementResult(mmKeyBelow13);
                    assessment.Results.push(mmKeyBelow13);
                }
            }
        } else {
            let result = {
                IsUnableToPerformedExercise: false,
                Exercise: response.exerciseName
            };
            result = new MeasurementResult(result);
            assessment.Results.push(result);
        }
    }
    catch(ex){

    }
};

const _prepareAnswerValueFromMeasurement = (assessment, exercise, questionId, answerValue, direction) => {
    let storeQuestionId = questionId;
    if (exercise === CONSTANT.EXERCISES_NAME.tipToFloorStandStill) {
        if (direction === CONSTANT.MEASUREMENT_KEY.LEFT) {
            storeQuestionId = 342;
        } else if (direction === CONSTANT.MEASUREMENT_KEY.RIGHT) {
            storeQuestionId = 343;
        }
    } else if (exercise === CONSTANT.EXERCISES_NAME.tipToFloor) {
        if (direction === CONSTANT.MEASUREMENT_KEY.LEFT) {
            storeQuestionId = 344;
        } else if (direction === CONSTANT.MEASUREMENT_KEY.RIGHT) {
            storeQuestionId = 345;
        }
    } else if (exercise === CONSTANT.EXERCISES_NAME.handLegBendLeftStandStill) {
        if (direction === CONSTANT.MEASUREMENT_KEY.LEFT) {
            storeQuestionId = 348;
        }
    } else if (exercise === CONSTANT.EXERCISES_NAME.handLegBendLeft) {
        if (direction === CONSTANT.MEASUREMENT_KEY.LEFT) {
            storeQuestionId = 350;
        }
    } else if (exercise === CONSTANT.EXERCISES_NAME.handLegBendRightStandStill) {
        if (direction === CONSTANT.MEASUREMENT_KEY.RIGHT) {
            storeQuestionId = 349;
        }
    } else if (exercise === CONSTANT.EXERCISES_NAME.handLegBendRight) {
        if (direction === CONSTANT.MEASUREMENT_KEY.RIGHT) {
            storeQuestionId = 351;
        }
    } else if (exercise === CONSTANT.EXERCISES_NAME.postureAnalysis) {
        if (direction === CONSTANT.MEASUREMENT_KEY.LEFT) {
            storeQuestionId = 10000637;
        } else if (direction === CONSTANT.MEASUREMENT_KEY.RIGHT) {
            storeQuestionId = 10000638;
        }
    } else {
        return;
    }

    const questionsInfo = {};
    questionsInfo.QuestionId = storeQuestionId;
    questionsInfo.AnswerValue = answerValue;
    assessment.Answers.push(questionsInfo);
};



const prepareAssessments = (tempQueuedExercises, measuredExercises, exercises, providerEmail, testId, bodyRegionList, shoulderToShoulderDistance) => {
    return new Promise((resolve, reject) => {
        let assessment = getNewAssessment(providerEmail,testId,bodyRegionList);
        tempQueuedExercises.forEach((exercise) => {
            if (exercise.status === CONSTANT.EXERCISE_STATUS.COMPLETED.key
                || exercise.status === CONSTANT.EXERCISE_STATUS.RETAKE_HUMAN_EXPERT.key) {

                if (!exercise.request_output) return;

                // CALIBRATION
                if (exercise.exerciseName === CONSTANT.EXERCISES_NAME.calibration) {
                    let reqOutputResult = exercise.request_output.result;
                    if (!reqOutputResult) return;

                    if (reqOutputResult.length > 0 && reqOutputResult[0].left) {
                        assessment.shoulderToShoulderDistance = reqOutputResult[0].left;
                    }

                    if (reqOutputResult.length > 1 && reqOutputResult[1].right) {
                        assessment.shoulderToElbowDistance = reqOutputResult[1].right;
                    }

                    if (reqOutputResult.length > 2 &&  reqOutputResult[2]['below']) {
                        assessment.elbowToWristDistance = reqOutputResult[2]['below'];
                    }
                    // if(shoulderToShoulderDistance !== assessment.shoulderToShoulderDistance){
                    //     updateUserInfoByCalibrationResult(assessment);
                    // }
                    updateUserInfoByCalibrationResult(assessment);
                    return;
                }

                let tempExercise = exercises.find(item => item.ExerciseCodeName === exercise.exerciseName);

                if (tempExercise) {
                    _prepareMeasurementResults(assessment, exercise, tempExercise);

                    // POSTURE ANALYSIS
                    if (exercise.exerciseName === CONSTANT.EXERCISES_NAME.postureAnalysis) {
                        const forwardHead = assessment.Results.find((item) =>
                            item.Exercise === CONSTANT.EXERCISES_NAME.postureAnalysis && item.Key === CONSTANT.MEASUREMENT_KEY.LEFT);
                        const rolledShoulder = assessment.Results.find((item) =>
                            item.Exercise === CONSTANT.EXERCISES_NAME.postureAnalysis && item.Key === CONSTANT.MEASUREMENT_KEY.RIGHT);

                        if (forwardHead) {
                            forwardHead.QuestionId = 305;
                            forwardHead.Exercise = 'forwardHead';
                        }

                        if (rolledShoulder) {
                            rolledShoulder.QuestionId = 307;
                            rolledShoulder.Exercise = 'rolledShoulder';
                            rolledShoulder.Key = 'left';
                        }
                    }
                }

            }
        });
        resolve(assessment);
    });
};

const saveAssessments = async (payload) => {
    try {
        if(payload.InvitationId === "") { payload.InvitationId = null };
        const response = await axios.post(ASSESSMENT_SAVE_URL, payload, RESPONSE_TYPE);

        return success(response)?.success;
    } catch (e) {
        error(e);
        return false;
    }
}

export const savePainQuestions = async (painQuestionList, providerEmail, testId, bodyRegionList) => {
    if(painQuestionList && painQuestionList.length > 0){
        let assessment = getNewAssessment(providerEmail,testId,bodyRegionList);

        for(let painQuestion of painQuestionList){
            let answer = {};
            answer.AnswerId = painQuestion.AnswerId;
            answer.QuestionId = painQuestion.QuestionId;
            answer.AnswerList = painQuestion.AnswerList;

            assessment.Answers.push(answer);
        }
        let response = await saveAssessments(assessment);
        return response;
    }
    return true;
}

//TODO: After new ROM API integration will remove this line as shouldertoshoulder distance will be no longer required to send from client side
export const saveCompletedAssessments = async (tempQueuedExercises, measuredExercises, exercises, patientId,providerEmail,testId,bodyRegionList,shoulderToShoulderDistance, doServerSideCalculation = true) => {
    let assessments = await prepareAssessments(tempQueuedExercises, measuredExercises, exercises,providerEmail,testId,bodyRegionList,shoulderToShoulderDistance);
    // if(assessments && doServerSideCalculation){
    //     assessments.DoServerSideCalculation = doServerSideCalculation;
    // }
    // let payload = {
    //     PatientId: patientId,//VA PORTAL API
    //     TESTID: testId,
    //     DoServerSideCalculation: true
    // }
    // let response = await saveSelfData(payload);
    // let data = {
    //     isSuccess: response,
    //     shoulderToShoulderDistance: assessments && assessments.shoulderToShoulderDistance || 0
    // }
    let data = {
        isSuccess: true,
        shoulderToShoulderDistance: assessments && assessments.shoulderToShoulderDistance || 0
    }
    return data;
}

export const updateUserData = async (userPayload) => {
    if(userPayload && userPayload !== {}){
        try {
            const response = await axios.post(UPDATE_USER_URL, userPayload, RESPONSE_TYPE);
        } catch (e) {
            console.error("Exception issue in patient update");
            error(e);
        }
    }
    return;
}

export const updateUserInfoByCalibrationResult = async (payload) => {

    if(payload && payload !== {}){
        const userPayload = {
            ShoulderToShoulderDistance: payload['shoulderToShoulderDistance'],
            ShoulderToElbowDistance: payload['shoulderToElbowDistance'],
            ElbowToWristDistance: payload['elbowToWristDistance']
        };

        await updateUserData(userPayload);
    }
    return;
};

const makeMeasurementResult = (ex, assessments, status) => {
    if(ex){
        // const primaryPainQuestion = ex['RelatedExercises']?.find((item) => item?.['IsPrimaryPainQuestion'])?.Question;
        const primaryPainQuestion = ex['RelatedExercises']?.find((item) => item?.['IsPrimaryPainQuestion'])?.Question;
        for(let i = 0; i < ex?.PainQuestions?.length; i++){
            let painQuestion = ex['PainQuestions'][i].QuestionTitle;

            if (painQuestion) {
                const substr = /yes/
                const answer = painQuestion['Responses']?.find((item) => substr.test(item?.['AnswerTitle']?.toLowerCase()));
                if (answer) {
                    assessments.Answers.push({
                        QuestionId: painQuestion.QuestionId,
                        AnswerId: answer.AnswerId
                    });
                }
            }
        }
        if (primaryPainQuestion) {
            const substr = /yes/
            const answer = primaryPainQuestion['Responses']?.find((item) => substr.test(item?.['AnswerTitle']?.toLowerCase()));
            if (answer) {
                assessments.Answers.push({
                    QuestionId: primaryPainQuestion.QuestionId,
                    AnswerId: answer.AnswerId
                });
            }
        }
        let measurementResult = new MeasurementResult({
            IsUnableToPerformedExercise: status,
            IsSkipExercise: status,
            Exercise: ex.ExerciseCodeName,
            RenderImageUrl: DEFAULT_RENDER_IMAGE_URL,
            ShortCode: ex['LeftShortCode'] || ex['RightShortCode'] || null,
            Status: CONSTANT.EXERCISE_STATUS.SKIPPED.key,
        });
        assessments.Results.push(measurementResult);
    }
}

export const skipMovement = async (exercsieList, exercise,providerEmail,testId) => {
    if(exercise && exercsieList && exercsieList.length > 0){
        // let assessments = getNewAssessment(patientId,providerEmail,testId,bodyRegionList);
        // let ex = exercsieList.find(x => x.ExerciseCodeName === exercise.ExerciseCodeName);
        // makeMeasurementResult(ex, assessments, true);
        // if(exercise.MergeExercises){
        //     // let exList = exercise.MergeExercises.split(',');
        //     let exList = exercise.MergeExercises;
        //     if(exList && exList.length > 0){
        //         for(let i = 0; i < exList.length; i++){
        //             ex = exercsieList.find(x => x.ExerciseCodeName === exList[i]);
        //             makeMeasurementResult(ex, assessments, true);
        //         }
        //     }
        // }
        // let response = await saveAssessments(assessments);
        // return response;
        let payload = {
            TestId: testId,
            ExerciseValue: exercise?.ExerciseCodeName,
            IsUnableToPerformedExercise: true
        };
        let response = await ExamService.skipAMovement(payload);
        return response;
    }
    return true;
}

export const resetSkipMovements = async (exercsieList,providerEmail,testId,bodyRegionList) => {
    if(exercsieList && exercsieList.length > 0){
        let assessments = getNewAssessment(providerEmail,testId,bodyRegionList);
        // let skipList = exercsieList.filter(x => x["IsUnableToPerformedExercise"]);
        let skipList = exercsieList.filter(x => x["IsSkipExercise"]);
        if(skipList && skipList.length > 0){
            for(let i = 0; i < skipList.length; i++){
                makeMeasurementResult(skipList[i], assessments, false);
            }
            let response = await saveAssessments(assessments);
            return response;
        }
    }
    return true;
}

export const getTelemedSessionId = async (invitationId) => {

    const payload = {
        InvitationId: invitationId
    };

    try {
        const response = await axios.post(TELEMED_SESSION_KEY_URL, payload, RESPONSE_TYPE);

        if(response){
            // return {
            //     isSuccess: response['Successful'],
            //     message: JSON.parse(response['Message'])
            // }

            if(response && response?.StatusMessage === 'Success'){
                // return {
                //     isSuccess: response['Successful'],
                //     message: JSON.parse(response['Message'])
                // }
                return success(response);
            }
        }
        else{
            return {
                success: false,
                error: "No response found"
            }
        }
    } catch (e) {
        error(e);
        return {
            success: false,
            error: "Request failed"
        };
    }
}

export const getAllExerciseGuideline = async () => {
    const payload = {

    };

    try {
        const response = await axios.post(EXERCISE_GUIDELINE_ALL, payload, RESPONSE_TYPE);

        // if(response && response?.StatusMessage === "Success"){
            // return {
            //     isSuccess: response['Successful'],
            //     message: JSON.parse(response['Message'])
            // }
            return success(response);
        // }
        // else{
        //     return {
        //         success: false,
        //         error: "No response found"
        //     }
        // }
    } catch (e) {
        // return {
        //     success: false,
        //     error: "Request failed"
        // };
        return error(e);
    }
}
