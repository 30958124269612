import { ChatbotButton } from "./ButtonComponent";
import React from "react";

const ShowSkipped = ({ chat }) => (
    <>
        {chat.skipped && chat.answers && chat.answers.length === 0 && (
            <ChatbotButton
                type="button"
                fill={'true'}
                className={"btn btn-pill-5 btn-lg cursor-hand"}>
                <div className="d-flex">
                    <span className="m-auto">Skipped</span>
                </div>
            </ChatbotButton>
        )}
    </>
);

export default ShowSkipped;