const JWT = {
    HEADER_NAME: 'Authorization',
    TOKEN_KEY: 'token',
    HEADER_PREFIX: 'Bearer'
};

const REFRESH_TOKEN = {
    KEY_NAME: 'refreshToken',
}

const CC_QUESTION = Object.freeze({
    ID: 10000870 // bot cc question id yes, chief complaint and no, chief complaint
});

const KEYBOARD_KEYS = Object.freeze({
    ENTER: 13,
    SPACE: 32,
    ESC: 27,
    ARROW_UP: 38,
    ARROW_DOWN: 40,
    ARROW_LEFT: 37,
    ARROW_RIGHT: 39
});

const MAX_TIMEOUT_TIME = 2147483647;
const HTML_REGEX = /<\/?[A-Za-z\s]+(\/)?>?/;

const PAIN_BOT_INFO = Object.freeze({
    id: "0",
    name: "EMMA",
    subName: "Pain Recorder",
    statusName: ""
});

const MSK_BOT_INFO = Object.freeze({
    id: "1",
    name: "EMMA",
    // subName: "Fysical Score™ Screening",
    subName: "ATLAS Score Screening",
    statusName: ""
});

const POSTURE_BOT_INFO = Object.freeze({
    id: "1",
    name: "POSTURE",
    subName: "Postural Screening",
    statusName: ""
});

const INTAKE_BOT_INFO = Object.freeze({
    id: "4",
    name: "Intake Assistant",
    subName: "Intake Assistant",
    statusName: ""
});

const APPOINTMENT_BOT_INFO = Object.freeze({
    id: "2",
    name: "EMMA",
    subName: "Appointment",
    statusName: ""
});

const PRECALL_TEST_BOT_INFO = Object.freeze({
    id: "3",
    name: "EMMA",
    subName: "Precall Test Screening",
    statusName: ""
});

const CHATBOT_INTENT = Object.freeze({
    WELCOME: "welcome",
    INITIAL: "initial",
    END: "bye",
    LAST: "last_question",
    TERMS_CONDITIONS: "terms_conditions",
    MAKE_APPOINTMENT: "make_appointment",
    PASSWORD: "password",
    CONFIRM_PASSWORD: "confirm_password",
    PROVIDER: "provider",
    NOTES: "notes",
    EMAIL_BODY: "email_body",
    ASSESSMENT_REPORT: "assessment_report",
    DATETIME_INPUT_MASK: "datetime_input_mask",
    BODY_REGION: "body_region",
    COUNTRY_INDICATION: "country_indication",
    CLICK_AUTOMATIC: "click_automatic",
    MOVEMENT: 'movement',
    CHIEF_COMPLAINTS: 'chief_complaint',
    OUTCOME_ASSESSMENT: 'outcome'
});

const CHATBOT_ACCOUNT = Object.freeze({
    FIRST_NAME: 1,
    LAST_NAME: 2,
    EMAIL: 401,
    PASSWORD: 2202,
    CONFIRM_PASSWORD: 2208,
    NOTES: 10005,
    HEIGHT: 4,
    WEIGHT: 5,
    DATE_OF_BIRTH: 6,
    DOMINANT_HAND: 14,
    PROFILE_PIC: 2205,
    GENDER: 8,
    UPDATED_DEMOGRAPHIC: 2211,
    SOCIAL_SECURITY_NUMBER: 21,
    OUTCOME_BODY_REGION_SELECTION: 10000816,
    ACCIDENT_DIRECT_TRAUMA: 10000585,
    HEIGHT_IN_FEET: 'heightInFeet',//at register page
    HEIGHT_IN_INCH: 'heightInInch',//at register page
    GENDER_AS_SELECT: 'genderAsSelect',//at register page
    DOMINANT_HAND_AS_SELECT: 'dominantHandAsSelect',//at register page
    TERMS_AND_CONDITIONS: 'termsAndConditions',//at register page
});

const PAIN_OPTIONS = Object.freeze({
    LABELS: 10002,
    TYPES: 10006,
    TRIGGERS: 11005,
    SYMPTOMS: 12005
});

const BOT_QUESTION_ID = Object.freeze({
    LOOKING_FOR: 2001,
    PROVIDER: 2002,
    EMAIL_BODY: 2003,
    START_DATE: 2005,
    BODY_REGION: 10000012,
    BIOMETRIC_CAUTION3: 404
});

const ACTIVITY_LEVEL_COLOR = Object.freeze({
    COLOR_0: "rgb(158, 220, 120)",
    COLOR_1: "rgb(118, 211, 130)",
    COLOR_2: "rgb(99, 206, 106)",
    COLOR_3: "rgb(255, 174, 62)",
    COLOR_4: "rgb(247, 185, 99)",
    COLOR_5: "rgb(243, 64, 46)",
});

const PAIN_SEVERITY_COLOR = Object.freeze({
    COLOR_0: "rgb(158, 220, 120)",
    COLOR_1: "rgb(118, 211, 130)",
    COLOR_2: "rgb(99, 206, 106)",
    COLOR_3: "rgb(98, 189, 110)",
    COLOR_4: "rgb(247, 185, 99)",
    COLOR_5: "rgb(254, 183, 84)",
    COLOR_6: "rgb(246, 173, 60)",
    COLOR_7: "rgb(252, 151, 9)",
    COLOR_8: "rgb(236, 105, 92)",
    COLOR_9: "rgb(247, 88, 70)",
    COLOR_10: "rgb(243, 64, 46)"
});

const ELEMENT_TYPES = Object.freeze({
    LABEL: "LABEL",
    TEXT: "TEXT",
    BUTTON: "BUTTON",
    CHECKBOX: "CHECKBOX",
    SELECT: "SELECT",
    AUTOCOMPLETE: "AUTOCOMPLETE",
    DROPDOWN: "DROPDOWN",
    BODYPART: "BODYPART",
    MOVEMENT_MODAL: "MODAL",
    OUTCOME_ASSESSMENT_MODAL: "OUTCOME_ASSESSMENT_MODAL",
    PROFILE_PIC: "PROFILE_PIC",
    DATE_PICKER: "DATE_PICKER",
    DATETIME_PICKER: "DATETIME_PICKER",
    ALLOW_MICROPHONE_AND_WEBCAM: "ALLOW_MICROPHONE_AND_WEBCAM",
    MICROPHONE: "MICROPHONE",
    WEBCAM: "WEBCAM",
    CALCULATION: "CALCULATION",
    NUMBER: "NUMBER",
    MODAL: "MODAL",
    MULTISELECT: "MULTISELECT"
});

const EXERCISE_STATUS = Object.freeze({
    NOT_PERFORMED: { name: 'Not Performed', key: 'notPerformed' },
    PERFORMING: { name: 'Performing', key: 'Performing' },
    PROCESSING: { name: 'Processing', key: 'processing' },
    AVAILABLE: { name: 'Available', key: 'available' },
    COMPLETED: { name: 'Completed', key: 'completed' },
    RETAKE: { name: 'Retake', key: 'retake' },
    RETAKE_HUMAN_EXPERT: { name: 'Review required', key: 'human-expert' },
    INVALID_EXERCISE: { name: 'Invalid Exercise', key: 'invalid' },
    WAITING: { name: 'Waiting', key: 'waiting' },
    SAVED: { name: 'Saved', key: 'saved' },
    SKIPPED: { name: 'Skipped', key: 'skipped' }
});

const EXERCISES_NAME = Object.freeze({
    calibration: 'calibration',
    postureAnalysis: 'postureAnalysis',
    lookCeiling: 'lookCeiling',
    chinToChest: 'chinToChest',
    reachArmsOverHead: 'reachArmsOverHead',
    makeMuscle: 'makeMuscle',
    rightHandToShoulder: 'rightHandToShoulder',
    rightShoulderBlade: 'rightShoulderBlade',
    leftHandToShoulder: 'leftHandToShoulder',
    leftShoulderBlade: 'leftShoulderBlade',
    prayHands: 'prayHands',
    headToTopBend: 'headToTopBend',
    kneeSquat: 'kneeSquat',
    spineAlign: 'spineAlign',
    lumbarLeftLateralFlexion: 'lumbarLeftLateralFlexion',
    lumbarRightLateralFlexion: 'lumbarRightLateralFlexion',
    handLegBendLeft: 'handLegBendLeft',
    handLegBendRight: 'handLegBendRight',
    handsTouchBackLeftUp: 'handsTouchBackLeftUp',
    handsTouchBackRightUp: 'handsTouchBackRightUp',
    invertedPrayHands: 'invertedPrayHands',
    rotatePalms: 'rotatePalms',
    sittingBothLegRaises: 'sittingBothLegRaises',
    sittingLegRaisesOneEach: 'sittingLegRaisesOneEach',
    slideDownHandsOnBack: 'slideDownHandsOnBack',
    sittingBothLegRaisesCough: 'sittingBothLegRaisesCough',
    sittingLookingDownBothLegRaises: 'sittingLookingDownBothLegRaises',
    slideUpLeftHand: 'slideUpLeftHand',
    slideUpRightHand: 'slideUpRightHand',
    tapHand: 'tapHand',
    tiltHeadLeft: 'tiltHeadLeft',
    tiltHeadRight: 'tiltHeadRight',
    tipToFloor: 'tipToFloor',
    turnHeadLeft: 'turnHeadLeft',
    turnHeadRight: 'turnHeadRight',
    floorLegsRaises: 'floorLegsRaises',
    kneesToChest: 'kneesToChest',
    tipToFloorStandStill: 'tipToFloorStandStill',
    handLegBendLeftStandStill: 'handLegBendLeftStandStill',
    handLegBendRightStandStill: 'handLegBendRightStandStill',
    shoulderAbductionLeft: 'shoulderAbductionLeft',
    shoulderAbductionRight: 'shoulderAbductionRight',
    shoulderAdductionLeft: 'shoulderAdductionLeft',
    shoulderAdductionRight: 'shoulderAdductionRight',
    shoulderFlexionLeft: 'shoulderFlexionLeft',
    shoulderFlexionRight: 'shoulderFlexionRight',
    shoulderExtensionLeft: 'shoulderExtensionLeft',
    shoulderExtensionRight: 'shoulderExtensionRight',
    elbowFlexionLeft: 'elbowFlexionLeft',
    elbowFlexionRight: 'elbowFlexionRight',
    elbowExtension: 'elbowExtension',
    backFlexion: 'backFlexion',
    shoulderInternalRotationLeft: 'shoulderInternalRotationLeft',
    shoulderInternalRotationRight: 'shoulderInternalRotationRight',
    shoulderExternalRotationLeft: 'shoulderExternalRotationLeft',
    shoulderExternalRotationRight: 'shoulderExternalRotationRight',
    hipExtensionRight: 'hipExtensionRight',
    hipExtensionLeft: 'hipExtensionLeft',
    hipAbductionRight: 'hipAbductionRight',
    hipAbductionLeft: 'hipAbductionLeft',
    hipAdductionRight: 'hipAdductionRight',
    hipAdductionLeft: 'hipAdductionLeft',
    hipExternalRotationRight: 'hipExternalRotationRight',
    hipExternalRotationLeft: 'hipExternalRotationLeft',
    hipInternalRotationRight: 'hipInternalRotationRight',
    hipInternalRotationLeft: 'hipInternalRotationLeft'
});

const LANGUAGES = Object.freeze({
    ENGLISH: { id: 'en', title: 'English', flag: 'us', value: 'en' },
    SPANISH: { id: 'es', title: 'Spanish', flag: 'es', value: 'es' },
    GERMAN: { id: 'de', title: 'German', flag: 'de', value: 'de-DE' },
    FRENCH: { id: 'fr', title: 'French', flag: 'fr', value: '' },
    ITALIAN: { id: 'it', title: 'Italian', flag: 'it', value: '' }
});

const DATE_FORMAT = Object.freeze({
    DATE_MONTH_YEAR: 'dd-MM-yyyy',
    DD_MM_YY: 'dd/MM/yyyy',
    MMM_DD_YYYY: 'MMM/dd/yyyy',
    MM_DD_YYYY: 'MM/dd/yyyy',
    MM_DD_YYYY_HH_MM: "MM/dd/yyyy hh:mm a",
    HH_MM: 'HH:mm',
    HH_MM_P_A: 'p',
    YYYY_MM_DD: 'yyyy-MM-dd'
});

const ASSESSMENT_GROUP = Object.freeze({
    DEMOGRAPHICS: { name: 'Demographics', key: 1, viewOrder: 1, shortName: 'D', midName: 'Dem' },
    ADL: { name: 'ADL', key: 3, viewOrder: 2, shortName: 'A', midName: 'ADL' },
    MEDICAL_HISTORY: { name: 'Medical History', key: 2, viewOrder: 3, shortName: 'M', midName: 'Med' },
    CHIEF_COMPLAINTS: { name: 'Chief Complaints', key: 4, viewOrder: 4, shortName: 'C', midName: 'CC' },
    OUTCOME_ASSESSMENT: { name: 'Oswestry', key: 8, viewOrder: 5, shortName: 'O', midName: 'Osw' },
    BIOMETRIC: { name: 'Biometric', key: 5, viewOrder: 6, shortName: 'B', midName: 'Bio' },
});

const ROLE = Object.freeze({
    PATIENT: "Patient",
    PROVIDER: "Doctor",
    REFERRING_PROVIDER: "ReferringProvider",
});

const CHAT_BOTS = {
    LEAD_GENERATOR_BOT: 'LEAD_GENERATOR_BOT',
    PAIN_BOT: 'PAIN_BOT',
    MSK_BOT: 'MSK_BOT',
    POSTURE_BOT: 'POSTURE_BOT',
    APPOINTMENT_BOT: 'APPOINTMENT_BOT',
    PRECALL_TEST_BOT: 'PRECALL_TEST_BOT',
    INTAKE_BOT: 'INTAKE_BOT',
    POSTURE_ANALYSIS: 'POSTURE_ANALYSIS',
    EDIT_ASSESSMENT: 'EDIT_ASSESSMENT'
};

const BODY_REGIONS = {
    ALL: { name: 'All', value: 'All', key: null },
    NECK: { name: 'Neck', value: 'Neck', key: 1 },
    ARMS: { name: 'Arms', value: 'Upper Extremity', key: 2 },
    BACK: { name: 'Back', value: 'Back', key: 3 },
    LEGS: { name: 'Legs', value: 'Lower Extremity', key: 4 },
    POSTURE: { name: 'Posture', value: 'Posture', key: 5 },
};

const DEFAULT_LANGUAGE = LANGUAGES.ENGLISH.value;
const FACE_DETECT_INTERVAL_TIME = 10000;

const TAB = {
    MOVEMENT_GUIDELINE: 'movementGuideline',
    PERFORMED_MOVEMENT: 'performedMovement'
};

const VIDEO_TYPE = {
    VIDEO_WEBM: 'video/webm',
    VIDEO_MP4: 'video/mp4;'
};

const PROVIDER_EMAIL = "emma@mymedicalhub.com";

const WEBCAM_FACING_MODE = {
    USER: "user",
    ENVIRONMENT: "environment"
};

const PAGE_NAME = {
    DASHBOARD: "Dashboard",
    MY_ASSESSMENTS: "My Assessments",
    MY_PROFILE: "My Profile",
    ASSESSMENT: "Assessment",
    CHANGE_PASSWORD: "Change Password",
    MY_TELEMEDICINE_INVITATION: "My Telemedicine Invitations",
    MY_EXERCISES: "My Exercises",
    MY_CALENDAR: "My Calendar",
    MY_SUBSCRIPTION: "My Subscriptions",
    MY_REPORTS: "My Reports",
    MY_INTAKE: "My Intake Form",
    MY_DOCUMENTS: "My Documents",
    MY_PAINLOGS: "My Painlogs",
    MY_INSIGHT: "My Insights",
    NEW_SCREENINGS: "New Screenings",
    MY_MEDICAL_HISTORY: "My Medical History",
    EDIT_ASSESSMENT: "Edit Assessment",
    MY_STATISTICS: "My Statistics",
    MY_CARDS: "My Cards",
};

const MEDIA_DEVICES_KIND = {
    VIDEO_INPUT: "videoinput",
    AUDIO_INPUT: "audioinput",
    AUDIO_OUTPUT: "audiooutput"
};

const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

const VOICE_COMMAND = Object.freeze({
    NO: { name: "No", key: "no" },
    YES: { name: "Yes", key: "yes" }
})

const QUESTION_GROUP_NAME = Object.freeze({
    LOSS_OF_ENJOYMENT: { key: 'LossOfEnjoyment', value: 'Loss of Enjoyment', id: 9 },
    DUTIES: { key: 'Duties', value: 'Duties', id: 10 },
    OUTCOME: { key: 'outcome', value: 'Outcome Assessment', id: 8 }
});

const FEATURE_ENABLE = {
    EmrEnable: false,
    AssessmentEnable: false,
    Assessment: false,
    Journal: false,
    EMR: false,
    MappingEnable: false,
    Mapping: false,
    Training: false,
    SymptomCheckerEnable: false,
    SymptomChecker: false,
    ProductOrderEnable: false,
    ProductOrder: false,
    TelemedicineAvailable: false,
    Appointment: false,
    IsIntakeEnable: false,
    AIChatBotEnabled: false,
    IsChatEnabled: false,
    AutoIntake: false,
    EnableFysicalScore: false,
    RegisterWithEMMA: false,
    IsPrecallTestBotEnabled: false,
    IsGaitAnalyzeEnabled: false,
    IsPostureAnalyzeEnabled: false,
    IsBalanceAnalyzeEnabled: false,
    IsWeightOrStrengthAnalyzeBotEnabled: false,
    IsAppointmentWithEMMAEnabled: false,
    IsIntakeWithEMMAEnabled: false,
    IsNoFaultEnabled: false,
    InterventionModule: false,
    ECommerceModule: false,
    CorporationAvailable: false,
    IsInClinicEnabled: false,
    IsPublicRegistrationAvailable: false,
    GaitMeasureWithEMMA: false,
    IsCareNavigationEnable: false,
    IsECommerceEnable: false,
    IsPreventiveCareEnable: false,
    SelfAssessmenInclinicMode: false,
    InvitationOnlyAssessment: false,
    IsPatientReportAvailable: false,
    OutcomeAssessmentEnabled: false,
    CanPatientCreateAssessment: false,
};

const PORTAL = {
    EMMA: { name: 'EMMA', value: 'emma' },
    TELEMED: { name: 'TELEMED', value: 'telemed' },
    DEV: { name: 'DEV', value: 'dev' },
    DAP: { name: 'DAP', value: 'dap' },
    PPT: { name: 'PPT', value: 'ppt' }
};

const HEIGHT = {
    FEET: 'feet',
    INCH: 'inch'
}

const REDIRECT_TYPE = {
    TELEMEDICINE: 'telemedicine',
    REFER: 'refer',
    INCLINIC: 'inclinic',
    INCLINIC_ENCOUNTER: 'inclinicencounter',
    FOLLOWUP: 'followup',
    SELF: 'self'
};

const INVITATION_TYPE = {
    None: 0,
    Self: 1,
    PainLog: 2,
    Telemedicine: 3,
    InClinic: 4
};

const VIDEO_CONSTRAINTS = {
    facingMode: "user",
    deviceId: "",
    frameRate: { max: 30 },
    height: 720,
    width: 1280
};

const VIDEO_CONSTRAINTS_NEW = {
    facingMode: "user",
    deviceId: "",
    frameRate: { max: 30 }
}

const REFERENCE_RESPONSE = Object.freeze({
    YES: 1,
    NO: 2
});

const URLS = Object.freeze({
    BASE_URL: "injurycloud.com"
});

const MOVEMENT_TYPES = Object.freeze({
    BALANCE: 1,
    GAIT: 2,
    STRENGTH: 3,
    ROM: 4,
    SPECIAL_TEST: 5,
});

const MEASUREMENT_KEY = {
    LEFT: 'left',
    RIGHT: 'right'
};

const STATUS_CODE = {
    CODE_200: 200
};

const POSE_POSITION = {
    nose: { label: 'Nose', value: 'nose' },
    left_eye: { label: 'Left Eye', value: 'left_eye' },
    right_eye: { label: 'Right Eye', value: 'right_eye' },
    left_knee: { label: 'Left Knee', value: 'left_knee' },
    right_knee: { label: 'Right Knee', value: 'right_knee' },
    left_ankle: { label: 'Left Ankle', value: 'left_ankle' },
    right_ankle: { label: 'Right Ankle', value: 'right_ankle' },
}

const GENERAL_AUDIOS_KEY_TEXT = {
    OPENING_VOICE: "OpeningVoice",
    GREETING_MESSAGE: "GREETING_MESSAGEG",
    WELCOME_TEXT: "WELCOME_TEXT",
    SELECT_EXERCISE: "SELECT_EXERCISE",
    START_MOVEMENT_CONFIRMATION: "START_MOVEMENT_CONFIRMATION",
    START_INSTRUCTION: "START_INSTRUCTION",
    SELECT_ANOTHER_EXERCISE: "SELECT_ANOTHER_EXERCISE",
    RETAKE_EXERCISE: "RETAKE_EXERCISE",
    FULL_BODY_NEGATIVE: "FULL_BODY_NEGATIVE",
    FACE_NEGATIVE: "FACE_NEGATIVE",
    COMPLETED_CONTINUE_OR_TERMINATE: "COMPLETED_CONTINUE_OR_TERMIATE",
    ASK_FOR_EXERCISE_DO: "ASK_FOR_EXERCISE_DO",
    WELCOME_TEXT_FOR_MOBILE: "WELCOME_TEXT_FOR_MOBILE",
    ASK_FOR_EXERCISE_DO_MOBILE: "ASK_FOR_EXERCISE_DO_MOBILE",
    SELECT_ANOTHER_EXERCISE_MOBILE: "SELECT_ANOTHER_EXERCISE_MOBILE",
    CLOSE_MODAL: "CLOSE_MODAL",
    SUBMIT_PAIN_QUESTION_ANSWER: "SUBMIT_PAIN_QUESTION_ANSWER",
    READY_INSTRUCTION: "READY_INSTRUCTION",
    EMMA_WELCOME_TEST: "EMMA_WELCOME_TEST",
    CALIBRATION_COMPLETED: "CALIBRATION_COMPLETED",
    ALL_MOVEMENTS_COMPLETED: "ALL_MOVEMENTS_COMPLETED",
    BEEP_START: "BEEP_START",
    BEEP_END: "BEEP_END",
    SAY_YES_OR_NO: "SAY_YES_OR_NO",
    POSE_INSIDE_BOX: "POSE_INSIDE_BOX",
    STARTING_MOVEMENT: "startingMovement",
    ENDING_MOVEMENT: "endingMovement",
    ANSWER_PAIN_QUESTION: "PainQuestionAudio",
    WAIT_UNTIL_CALIBRATION_FINISHED: "CalibratingHeight",
    PLEASE_WAIT_UNTIL_MOVEMENT_COMPLETE: "PleaseWaitMovement",
    ALL_MOVEMENT_COMPLETED: 'all_movement_completed',
    PLEASE_RETAKE_BIOMETRICS: 'PleaseRetakeBiometrics',
    STEP_OUT_OF_BOX: "StepOut",
    POSITION_IN_THE_BOX: "PositionBox",
    TAKE_BRAKE: "BreakPause",
    FACE_LEFT_WHILE_PERFORMING_EXERCISE: "FaceLeftWhilePerfomingExercise",
    FACE_RIGHT_WHILE_PERFORMING_EXERCISE: "FaceRightWhilePerfomingExercise"
};

const TIME_ZONES = [
    {
        "id": "",
        "name": "-- Select Timezone --"
    },
    {
        "id": "Dateline Standard Time",
        "name": "(UTC-12:00) International Date Line West"
    },
    {
        "id": "Aleutian Standard Time",
        "name": "(UTC-10:00) Aleutian Islands"
    },
    {
        "id": "Hawaiian Standard Time",
        "name": "(UTC-10:00)  Hawaii"
    },
    {
        "id": "Marquesas Standard Time",
        "name": "(UTC-09:30) Marquesas Islands"
    },
    {
        "id": "Alaskan Standard Time",
        "name": "(UTC-09:00) Alaska"
    },
    {
        "id": "UTC-09",
        "name": "Coordinated Universal Time-09"
    },
    {
        "id": "Pacific Standard Time (Mexico)",
        "name": "(UTC-08:00) Baja California"
    },
    {
        "id": "UTC-08",
        "name": "Coordinated Universal Time-08"
    },
    {
        "id": "Pacific Standard Time",
        "name": "(UTC-08:00) Pacific Time (US &amp; Canada)"
    },
    {
        "id": "US Mountain Standard Time",
        "name": "(UTC-07:00) Arizona"
    },
    {
        "id": "Mountain Standard Time (Mexico)",
        "name": "(UTC-07:00) Chihuahua, La Paz, Mazatlan"
    },
    {
        "id": "Mountain Standard Time",
        "name": "(UTC-07:00) Mountain Time (US &amp; Canada)"
    },
    {
        "id": "Central America Standard Time",
        "name": "(UTC-06:00)  Central America"
    },
    {
        "id": "Central Standard Time",
        "name": "(UTC-06:00) Central Time (US &amp; Canada)"
    },
    {
        "id": "Easter Island Standard Time",
        "name": "(UTC-06:00) Easter Island"
    },
    {
        "id": "Central Standard Time (Mexico)",
        "name": "(UTC-06:00) Guadalajara, Mexico City, Monterrey"
    },
    {
        "id": "Canada Central Standard Time",
        "name": "(UTC-06:00) Saskatchewan"
    },
    {
        "id": "SA Pacific Standard Time",
        "name": "(UTC-05:00) Bogota, Lima, Quito, Rio Branco"
    },
    {
        "id": "Eastern Standard Time (Mexico)",
        "name": "(UTC-05:00) Chetumal"
    },
    {
        "id": "Eastern Standard Time",
        "name": "(UTC-05:00) Eastern Time (US &amp; Canada)"
    },
    {
        "id": "Haiti Standard Time",
        "name": "(UTC-05:00) Haiti"
    },
    {
        "id": "Cuba Standard Time",
        "name": "(UTC-05:00) Havana"
    },
    {
        "id": "US Eastern Standard Time",
        "name": "(UTC-05:00) Indiana (East)"
    },
    {
        "id": "GMT-04:00",
        "name": "America/La_Paz"
    },
    {
        "id": "GMT-03:00",
        "name": "America/Argentina/Buenos_Aires"
    },
    {
        "id": "Venezuela Standard Time",
        "name": "(UTC-04:00) Caracas"
    },
    {
        "id": "Central Brazilian Standard Time",
        "name": "(UTC-04:00) Cuiaba"
    },
    {
        "id": "SA Western Standard Time",
        "name": "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan"
    },
    {
        "id": "Pacific SA Standard Time",
        "name": "(UTC-04:00) Santiago"
    },
    {
        "id": "Newfoundland Standard Time",
        "name": "(UTC-03:30) Newfoundland"
    },
    {
        "id": "GMT-03:30",
        "name": "America/St_Johns"
    },
    {
        "id": "E. South America Standard Time",
        "name": "(UTC-03:00) Brasilia"
    },
    {
        "id": "SA Eastern Standard Time",
        "name": "(UTC-03:00) Cayenne, Fortaleza"
    },
    {
        "id": "Greenland Standard Time",
        "name": "(UTC-03:00) Greenland"
    },
    {
        "id": "Montevideo Standard Time",
        "name": "(UTC-03:00) Montevideo"
    },
    {
        "id": "UTC-02",
        "name": "(UTC-02:00) Coordinated Universal Time-02"
    },
    {
        "id": "Mid-Atlantic Standard Time",
        "name": "(UTC-02:00)  Mid-Atlantic - Old"
    },
    {
        "id": "Azores Standard Time",
        "name": "(UTC-01:00) Azores"
    },
    {
        "id": "UTC",
        "name": "(UTC) Coordinated Universal Time"
    },
    {
        "id": "Greenwich Standard Time",
        "name": "(UTC+00:00) Monrovia, Reykjavik"
    },
    {
        "id": "Sao Tome Standard Time",
        "name": "(UTC+00:00) Sao Tome"
    },
    {
        "id": "W. Europe Standard Time",
        "name": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
    },
    {
        "id": "Central Europe Standard Time",
        "name": "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana,Prague"
    },
    {
        "id": "W. Central Africa Standard Time",
        "name": "(UTC+01:00) West Central Africa"
    },
    {
        "id": "Jordan Standard Time",
        "name": "(UTC+02:00) Amman"
    },
    {
        "id": "GTB Standard Time",
        "name": "(UTC+02:00) Athens, Bucharest"
    },
    {
        "id": "Middle East Standard Time",
        "name": "(UTC+02:00) Beirut"
    },
    {
        "id": "Syria Standard Time",
        "name": "(UTC+02:00)Damascus"
    },
    {
        "id": "West Bank Standard Time",
        "name": "(UTC+02:00) Gaza, Hebron"
    },
    {
        "id": "South Africa Standard Time",
        "name": "(UTC+02:00) Harare, Pretoria"
    },
    {
        "id": "Israel Standard Time",
        "name": "(UTC+02:00) Jerusalem"
    },
    {
        "id": "Kaliningrad Standard Time",
        "name": "(UTC+02:00) Kaliningrad"
    },
    {
        "id": "Namibia Standard Time",
        "name": "(UTC+02:00) Windhoek"
    },
    {
        "id": "Arabic Standard Time",
        "name": "(UTC+03:00) Baghdad"
    },
    {
        "id": "Belarus Standard Time",
        "name": "(UTC+03:00) Minsk"
    },
    {
        "id": "Iran Standard Time",
        "name": "(UTC+03:30) Tehran"
    },
    {
        "id": "Arabian Standard Time",
        "name": "(UTC+04:00) Abu Dhabi, Muscat"
    },
    {
        "id": "GMT+04:00",
        "name": "Asia/Yerevan"
    },
    {
        "id": "GMT+04:00",
        "name": "Europe/Moscow"
    },
    {
        "id": "Astrakhan Standard Time",
        "name": "(UTC+04:00) Astrakhan, Ulyanovsk"
    },
    {
        "id": "Russia Time Zone 3",
        "name": "(UTC+04:00) Izhevsk, Samara"
    },
    {
        "id": "GMT+05:30",
        "name": "Asia/Kolkata"
    },
    {
        "id": "Saratov Standard Time",
        "name": "(UTC+04:00) Saratov"
    },
    {
        "id": "Volgograd Standard Time",
        "name": "(UTC+04:00) Volgograd"
    },
    {
        "id": "GMT+06:00",
        "name": "Asia/Yekaterinburg"
    },
    {
        "id": "Afghanistan Standard Time",
        "name": "(UTC+04:30) Kabul"
    },
    {
        "id": "West Asia Standard Time",
        "name": "(UTC+05:00) Ashgabat, Tashkent"
    },
    {
        "id": "India Standard Time",
        "name": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi"
    },
    {
        "id": "Nepal Standard Time",
        "name": "(UTC+05:45) Kathmandu"
    },
    {
        "id": "Central Asia Standard Time",
        "name": "(UTC+06:00) Astana"
    },
    {
        "id": "Bangladesh Standard Time",
        "name": "(UTC+06:00) Dhaka"
    },
    {
        "id": "GMT+08:00",
        "name": "Australia/Perth"
    },
    {
        "id": "Myanmar Standard Time",
        "name": "(UTC+06:30) Yangon (Rangoon)"
    },
    {
        "id": "SE Asia Standard Time",
        "name": "(UTC+07:00) Bangkok, Hanoi, Jakarta"
    },
    {
        "id": "Altai Standard Time",
        "name": "(UTC+07:00) Barnaul, Gorno-Altaysk"
    },
    {
        "id": "North Asia Standard Time",
        "name": "(UTC+07:00) Krasnoyarsk"
    },
    {
        "id": "Singapore Standard Time",
        "name": "(UTC+08:00) Kuala Lumpur, Singapore"
    },
    {
        "id": "Taipei Standard Time",
        "name": "(UTC+08:00) Taipei"
    },
    {
        "id": "Transbaikal Standard Time",
        "name": "(UTC+09:00) Chita"
    },
    {
        "id": "Tokyo Standard Time",
        "name": "(UTC+09:00) Osaka, Sapporo, Tokyo"
    },
    {
        "id": "Korea Standard Time",
        "name": "(UTC+09:00) Seoul"
    },
    {
        "id": "AUS Central Standard Time",
        "name": "(UTC+09:30) Darwin"
    },
    {
        "id": "Tasmania Standard Time",
        "name": "(UTC+10:00) Hobart"
    },
    {
        "id": "Russia Time Zone 10",
        "name": "(UTC+11:00) Chokurdakh"
    },
    {
        "id": "GMT+11:00",
        "name": "(UTC+11:00) Sakhalin"
    },
    {
        "id": "New Zealand Standard Time",
        "name": "(UTC+12:00) Auckland, Wellington"
    },
    {
        "id": "Chatham Islands Standard Time",
        "name": "(UTC+12:45) Chatham Islands"
    },
    {
        "id": "Line Islands Standard Time",
        "name": "(UTC+14:00) Kiritimati Island"
    },
    {
        "id": "GMT+12:00",
        "name": "Asia/Magadan"
    },
    {
        "id": "UTC-11",
        "name": "(UTC-11:00) Coordinated Universal Time-11"
    },
    {
        "id": "Marquesas Standard Time",
        "name": "(UTC-09:30) Marquesas Islands"
    },
    {
        "id": "UTC-08",
        "name": "(UTC-08:00) Coordinated Universal Time-08"
    },
    {
        "id": "US Mountain Standard Time",
        "name": "(UTC-07:00) Arizona"
    },
    {
        "id": "Central America Standard Time",
        "name": "(UTC-06:00) Central America"
    },
    {
        "id": "Easter Island Standard Time",
        "name": "(UTC-06:00) Easter Island"
    },
    {
        "id": "SA Pacific Standard Time",
        "name": "(UTC-05:00) Bogota, Lima, Quito, Rio Branco"
    },
    {
        "id": "US Eastern Standard Time",
        "name": "(UTC-05:00) Indiana (East)"
    },
    {
        "id": "Paraguay Standard Time",
        "name": "(UTC-04:00) Asuncion"
    },
    {
        "id": "Central Brazilian Standard Time",
        "name": "(UTC-04:00) Cuiaba"
    }, {
        "id": "Tocantins Standard Time",
        "name": "(UTC-03:00) Araguaina"
    }, {
        "id": "Greenland Standard Time",
        "name": "(UTC-03:00) Greenland"
    },
    {
        "id": "UTC-02",
        "name": "(UTC-02:00) Coordinated  Universal Time-02"
    },
    {
        "id": "UTC",
        "name": "(UTC) Coordinated Universal Time"
    }, {
        "id": "W. Europe Standard Time",
        "name": "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"
    },
    {
        "id": "Romance Standard Time",
        "name": "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris"
    }, {
        "id": "Central European Standard Time",
        "name": "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb"
    },
    {
        "id": "Middle East Standard Time",
        "name": "(UTC+02:00) Beirut"
    },
    {
        "id": "West Bank Standard Time",
        "name": "(UTC+02:00) Gaza, Hebron"
    },
    {
        "id": "Sudan Standard Time",
        "name": "(UTC+02:00) Khartoum"
    },
    {
        "id": "Arabic Standard Time",
        "name": "(UTC+03:00) Baghdad"
    },
    {
        "id": "Russian Standard Time",
        "name": "(UTC+03:00) Moscow, St. Petersburg"
    },
    {
        "id": "Afghanistan Standard Time",
        "name": "(UTC+04:30) Kabul"
    }
];

const STATUS = {
    SUCCESS: "SUCCESS",
    NOT_STARTED: "NOT_STARTED",
    FETCHING: "FETCHING",
    FAILED: "FAILED",
    PENDING: 'PENDING',
    COMPLETED: 'COMPLETED'
};

const ASSESSMENT_QUESTIONS = {
    OUTCOME_BODY_REGION_SELECTION: 10000816
};

const ETHNIC_IDENTIFICATION = [
    { value: "Caucasian", name: "Caucasian" },
    { value: "American Indian or Alaska Native", name: "American Indian or Alaska Native" },
    { value: "Asian", name: "Asian" },
    { value: "African American", name: "African American" },
    { value: "Hispanic or Latino", name: "Hispanic or Latino" },
    { value: "Native Hawaiian or Other Pacific Islander", name: "Native Hawaiian or Other Pacific Islander" },
    { value: "Other", name: "Other" }
]

const DEPARTMENT = [
    { value: "907660000", name: "Administrative" },
    { value: "907660001", name: "Clinical" },
    { value: "907660002", name: "Engineering" },
    { value: "907660003", name: "Executive" },
    { value: "907660004", name: "Finance/Accounting" },
    { value: "907660005", name: "Human Resources" },
    { value: "907660006", name: "Information Technology" },
    { value: "907660007", name: "Legal" },
    { value: "907660008", name: "Marketing" },
    { value: "907660009", name: "Operations" },
    { value: "907660010", name: "Research & Development" },
    { value: "907660011", name: "Sales" },
    { value: "907660012", name: "Supply Chain" },
    { value: "907660013", name: "Warehouse" },
    { value: "907660014", name: "Other" },
]

const EMPLOYMENT_STATUS = [
    { value: "907660000", name: "Employed" },
    { value: "907660002", name: "Full-Time Student" },
    { value: "907660005", name: "None" },
    { value: "907660003", name: "Part-Time Student" },
    { value: "907660001", name: "Unemployed" },
    { value: "907660004", name: "Veteran" },
]

const MARITAL_STATUS = [
    { value: "3602", name: "Divorced" },
    { value: "3600", name: "Married" },
    { value: "3601", name: "Single" },
    { value: "3603", name: "Widow(Er)" },
]

const ARMS_ASSESSMENT_RESPONSE_TYPES = ['Extreme', 'Mild', 'Moderate', 'None', 'Severe', 'Score']

const OUTCOME_SCORE_QUESTIONS = {
    Neck: { OSWScore: 10000689, PScore: 10000690 },
    Arms: { None: 10000711, Mild: 10000712, Moderate: 10000713, Severe: 10000714, Extreme: 10000715, Score: 10000716 },
    Legs: { Score: 10000749 },
    Back: { OSWScore: 10000727, PScore: 10000728 }
};

const ALERT_TYPE = Object.freeze({
    SUCCESS: "success",
    DEFAULT: "default",
    INFO: "info",
    WARNING: "warning",
    DANGER: "danger",
    ERROR: "error",
    INPUT: "input",
    CUSTOM: "custom",
    CONTROLLED: "controlled",
});

const ALERT_TITLE = Object.freeze({
    SUCCESS: "Success",
    DEFAULT: "Default",
    INFO: "Info",
    WARNING: "Warning",
    DANGER: "Danger",
    ERROR: "Error",
    INPUT: "Input",
    CUSTOM: "Custom",
    CONTROLLED: "Controlled",
    CONGRATULATIONS: "Congratulations!"
});

const BOT_GROUPS = Object.freeze({
    DEMOGRAPHICS: { name: 'Demographics', key: 1 },
    ADL: { name: 'ADL', key: 3 },
    MEDICAL_HISTORY: { name: 'Medical History', key: 2 },
    CHIEF_COMPLAINTS: { name: 'Chief Complaints', key: 4 },
    GENERAL: { name: 'general', key: 0 }
});

const DOCUMENT_TYPE = [
    {
        Id: 1,
        Name: "ADL"
    },
    {
        Id: 2,
        Name: "Medical history"
    },
];

const PATIENT_PORTAL_TYPE = Object.freeze({
    Employer: { name: 'Employer', key: 1 },
    Consumer: { name: 'Consumer', key: 2 },
});

const FILTER_BY_REPORT = Object.freeze({
    ALL: { name: 'All', key: 'all' },
    IN_PROGRESS: { name: 'In Progress', key: 'inProgress' },
    COMPLETED: { name: 'Completed', key: 'completed' },
});

const FILTER_BY_GENDER = Object.freeze({
    MALE: { name: 'Male', key: 'male' },
    FEMALE: { name: 'Female', key: 'female' },
    OTHERS: { name: 'Others', key: 'others' },
});

const FILTER_BY_DOMINANT_HAND = Object.freeze({
    LEFT: { name: 'Left', key: 'left' },
    FEMALE: { name: 'Right', key: 'Right' },
});

const FOLLOW_UP = Object.freeze({
    YES_OR_NO: { name: 'Yes or No', key: 'all' },
    YES: { name: 'Yes', key: 'yes' },
    NO: { name: 'No', key: 'no' },
});

const ASSESSMENT_TYPE = Object.freeze({
   ALL : { name: "All", key : 'all' },
   FOLLOW_UP: { name: 'Follow Up', key: 'followUp' },
   LAST_INCOMPLETE: { name: 'Last Incomplete', key: 'lastIncomplete' },
});

const VIDEO_RECORDING_PURPOSE = Object.freeze({
    MOVEMENT_PERFORM: 'movementPerform',
    OTHER: 'other'
});

const PAGE_SIZE = Object.freeze({
    TEN: { name: "10", value: 10 },
    TWENTY: { name: "20", value: 20 },
    THIRTY: { name: "30", value: 30 }
});

const DEFAULT_PAGE_NUMBER = Object.freeze({
    INITIAL_PAGE: { name: "1", value: 1 },
});

const CONTROLLER = Object.freeze({
    BASE_CONTROLLER: 'base',
    EXAM_CONTROLLER: 'exam',
    EXERCISE_CONTROLLER: 'exercise',
    OPENTOK_CONTROLLER: 'Opentok',
    TELEMED_CONTROLLER: 'telemed',
    EVENT_SCHEDULER_CONTROLLER: 'EventScheduler',
    SUBSCRIPTION_FEATURE_CONTROLLER: 'SubscriptionFeature',
    LOG_CONTROLLER: 'log',
    ROM_EXERCISE_API :'RomExerciseApi',
    EXAMAPI: 'ExamApi'
})

//In which direction the user is facing while performing the exercise
const USER_FACING_DIRECTION = {
    LEFT: 'left',
    RIGHT: 'right',
    UNDETERMINED: 'undetermined'
}

const EXERCISE_CONSTRAINT = {
    STAND_STRAIGHT: 'standStraight',
    FACING_LEFT: 'facingLeft',
    FACING_RIGHT: 'facingRight',
}

const EXERCISE_NAME = [
    {
        CodeName: 'calibration',
        DisplayName: 'Calibration',
        CONSTRAINTS: [EXERCISE_CONSTRAINT.STAND_STRAIGHT]
    },
    {

    }
]

const EXERCISE_REQUIREMENT = {

}
const PLATFORM = Object.freeze({
    WEB: 'Web',
})

const SIDE = {
    FRONT: { name: 'FRONT', key: 1 },
    BACK: { name: 'BACK', key: 2 }
}

const BOT_API_VERSION = 'v1';

const ROM_API_VERSION = 'v1';

const CONSTANT = {
    JWT: JWT,
    KEYBOARD_KEYS: KEYBOARD_KEYS,
    MAX_TIMEOUT_TIME: MAX_TIMEOUT_TIME,
    HTML_REGEX: HTML_REGEX,
    PAIN_BOT_INFO: PAIN_BOT_INFO,
    MSK_BOT_INFO: MSK_BOT_INFO,
    POSTURE_BOT_INFO: POSTURE_BOT_INFO,
    INTAKE_BOT_INFO: INTAKE_BOT_INFO,
    APPOINTMENT_BOT_INFO: APPOINTMENT_BOT_INFO,
    PRECALL_TEST_BOT_INFO: PRECALL_TEST_BOT_INFO,
    CHATBOT_INTENT: CHATBOT_INTENT,
    CHATBOT_ACCOUNT: CHATBOT_ACCOUNT,
    PAIN_OPTIONS: PAIN_OPTIONS,
    ACTIVITY_LEVEL_COLOR: ACTIVITY_LEVEL_COLOR,
    PAIN_SEVERITY_COLOR: PAIN_SEVERITY_COLOR,
    ELEMENT_TYPES: ELEMENT_TYPES,
    BOT_QUESTION_ID: BOT_QUESTION_ID,
    EXERCISE_STATUS: EXERCISE_STATUS,
    EXERCISES_NAME: EXERCISES_NAME,
    LANGUAGES: LANGUAGES,
    DATE_FORMAT: DATE_FORMAT,
    ASSESSMENT_GROUP: ASSESSMENT_GROUP,
    DEFAULT_LANGUAGE: DEFAULT_LANGUAGE,
    ROLE: ROLE,
    CHAT_BOTS: CHAT_BOTS,
    BODY_REGIONS: BODY_REGIONS,
    FACE_DETECT_INTERVAL_TIME: FACE_DETECT_INTERVAL_TIME,
    TAB: TAB,
    VIDEO_TYPE: VIDEO_TYPE,
    PROVIDER_EMAIL: PROVIDER_EMAIL,
    WEBCAM_FACING_MODE: WEBCAM_FACING_MODE,
    PAGE_NAME: PAGE_NAME,
    MEDIA_DEVICES_KIND: MEDIA_DEVICES_KIND,
    MONTHS: MONTHS,
    VOICE_COMMAND: VOICE_COMMAND,
    QUESTION_GROUP_NAME: QUESTION_GROUP_NAME,
    FEATURE_ENABLE: FEATURE_ENABLE,
    PORTAL: PORTAL,
    HEIGHT: HEIGHT,
    REDIRECT_TYPE: REDIRECT_TYPE,
    VIDEO_CONSTRAINTS: VIDEO_CONSTRAINTS,
    VIDEO_CONSTRAINTS_NEW: VIDEO_CONSTRAINTS_NEW,
    REFERENCE_RESPONSE: REFERENCE_RESPONSE,
    URLS: URLS,
    MOVEMENT_TYPES: MOVEMENT_TYPES,
    MEASUREMENT_KEY: MEASUREMENT_KEY,
    STATUS_CODE: STATUS_CODE,
    GENERAL_AUDIOS_KEY_TEXT: GENERAL_AUDIOS_KEY_TEXT,
    POSE_POSITION: POSE_POSITION,
    TIME_ZONES: TIME_ZONES,
    STATUS: STATUS,
    ASSESSMENT_QUESTIONS: ASSESSMENT_QUESTIONS,
    ETHNIC_IDENTIFICATION: ETHNIC_IDENTIFICATION,
    DEPARTMENT: DEPARTMENT,
    EMPLOYMENT_STATUS: EMPLOYMENT_STATUS,
    MARITAL_STATUS: MARITAL_STATUS,
    ARMS_ASSESSMENT_RESPONSE_TYPES: ARMS_ASSESSMENT_RESPONSE_TYPES,
    OUTCOME_SCORE_QUESTIONS: OUTCOME_SCORE_QUESTIONS,
    ALERT_TYPE: ALERT_TYPE,
    BOT_GROUPS: BOT_GROUPS,
    DOCUMENT_TYPE: DOCUMENT_TYPE,
    PATIENT_PORTAL_TYPE: PATIENT_PORTAL_TYPE,
    ALERT_TITLE: ALERT_TITLE,
    FILTER_BY_REPORT: FILTER_BY_REPORT,
    FOLLOW_UP: FOLLOW_UP,
    VIDEO_RECORDING_PURPOSE: VIDEO_RECORDING_PURPOSE,
    PAGE_SIZE: PAGE_SIZE,
    DEFAULT_PAGE_NUMBER: DEFAULT_PAGE_NUMBER,
    INVITATION_TYPE: INVITATION_TYPE,
    FILTER_BY_GENDER: FILTER_BY_GENDER,
    FILTER_BY_DOMINANT_HAND: FILTER_BY_DOMINANT_HAND,
    CC_QUESTION: CC_QUESTION,
    CONTROLLER: CONTROLLER,
    REFRESH_TOKEN: REFRESH_TOKEN,
    PLATFORM: PLATFORM,
    ASSESSMENT_TYPE: ASSESSMENT_TYPE,
    USER_FACING_DIRECTION: USER_FACING_DIRECTION,
    EXERCISE_REQUIREMENT: EXERCISE_REQUIREMENT,
    SIDE: SIDE,
    BOT_API_VERSION: BOT_API_VERSION,
    ROM_API_VERSION: ROM_API_VERSION
};


export default CONSTANT;
