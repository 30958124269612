import CONSTANT from "../../../../../constants/Constant";
import { _getWaitingRoomByInvitationInfo } from "../../../../../utils/Utils";
import GLOBAL from "../../../../../global";
import { useContext } from "react";
import AppContext from "../../../../../contexts/AppContext";
import {
    initIntakeForm,
    setInvitationId,
    setJoiningTelemedicineAtIntakeForm,
    closeIntakeForm,
    setWaitingRoomId,
    setJoiningLaterTelemedicineAtIntakeForm
} from "../../../../../features/intakeForm/intakeFormSlice";
import { useDispatch, useSelector } from "react-redux";
import { redirectTo } from "../../ChatServices";
import routes from "../../../../../constants/Routes";
import useSweetAlert from "../../../hooks/useSweetAlert";
import examService from "../../../../../services/ExamService";
import useChatbot from "../../hooks/useChatbot";
import { clearChatBotProperty } from "../../../../../features/chat/chatbotSlice";
import { saveIntake } from "../../../intake-form/intake-form-service";

const useCurrentQuestion = () => {
    const dispatch = useDispatch();
    const chatBotState = useSelector(state => state.chatBot);
    const isIntakeSigned = GLOBAL?.USER_INFO?.IsIntakeSigned || false;
    const { userInfo, setTelemedicineProperties, resetTelemedicineKeys } = useContext(AppContext);
    const { clearAndCloseChatbot } = useChatbot();
    const { showSweetAlert, setNoLoading, hideSweetAlert, setOkLoading } = useSweetAlert();


    const botData = chatBotState?.botData || {};

    const bodyRegionList = botData?.bodyRegionList || [];
    const invitationTypeName = (botData?.invitationTypeName || '').toLowerCase();
    const invitationIdFromState = botData?.invitationId || '';


    async function conversationEnded() {
        if (invitationTypeName === CONSTANT.REDIRECT_TYPE.TELEMEDICINE) {
            const {
                invitationId,
                waitingRoomId
            } = await _getWaitingRoomByInvitationInfo(GLOBAL?.ASSESSMENT?.['TestId'], invitationIdFromState);

            if (invitationId && waitingRoomId) {
                if (!isIntakeSigned) {
                    dispatch(initIntakeForm({
                        showIntakeForm: true,
                        bodyRegionList: bodyRegionList,
                        invitationTypeName: invitationTypeName || '',
                    }));
                    dispatch(setWaitingRoomId(waitingRoomId));
                    dispatch(setInvitationId(invitationId));
                } else {
                    showSweetAlert({
                        title: 'Question',
                        message: 'Please confirm your telemedicine encounter',
                        type: CONSTANT.ALERT_TYPE.SUCCESS,
                        showConfirmBtn: true,
                        showCancelBtn: true,
                        confirmBtnCss: "btn btn-success btn-wide",
                        cancelBtnCss: "btn btn-danger btn-wide kt-ml-20",
                        confirmBtnText: "JOIN NOW",
                        cancelBtnText: "JOIN LATER",
                        textWhileProcessingConfirmBtn: "Joining...",
                        onConfirm: () => {
                            setOkLoading(true);
                            onClickJoinTelemedicineNow('', invitationId, bodyRegionList);
                        },
                        onCancel: () => {
                            setNoLoading(true);
                            onClickJoinTelemedicineLater('', waitingRoomId);
                        },
                    })
                }


            } else {
                showSweetAlert({
                    title: '',
                    message: 'Waiting room invitation error',
                    type: CONSTANT.ALERT_TYPE.INFO,
                    closeOnClickOutside: true,
                    showOkBtn: true,
                    onConfirm: onConfirmAlert
                })
            }
        } else if (
            invitationTypeName === CONSTANT.REDIRECT_TYPE.INCLINIC ||
            invitationTypeName === CONSTANT.REDIRECT_TYPE.INCLINIC_ENCOUNTER) {
                showSweetAlert({
                    title: '',
                    message: 'Thank you for completing your assessment questionnaire. Your assessment will be reviewed by your provider during your telemedicine or in-clinic appointment.',
                    type: CONSTANT.ALERT_TYPE.INFO,
                    closeOnClickOutside: true,
                    showOkBtn: true,
                    onConfirm: () => {
                        dispatch(clearChatBotProperty());
                        redirectTo(routes.landing);
                        window.location.reload();
                    }

                })
        }
    }

    const closeChatBot = () => {
        if(!userInfo?.Permissions?.CanPatientCreateAssessment) {
            redirectTo(`${routes.myAssessments}/true`);
        }
        clearAndCloseChatbot();
    }

    const sendEmailForTelemed = async (waitingRoomId) => {
        const payload = { WaitingRoomId: waitingRoomId };
        const response = await examService.sendTelemedEmailForEmma(payload);
        const { success, error } = response;

        if (!success) {
            console.error(error);

            showSweetAlert({
                title: '',
                message: 'No waiting room available.',
                type: CONSTANT.ALERT_TYPE.INFO,
                closeOnClickOutside: true,
                showOkBtn: true,
                onConfirm: onConfirmAlert
            })
        } else {
            showSweetAlert({
                title: '',
                message: 'An Email has been sent to your inbox with telemedicine information.',
                type: CONSTANT.ALERT_TYPE.INFO,
                closeOnClickOutside: true,
                showOkBtn: true,
                onConfirm: onConfirmAlert
            })
        }
    };

    const onOpenTelemedicineModal = () => {
        hideSweetAlert();
    }


    const onClickJoinTelemedicineNow = async (values, invitationId, bodyRegionList) => {
        if(!isIntakeSigned) {
            dispatch(setJoiningTelemedicineAtIntakeForm(true));
            await saveIntake(values);
        }
        setTelemedicineProperties(invitationId, bodyRegionList, onOpenTelemedicineModal, onCloseTelemedicineModal);
    }

    const onCloseTelemedicineModal = () => {
        dispatch(closeIntakeForm());
        resetTelemedicineKeys();
        onConfirmAlert();
        window.location.replace(`/#${routes.myAssessments}/false`);
    }

    const onClickJoinTelemedicineLater = async (values, waitingRoomId) => {
        if (!isIntakeSigned) {
            dispatch(setJoiningLaterTelemedicineAtIntakeForm(true));
        }

        if(values) {
            await saveIntake(values);
        }
        await sendEmailForTelemed(waitingRoomId);
        if(values) {
            dispatch(closeIntakeForm());
        }
        window.location.replace(`/#${routes.myAssessments}/false`);
    };

    const onConfirmAlert = () => {
        dispatch(closeIntakeForm());
        hideSweetAlert();
        closeChatBot();
    };

    return {
        conversationEnded,
        onClickJoinTelemedicineNow,
        onClickJoinTelemedicineLater,
    };
}

export default useCurrentQuestion;