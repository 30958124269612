import React, { useState } from "react";
import { Modal } from "reactstrap";

import MovementCore from "./MovementCore";
import AppointmentConfirmPopup from "../layout/AppointmentConfirmPopup";
import AppointmentPopup from "../layout/AppointmentPopup";

const MovementModal = (props) => {
    const { open, selectedRegion, testId, onClose, clearAndCloseChatbot } = props;
    // const [showApponitmentPopup, setShowAppointmentPopup] = useState(false);
    let [submitStatus , setSubmitStatus] = useState(false);

    // const pull_data = (data) => {
    //     setShowAppointmentPopup(data);
    // };

    const pull_data_submitStatus = (submit) =>{
        setSubmitStatus(submit);
    };

    const submitCloseFunc = (submit) =>{
        setSubmitStatus(submit);
    };

    return (
        <>
            <Modal isOpen={open} className="modal-xl h-100 kt-m0"
                   modalClassName="mmh-movement-modal">
                <MovementCore
                    bodyRegionList={selectedRegion}
                    testId={testId}
                    onClose={onClose}
                    // showAppointmentPop={pull_data}
                    clearAndCloseChatbot={clearAndCloseChatbot}
                />
            </Modal>
            {submitStatus && <AppointmentConfirmPopup submitCloseFunc={submitCloseFunc} submitStatus={submitStatus}/>}
            {/*{showApponitmentPopup && <AppointmentPopup func={pull_data} show={showApponitmentPopup} submitFunc={pull_data_submitStatus}/>}*/}
        </>

    )
};

export default MovementModal;
