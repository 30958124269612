class Notification {
    constructor(notification) {
        notification = notification || {};

        this.InvitationTypeId = notification?.InvitationTypeId;
        this.InvitationTypeName = notification?.InvitationTypeName || '';
        this.BodyRegionList = notification?.BodyRegionList || [];
        this.MeetingDate = notification?.MeetingDate || '';
        this.ProviderName = notification?.ProviderName || '';
        this.TestId = notification?.TestId || '';
        this.IsFollowup = notification?.IsFollowup || false;
        this.PatientMeetingUrl = notification?.PatientMeetingUrl || '';
        this.InvitationId = notification?.InvitationId || '';
        this.IsMeetingNow = notification?.IsMeetingNow || false;
        this.IsLastIncomplete = notification?.IsLastIncomplete || false;
        this.IsFormBasedIntakeEnabled = notification?.IsFormBasedIntakeEnabled || false;
        this.IsInUpcomingWeek = notification?.IsInUpcomingWeek || false;
        this.AssessmentStatus = {
            DemographicsCompleted : notification?.DemographicsCompleted || false,
            ADLCompleted : notification?.AdlCompleted || false,
            MedicalHistoryCompleted : notification?.MedicalHistoryCompleted || false,
            ChiefComplaintsCompleted : notification?.ChiefComplaintsCompleted || false,
            BiometricCompleted : notification?.BiometricCompleted || false
        }
        this.WaitingRoomId = notification?.WaitingRoomId || '';
    }
}

export default Notification
