import { ChatbotButton } from "../ButtonComponent";
import React, {useContext} from "react";
import { defaultColor, getMarginStyle } from "../ChatServices";
import SkipButton from "../SkipButton";
import ShowSkipped from "../ShowSkipped";
import {isSelectedCurrentOption} from "../ChatUtils";
import {useSelector} from "react-redux";
import useChiefComplaintAtBot from "../hooks/useChiefComplaintAtBot";

const ChiefComplaintQuestionsAnswers = ({ onClickSkip }) => {
    const chatBotState = useSelector(state => state.chatBot);
    const botData = chatBotState?.botData || {};
    const chatBotUIState = chatBotState?.chatBotUIState || {};

    const chats = botData?.chats || [];
    const currentChatIndex = botData?.currentChatIndex ?? -1;

    const isLoadingCC = chatBotUIState?.isLoadingCC || false;


    const { chiefComplaintModalToggle } = useChiefComplaintAtBot();

    const chat = chats[currentChatIndex];

    return (
        <>
            {chat && !chat.answered && (
                <>
                    <div className="responses">
                        {chat.responses && chat.responses.length > 0 && (
                            chat.responses.map((chatResponse, indexR) => {
                                return (
                                    <ChatbotButton
                                        type="button"
                                        key={indexR}
                                        color={defaultColor(chatResponse.color)}
                                        fill={isSelectedCurrentOption(chat, chatResponse).toString()}
                                        onClick={() => chiefComplaintModalToggle(chat, chatResponse)}
                                        disabled={isLoadingCC}
                                        className="btn btn-outline-brand btn-elevate btn-pill-5 btn-lg btn-ans mt-2 mr-5">
                                        <div className="d-flex">
                                            <span className={`m-auto px-2 ${getMarginStyle(chat)}`}>
                                                {isSelectedCurrentOption(chat, chatResponse) ? <i className="fa fa-check"/> : "" }
                                                {(isLoadingCC && chatResponse?.referenceId === 1) ? <i className="fa fa-spinner fa-spin"/> : ""}
                                                  {chatResponse.name}
                                            </span>
                                        </div>
                                    </ChatbotButton>
                                )
                            })
                        )}
                    </div>
                    <SkipButton chat={chat} onClickSkip={onClickSkip}/>
                </>
            )}
            {chat && chat.answered && (
                <div className="answers">
                    {chat.answers && chat.answers.length > 0 && (
                        chat.answers.map((chatAnswer, indexR) => {
                            return (
                                <div className="kt-widget3" key={indexR}>
                                    <div className="kt-widget3__item">
                                        <div className="kt-widget3__header d-flex justify-content-end">
                                            <div className="kt-widget3__user-img">
                                                {chatAnswer.referenceId === 2 && (
                                                    <span>
                                                        NO CHIEF COMPLAINT
                                                    </span>
                                                )}
                                                {chatAnswer.referenceId !== 2 && (
                                                    <span onClick={() => chiefComplaintModalToggle(chat, chatAnswer, true)}>
                                                        REVIEW CHIEF COMPLAINTS
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })

                    )}
                    <ShowSkipped chat={chat}/>
                </div>
            )}
        </>
    )
};

export default ChiefComplaintQuestionsAnswers;
