import { getNotificationInformation } from '../data/api'
import { mapNotificationDataToLocalNotificationModal } from '../data/models/NotificationMapping'


const dateRegex = /(\d{1,2} \w+ \d{2,4}) (\d{1,2}:\d{1,2} [APMapm]{2}) - (\d{1,2}:\d{1,2} [APMapm]{2})/;

export const getNotificationData = async (Email) => {
   const notificationData = await getNotificationInformation({ Email })
    const formatedData  = mapNotificationDataToLocalNotificationModal(notificationData)

    return formatedData
}

export const extractDateAndTime = (dateTimeString) => {
    const match = dateTimeString.match(dateRegex);

    // 20 February 2024 01:52 PM - 03:52 PM
    if (match) {
        const datePart = match[1]; // "20 February 2024"
        const startTimeUTC = match[2]; // "01:52 PM"
        const endTimeUTC = match[3]; // "03:52 PM"
        return {
            date: datePart,
            startTimeUTC,
            endTimeUTC
        }
    } else {
        return null;
    }
}

export const timeConverterComponent = (dateTimeString) => {
    const dateTime = extractDateAndTime(dateTimeString);

    if (!dateTime) {
        return "Invalid date and time";
    }

    const { date, startTimeUTC, endTimeUTC } = dateTime;

    // Extract just the time part for start and end
    const startTime = new Date(`${date} ${startTimeUTC} UTC`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
    const endTime = new Date(`${date} ${endTimeUTC} UTC`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });

    // Assuming the date doesn't change, use the start date
    const localDate = new Date(`${date} ${startTimeUTC} UTC`).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });

    return {
        date: localDate,
        startTime,
        endTime
    }
};

export function formatDateTimeIntoISO8601(dateStr, timeStr) {
    // Combine date and time strings
    const dateTimeStr = `${dateStr} ${timeStr}`;

    // Parse the combined string into a Date object
    const months = {
        "January": 0, "February": 1, "March": 2, "April": 3, "May": 4, "June": 5,
        "July": 6, "August": 7, "September": 8, "October": 9, "November": 10, "December": 11
    };
    const parts = dateTimeStr.match(/(\w+) (\d+), (\d+) (\d+):(\d+) ([AP]M)/);
    const year = parseInt(parts[3], 10);
    const month = months[parts[1]];
    const day = parseInt(parts[2], 10);
    let hours = parseInt(parts[4], 10) + (parts[6] === "PM" && parts[4] !== "12" ? 12 : 0);
    if (parts[6] === "AM" && parts[4] === "12") hours = 0; // Convert 12 AM to 00
    const minutes = parseInt(parts[5], 10);

    // Create a Date object
    const date = new Date(year, month, day, hours, minutes);

    // Format to YYYY-MM-DDTHH:MM:SS
    const pad = (num) => num.toString().padStart(2, '0');
    const formattedDate = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;

    return formattedDate;
}