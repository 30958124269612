import { createSlice } from "@reduxjs/toolkit";
import { initialIntakeFormState } from "./intakeFormConstant";

const IntakeFormSlice = createSlice({
    name: 'intakeAndJoinTelemedicinePopup',
    initialState: initialIntakeFormState,
    reducers: {
        initIntakeForm(state, action) {
            const showIntakeForm = action.payload?.showIntakeForm || false;
            const bodyRegionList = action.payload?.bodyRegionList || [];
            const invitationTypeName = action.payload?.invitationTypeName || '';

            state.showIntakeForm = showIntakeForm;
            state.bodyRegionList = bodyRegionList;
            state.invitationTypeName = invitationTypeName.toLowerCase();
        },
        toggleShowPolicy(state) {
            state.showPolicy = !state.showPolicy;
        },
        toggleShowTermsAndConditionModal(state) {
            state.showTermsAndConditionModal = !state.showTermsAndConditionModal;
        },
        closeIntakeForm() {
            return {
                ...initialIntakeFormState
            }
        },
        setJoiningTelemedicineAtIntakeForm(state, action) {
            return {
                ...state,
                joinNowBtnConfig: {
                    ...state.joinNowBtnConfig,
                    joiningTelemedicine: action.payload
                }
            }
        },
        setJoiningLaterTelemedicineAtIntakeForm(state, action) {
            return {
                ...state,
                joinLaterBtnConfig: {
                    ...state.joinLaterBtnConfig,
                    joiningLaterTelemedicine: action.payload
                }
            }
        },
        setInvitationId(state, action) {
            state.invitationId = action.payload;
        },
        setWaitingRoomId(state, action) {
            const waitingRoomId = action.payload || '';
            return {
                ...state,
                waitingRoomId: waitingRoomId
            }
        },
        setInvitationTypeName(state, action) {
            let invitationTypeName = action.payload || '';
            invitationTypeName = invitationTypeName.toLowerCase();
            state.invitationTypeName = action.payload;
        },
        setBodyRegionList(state, action) {
            state.bodyRegionList = action.payload;
        }
    }
});

export const {
    closeIntakeForm,
    toggleShowTermsAndConditionModal,
    showIntakeForm,
    setJoiningTelemedicineAtIntakeForm,
    setJoiningLaterTelemedicineAtIntakeForm,
    toggleShowPolicy,
    setInvitationId,
    setWaitingRoomId,
    setInvitationTypeName,
    setBodyRegionList,
    initIntakeForm,
} = IntakeFormSlice.actions;

export default IntakeFormSlice.reducer;