import React, { useContext, useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import GLOBAL from "../../global";
import authService from "../../services/AuthService";
import AppContext from "../../contexts/AppContext";
import defaultProfilePic from "../../assets/img/users/default.jpg";

const ChangePasswordDetails = () => {
    const { deAuthenticate } = useContext(AppContext);
    const { i18n } = useTranslation();
    const [storeOldPassword, setStoreOldPassword] = useState('');
    const subscription = useRef(true);

    const fetchData = async (payload) => {
        const response = await authService.getPassword(payload);
        const { data, success, error } = response;

        if (!success) {
            console.error(error);
            return;
        }

        return data;
    };

    useEffect(() => {
        const payload = {
            Email: GLOBAL.USER_INFO.EmailAddress
        };

        fetchData(payload).then(data => {
            const tempData = JSON.parse(data || '{}');

            if (subscription.current) {
                setStoreOldPassword(tempData.Password);
            }
        }).catch(error => {
            console.error(error);
        });

        return () => {
            subscription.current = false;
        };

    }, []);

    const onSubmitChangePassword = async (values) => {
        const payload = {
            Email: GLOBAL.USER_INFO['EmailAddress'],
            Password: values.NewPassword
        }

        const response = await authService.resetPassword(payload);
        const { success, error } = response;

        if (!success) {
            console.error(error);
            return;
        }

        deAuthenticate();
    };

    const initialValuesForUserPassword = {
        OldPassword: "",
        NewPassword: "",
        ConfirmPassword: "",
    };

    const validationSchema = () => {
        return Yup.object().shape({
            OldPassword: Yup.string()
                .required(i18n.t("common.message.error.required-field"))
                .matches(storeOldPassword, i18n.t("common.message.info.old-password-not-matching")),
            NewPassword: Yup.string()
                .required(i18n.t("common.message.error.required-field"))
                .matches(/.*[A-Z]/, i18n.t("common.message.info.password-strength-Upper-letter"))
                .matches(/.*[a-z]/, i18n.t("common.message.info.password-strength-Lower-letter"))
                .matches(/.*\d/, i18n.t("common.message.info.password-strength-number"))
                .min(8, i18n.t("common.message.info.password-strength-alert"))
                .when("OldPassword", {
                    is: val => (!!(val && val.length > 0)),
                    then: Yup.string().notOneOf(
                        [Yup.ref("OldPassword")],
                        "The new password is not  same  to the old password"
                    )
                }),
            ConfirmPassword: Yup.string()
                .required(i18n.t("common.message.error.required-field"))
                .matches(/.*[A-Z]/, i18n.t("common.message.info.password-strength-Upper-letter"))
                .matches(/.*[a-z]/, i18n.t("common.message.info.password-strength-Lower-letter"))
                .matches(/.*\d/, i18n.t("common.message.info.password-strength-number"))
                .min(8, i18n.t("common.message.info.password-strength-alert"))
                .when("NewPassword", {
                    is: val => (!!(val && val.length > 0)),
                    then: Yup.string().oneOf(
                        [Yup.ref("NewPassword")],
                        "Both password need to be the same"
                    )
                }),
        });
    };

    const onClickEmailHandle = (e) => {
        e.preventDefault();
    };

    const onClickToggleSidebar = () => {
        document.getElementById("kt_user_profile_aside").classList.toggle("kt-app__aside--on");
    };

    return (
        <Formik
            initialValues={initialValuesForUserPassword}
            validationSchema={validationSchema()}
            onSubmit={onSubmitChangePassword}>
            {({
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  values,
                  errors,
                  touched,
                  isSubmitting,
              }) => (
                <>
                    <div className="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app w-100">
                        <div className="kt-grid__item kt-app__toggle kt-app__aside" id="kt_user_profile_aside">
                            <button className="kt-app__aside-close" id="kt_user_profile_aside_close"
                                    onClick={onClickToggleSidebar}>
                                <i className="la la-close"/>
                            </button>
                            <div className="kt-portlet ">
                                <div className="kt-portlet__head  kt-portlet__head--noborder">
                                    <div className="kt-portlet__head-label">
                                        <h3 className="kt-portlet__head-title">
                                        </h3>
                                    </div>
                                </div>
                                <div className="kt-portlet__body kt-portlet__body--fit-y">
                                    <div className="kt-widget kt-widget--user-profile-1">
                                        <div className="kt-widget__head">
                                            <div className="kt-widget__media">
                                                {GLOBAL.USER_INFO['ProfilePic'] ?
                                                    <img src={GLOBAL.USER_INFO['ProfilePic']} alt="Pic"/>
                                                    : <img src={defaultProfilePic} alt="Pic"/>
                                                }
                                            </div>
                                            <div className="kt-widget__content">
                                                <div className="kt-widget__section">
                                                    <a href={"!#"} className="kt-widget__username"
                                                       onClick={(e) => e.preventDefault()}>
                                                        {GLOBAL.USER_INFO['FullName']}
                                                        <i className="flaticon2-correct kt-font-success"/>
                                                    </a>
                                                    <span className="kt-widget__subtitle">
                                                    PATIENT
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="kt-widget__body">
                                            <div className="kt-widget__content">
                                                <div className="kt-widget__info">
                                                    <span className="kt-widget__label">Email:</span>
                                                    <a onClick={e => onClickEmailHandle(e)}
                                                       rel="noopener noreferrer" href={"!#"}
                                                       className="kt-widget__data">{GLOBAL.USER_INFO['EmailAddress']}</a>
                                                </div>
                                                <div className="kt-widget__info">
                                                    <span className="kt-widget__label">Phone:</span>
                                                    <a href={"!#"}
                                                       className="kt-widget__data">{GLOBAL.USER_INFO['MobilePhone']}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="kt-portlet">
                                        <div className="kt-portlet__head">
                                            <div className="kt-portlet__head-label">
                                                <h3 className="kt-portlet__head-title">Change Your Password</h3>
                                            </div>
                                        </div>
                                        <form onSubmit={handleSubmit}
                                              className="kt-form kt-form--label-right">
                                            <div className="kt-portlet__body">
                                                <div className="kt-section kt-section--first">
                                                    <div className="kt-section__body">
                                                        <div className="row">
                                                            <label className="col-xl-3"/>
                                                            <div className="col-lg-9 col-xl-6">
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-xl-3 col-lg-3 col-form-label">Old
                                                                Password<span
                                                                    className="required text-danger"
                                                                    aria-required="true">*</span></label>
                                                            <div className="col-lg-9 col-xl-6">
                                                                <input
                                                                    className="kt-mt-0 form-control"
                                                                    type="text"
                                                                    placeholder="Old password"
                                                                    name="OldPassword"
                                                                    autoComplete="off"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.OldPassword}
                                                                />
                                                                {errors.OldPassword && touched.OldPassword && (
                                                                    <div
                                                                        className="invalid-feedback d-block">{errors.OldPassword}</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-xl-3 col-lg-3 col-form-label">New
                                                                Password<span
                                                                    className="required text-danger"
                                                                    aria-required="true">*</span></label>
                                                            <div className="col-lg-9 col-xl-6">
                                                                <input
                                                                    className="kt-mt-0 form-control"
                                                                    type="text"
                                                                    placeholder="New password"
                                                                    name="NewPassword"
                                                                    autoComplete="off"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.NewPassword}
                                                                />
                                                                {errors.NewPassword && touched.NewPassword && (
                                                                    <div
                                                                        className="invalid-feedback d-block">{errors.NewPassword}</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-xl-3 col-lg-3 col-form-label">Confirm
                                                                Password<span
                                                                    className="required text-danger"
                                                                    aria-required="true">*</span></label>
                                                            <div className="col-lg-9 col-xl-6">
                                                                <input
                                                                    className="kt-mt-0 form-control"
                                                                    type="text"
                                                                    placeholder="Confirm password"
                                                                    name="ConfirmPassword"
                                                                    autoComplete="off"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.ConfirmPassword}
                                                                />
                                                                {errors.ConfirmPassword && touched.ConfirmPassword && (
                                                                    <div
                                                                        className="invalid-feedback d-block">{errors.ConfirmPassword}</div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <div className="col-xl-3 col-lg-3"/>
                                                            <div className="col-xl-3 col-lg-3">
                                                                <button type="submit" className="btn btn-success"
                                                                        disabled={isSubmitting}
                                                                >
                                                                    {isSubmitting ? (
                                                                        <>
                                                                            <i className="fa fa-spinner fa-spin"/>&nbsp;&nbsp; Saving..
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <i className="fa fa-check"
                                                                               aria-hidden="true"/>&nbsp; Save
                                                                        </>
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Formik>
    )
};

export default ChangePasswordDetails;
