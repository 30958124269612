import CONSTANT from "../../../../constants/Constant";
import CheckBoxQuestionsAnswers from "../Responses/CheckBoxQuestionsAnswers";
import LabelQuestionsAnswers from "../Responses/LabelQuestionsAnswers";
import ButtonQuestionsAnswers from "../Responses/ButtonQuestionsAnswers";
import MovementModalAnswers from "../Responses/MovementModalAnswers";
import ChiefComplaintQuestionsAnswers from "../Responses/ChiefComplaintQuestionsAnswers";
import AutoCompleteQuestionsAnswers from "../Responses/AutoCompleteQuestionsAnswers";
import TextQuestionsAnswers from "../Responses/TextQuestionsAnswers";
import DatetimePickerQuestionsAnswers from "../Responses/DatetimePickerQuestionsAnswers";
import DatePickerQuestionsAnswers from "../Responses/DatePickerQuestionsAnswers";
import React from "react";
import DropdownQuestionsAnswers from "../Responses/DropdownQuestionsAnswers";
import {useSelector} from "react-redux";
import useChatbot from "../hooks/useChatbot";

const CurrentQuestionResponse = () => {
    const { prepareChatbotAnswer } = useChatbot();
    const chatBotState = useSelector(state => state.chatBot);
    const botData = chatBotState?.botData || {};
    const chats = botData?.chats || [];
    const currentChatIndex = botData?.currentChatIndex ?? -1;

    const onClickSkip = (chat) => async () => {
        chat.skipped = true;
        await prepareChatbotAnswer(chat);
    };

    return (
        <div className="mb-5">
            {chats[currentChatIndex]?.responseType === CONSTANT.ELEMENT_TYPES.CHECKBOX && (
                <CheckBoxQuestionsAnswers
                    onClickSkip={onClickSkip}
                />
            )}

            {chats[currentChatIndex]?.responseType === CONSTANT.ELEMENT_TYPES.LABEL && (
                <LabelQuestionsAnswers />
            )}

            {chats[currentChatIndex]?.responseType === CONSTANT.ELEMENT_TYPES.BUTTON && (
                <ButtonQuestionsAnswers onClickSkip={onClickSkip}/>
            )}

            {chats[currentChatIndex]?.responseType === CONSTANT.ELEMENT_TYPES.MODAL && (
                <>
                    {chats[currentChatIndex]?.intent === CONSTANT.CHATBOT_INTENT.MOVEMENT && (
                        <MovementModalAnswers
                            onClickSkip={onClickSkip}
                        />
                    )}

                    {chats[currentChatIndex]?.intent === CONSTANT.CHATBOT_INTENT.CHIEF_COMPLAINTS && (
                        <ChiefComplaintQuestionsAnswers
                            onClickSkip={onClickSkip}
                        />
                    )}
                </>
            )}

            {chats[currentChatIndex]?.responseType === CONSTANT.ELEMENT_TYPES.AUTOCOMPLETE && (
                <AutoCompleteQuestionsAnswers
                    onClickSkip={onClickSkip}
                />
            )}

            {chats[currentChatIndex]?.responseType === CONSTANT.ELEMENT_TYPES.DROPDOWN && (
                <DropdownQuestionsAnswers
                    onClickSkip={onClickSkip}
                />
            )}

            {chats[currentChatIndex]?.responseType === CONSTANT.ELEMENT_TYPES.TEXT && (
                <TextQuestionsAnswers
                    onClickSkip={onClickSkip}
                />
            )}

            {chats[currentChatIndex]?.responseType === CONSTANT.ELEMENT_TYPES.DATETIME_PICKER && (
                <DatetimePickerQuestionsAnswers
                    onClickSkip={onClickSkip}
                />
            )}

            {chats[currentChatIndex]?.responseType === CONSTANT.ELEMENT_TYPES.DATE_PICKER && (
                <DatePickerQuestionsAnswers
                    onClickSkip={onClickSkip}
                />
            )}
        </div>
    )
}

export default CurrentQuestionResponse;