import React from 'react';
import Popup from "../Popup";
import IconCloseButton from "../IconCloseButton";

//toggleDialog => click on cancel or no button action
const ConfirmationDialog = (props) => {
    const { headerText, show, toggleDialog, children, onClickHeaderClose, confirmText, cancelText, isYesInLeft = false, isSaving = false, showLoadingInConform = false, showYesBtn = true  } = props;

    const onConfirmationClick = () => {
        const { onConfirm } = props;
        onConfirm && onConfirm();
    };

    return (
        <>
            <Popup show={show}>
                <div className="confirm-modal rounded-lg">
                    <div className="d-flex justify-content-end p-3">
                        <IconCloseButton closeHandler={onClickHeaderClose || toggleDialog} />
                    </div>
                    {(isSaving) ? (
                       <>
                           <p className='text-center mb-5 font-size-md'>Please wait while saving <i className="fa fa-spinner fa-spin"/>&nbsp;&nbsp;</p>
                           {/*<img src={loadingGif} alt="loading" className="d-block m-auto" style={{ maxHeight: '200px', maxWidth: '200px' }}/>*/}
                       </>
                    ) : (
                        <>
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="bordered text-info">
                                    {(headerText) ? headerText : "Confirmation"}
                                </div>
                            </div>
                            <div className="confirm-text">
                                <div className="confirm-text--container">
                                    {children}
                                </div>
                            </div>
                            <div className="button-container">
                                {!isYesInLeft ? (
                                    <>
                                        <button type="button" className="bg-color-red mr-3" onClick={toggleDialog} disabled={showLoadingInConform}>
                                            {cancelText || "Cancel"}
                                        </button>
                                        {
                                            showYesBtn
                                                ?
                                                (
                                                    <button type="button" className="bg-color-bright-light-green" onClick={onConfirmationClick} disabled={showLoadingInConform}>
                                                        {(showLoadingInConform) && (<i className="fa fa-spinner fa-spin mr-3"/>) }
                                                        {confirmText || "Confirm"}
                                                    </button>
                                            ): ""
                                        }

                                    </>
                                ) : (
                                    <>
                                        {showYesBtn
                                            ?(
                                                <button type="button" className="confirm-btn font-size-md bg-color-bright-light-green mr-3" onClick={onConfirmationClick} disabled={showLoadingInConform}>
                                                    {(showLoadingInConform) && (<i className="fa fa-spinner fa-spin mr-3"/>) }
                                                    {confirmText || "Confirm"}
                                                </button>
                                            ): ""
                                        }
                                        <button type="button" className="cancel-btn font-size-md bg-color-red" onClick={toggleDialog} disabled={showLoadingInConform}>
                                            {cancelText || "Cancel"}
                                        </button>
                                    </>
                                )}

                            </div>
                        </>
                    )}

                </div>
            </Popup>


            {/*<Modal isOpen={show} toggle={toggleDialog} backdrop={'static'}>*/}
            {/*    <ModalHeader toggle={onClickHeaderClose}>*/}
            {/*        Confirmation?*/}
            {/*    </ModalHeader>*/}
            {/*    <ModalBody>*/}
            {/*        {children}*/}
            {/*    </ModalBody>*/}
            {/*    <ModalFooter>*/}
            {/*        <button type="button" className={`${cancelBtnCssClass ? cancelBtnCssClass : 'btn btn-danger'}`}*/}
            {/*                onClick={toggleDialog}>{cancelText || "Cancel"}</button>*/}
            {/*        <button type="button" className={`${confirmBtnCssClass ? confirmBtnCssClass : 'btn btn-success'}`}*/}
            {/*                onClick={onConfirmationClick}>{confirmText || "Confirm"}</button>*/}
            {/*    </ModalFooter>*/}
            {/*</Modal>*/}
        </>

    );
};

export default ConfirmationDialog;
