import CONSTANT from "../../../constants/Constant";
import GLOBAL from "../../../global";
import ChatRequest from "../../../models/ChatRequest";
import { format } from "date-fns";
import { makeSerializable } from "../../../utils/Utils";

export const getIndexOfFirstUnansweredChat = (chats) => {
    return chats.findIndex((chat, index) => {
        const isUnanswered = chat?.answers.length === 0;

        // If the user clicked "yes" on the chief complaint but hasn't answered any follow-up questions
        let isUserAnsweredCCQuestion = true;
        if (chat?.questionId === CONSTANT.CC_QUESTION.ID) {
            let answer = chat?.answers[0];
            let isUserClickedYesChiefComplaint = answer && answer?.referenceId === CONSTANT.REFERENCE_RESPONSE.YES;
            if (isUserClickedYesChiefComplaint && GLOBAL.ISANYCHIEFCOMPLAINTANSWEDED === false) {
                isUserAnsweredCCQuestion = false;
            }
        }

        // If the last question is a checkbox or multiselect, and it's not answered
        let isLastChatCheckboxUnanswered = true;
        let isLastQuestionCheckbox = (chats[chats.length - 1]?.responseType === CONSTANT.ELEMENT_TYPES.CHECKBOX) ||
                                              (chats[chats.length - 1]?.responseType === CONSTANT.ELEMENT_TYPES.MULTISELECT);

        if (isLastQuestionCheckbox && index === chats.length - 1) {
            isLastChatCheckboxUnanswered = false;
        }

        // Return index if unanswered, CC question is not properly answered, or the last checkbox is unanswered
        return isUnanswered || !isUserAnsweredCCQuestion || !isLastChatCheckboxUnanswered;
    });
};


export const willSelectBodyRegionAutomatically = (chat, BodyRegionList) => {
    if (chat.intent === CONSTANT.CHATBOT_INTENT.BODY_REGION &&
        (chat.botName === CONSTANT.CHAT_BOTS.INTAKE_BOT
            || chat.botName === CONSTANT.CHAT_BOTS.MSK_BOT
            || chat.botName === CONSTANT.CHAT_BOTS.POSTURE_BOT
            || chat.botName === CONSTANT.CHAT_BOTS.PAIN_BOT
        ) &&
        BodyRegionList && BodyRegionList.length > 0) {
        return true;
    }
    return false;
}

// export const createChatRequest = (tempCurrentChatInd, updatedChats, intent, skipped, isEditing, options, patientId, lastChat, assessmentInformation, sessionId) => {
export const createChatRequest = (tempCurrentChatInd, updatedChats, intent, skipped, isEditing, options, patientId, invitationId, invitationTypeName, isFollowup, sessionId) => {
    let chatRequest = new ChatRequest();
    chatRequest.id = (tempCurrentChatInd >= 0 && updatedChats[tempCurrentChatInd]?.id) || 0;
    chatRequest.botName = (options && options.name) || '';
    chatRequest.tenant = GLOBAL.TENANT;//BOT API
    chatRequest.email = (GLOBAL.USER_INFO && GLOBAL.USER_INFO['EmailAddress']) || '';//no problem as provider portal api is not using this
    chatRequest.patientId = patientId;//BOT API
    chatRequest.providerId = (GLOBAL.USER_INFO && GLOBAL.USER_INFO['ProviderId']) || '';
    chatRequest.gender = (GLOBAL.USER_INFO && GLOBAL.USER_INFO['Gender']) || '';
    chatRequest.sessionId = sessionId || 0;
    chatRequest.questionId = (tempCurrentChatInd >= 0 && updatedChats[tempCurrentChatInd]?.questionId) || 0;
    chatRequest.intent = intent;
    chatRequest.answers = updatedChats[tempCurrentChatInd]?.answers || [];
    chatRequest.loggedTime = format(chatRequest.loggedTime, CONSTANT.DATE_FORMAT.HH_MM);
    chatRequest.referEnabled = !!invitationId;
    chatRequest.employerEnabled = invitationTypeName.toLowerCase() === CONSTANT.REDIRECT_TYPE.REFER;
    chatRequest.referenceId = '';
    chatRequest.referType = invitationTypeName || '';
    chatRequest.skipped = skipped;
    chatRequest.isFollowUp = isFollowup || false;

    return chatRequest;
}

export const shouldSaveToServer = (intent, chat) => {
    return intent === CONSTANT.CHATBOT_INTENT.TERMS_CONDITIONS
        || intent === CONSTANT.CHATBOT_INTENT.EMAIL_BODY
        || intent === CONSTANT.CHATBOT_INTENT.BODY_REGION
        || chat?.lastQuestionInGroup
        || CONSTANT.BOT_QUESTION_ID.BIOMETRIC_CAUTION3 === chat?.questionId
        || chat?.questionId === CONSTANT.CHATBOT_ACCOUNT.DATE_OF_BIRTH
}

export const isSelectedCurrentOption = (chat, chatResponse) => {
    let isChecked = false;
    if(chat.answers && chat.answers.length > 0) {
        chat.answers.forEach(answer => {
            if(answer.id === chatResponse.id) {
                isChecked = true;
            }
        });
    }

    return isChecked;
}

export const getChildQuestionIds = (responses) => {
    let childQuestionIds = [];

    responses.map(( response) => {
        if(response?.conditionalQuestionList?.length > 0) {
            let conditionalQuestionList = response.conditionalQuestionList || [];
            conditionalQuestionList.forEach(conditionalQuestionId => {
                if(!childQuestionIds.includes(conditionalQuestionId)) {
                    childQuestionIds.push(conditionalQuestionId);
                }
            });
        }
    });

    return childQuestionIds;
};

export const getChildQuestionIdsNotInSelectedResponse = (allIds, selectedIds) => {
    return allIds.filter(id => !selectedIds.includes(id));
};

export const childQuestionIdsAlreadyInChats = (chats, allChildQuestionIds) => {
    let childQuestionIds = [];
    allChildQuestionIds.forEach(id => {
        if (chats.find(chat => chat.questionId === id)) {
            childQuestionIds.push(id);
        }
    });

    return childQuestionIds;
}

export const getSelectedValue = (chat) => {
    let serializableChat = makeSerializable(chat);
    let answers = serializableChat?.answers || [];
    if(answers.length === 0) {
        return "";
    }

    let selectedValue = answers[0];
    //as react-select-async-paginate expects label and value
    selectedValue = {
        ...selectedValue,
        label: selectedValue.name || "",
        value: selectedValue.id || 0
    }

    return selectedValue;
}

export const getAllTypesText = (types) => {
    let questionTypes = "";

    const typesText = types.map((type) => {
        return type.name;
    });

    if(typesText.length > 1)
        questionTypes = typesText.join(', ');
    else
        questionTypes = typesText[0];

    return questionTypes;
}