import {VIDEO_STORE_URL, PROCESS_EXERCISE_VIDEO_URL, PROCESS_QUEUE_EXERCISE_URL, No_START_OVER_URL} from "./UrlManager";
import GLOBAL from "../../../../global";
import axios from '../../../../../src/services/Axios';
import { error, success } from "../../../../services/ResponseHandler";

const RESPONSE_TYPE = { responseType: 'text' };

export const storeRecordedVideo = async (filename, exerciseValue, base64data) => { 
    const patientId = (GLOBAL.USER_INFO && GLOBAL.USER_INFO['ContactId']) || '';//BOT API

    const payload = {
        patientId: patientId,//BOT API
        exerciseName: exerciseValue,
        filename: filename,
        blobBase64: base64data,
        tenant: GLOBAL.TENANT//BOT API
    };

    try{
        const response = await axios.post(VIDEO_STORE_URL, payload, RESPONSE_TYPE);
        const http = '^http';
        const regexp = new RegExp(http);

        return response && regexp.test(response) ? response : '';
    }
    catch(ex){
        return '';
    }
}

// export const processExerciseVideo = async (testId, exerciseValue, videoUrl, isMeasurementNeeded, fileName) => {
export const processExerciseVideo = async (testId, exerciseValue, isMeasurementNeeded, fileName, base64Image) => {
    if (exerciseValue === 'calibration') {
        GLOBAL.USER_INFO['ShoulderToShoulderDistance'] = 0;
        GLOBAL.USER_INFO['ShoulderToElbowDistance'] = 0;
        GLOBAL.USER_INFO['ElbowToWristDistance'] = 0;
    }

    const patientId = (GLOBAL.USER_INFO && GLOBAL.USER_INFO['ContactId']) || '';//ROM API
    const height = (GLOBAL.USER_INFO && GLOBAL.USER_INFO['Height']) || 0;

    const payload = {
        patientId: patientId,//ROM API
        testId: testId,
        // rawVideoUrl: videoUrl,
        rawVideoUrl: base64Image,
        height: height,
        exerciseName: exerciseValue,
        isMeasurementNeeded: isMeasurementNeeded,
        filename: fileName,
        tenant: GLOBAL.TENANT,//ROM API
        calibration: {
            shoulderToShoulderDistance: GLOBAL.USER_INFO['ShoulderToShoulderDistance'] || 0,
            shoulderToElbowDistance: GLOBAL.USER_INFO['ShoulderToElbowDistance'] || 0,
            elbowToWristDistance: GLOBAL.USER_INFO['ElbowToWristDistance'] || 0
        }
    };

    let responseObj = {
        success: false,
        message: ""
    }

    try{
        const response = await axios.post(PROCESS_EXERCISE_VIDEO_URL, payload, RESPONSE_TYPE);
        if(response && response["success"] !== undefined){
            responseObj.success = response["success"];
            responseObj.message = response["success"] === false ? response["message"] : "";
        }
        return responseObj;
    }
    catch(err){
        return responseObj;;
    }
}

export const getQueuedMovements = async (testId) => {
    try {
        const requestUrl = `${PROCESS_QUEUE_EXERCISE_URL}`;
        let payload = {
            tenant: GLOBAL.TENANT,//VA PORTAL API
            testId: testId,
        }
        const response = await axios.post(requestUrl, payload ,RESPONSE_TYPE);
        return response?.Message && response?.Message?.queue ? response?.Message.queue : [];
    } catch (err) {
        console.error(err);
        return [];
    }
}

export const callStartOverApi = async (testId, patientId) => {//VA PORTAL API
    try {
        let payload = {
            tenant: GLOBAL.TENANT,//VA PORTAL API
            testId: testId,
            PatientId: patientId//VA PORTAL API
        }
        const response = await axios.post(No_START_OVER_URL, payload, RESPONSE_TYPE);
        return response;
    } catch (e) {
        console.error(e);
        return false;
    }
}